import { useState, useEffect } from 'react';
import { Chip } from '@material-ui/core';
import styled from 'styled-components';
import { JobTypes } from '../../types/JobTypes';

interface BadgeProps {
  usecolor: string;
}

const Badge = styled(Chip)`
  background-color: ${({ usecolor }: BadgeProps) => `${usecolor} !important`};
  text-transform: capitalize !important;
  font-weight: 400;
  min-width: 88px;
  height: 27px !important;
  cursor: pointer !important;
`;

interface JobTypeBadgeProps {
  type: string;
}

const JobTypeBadge = ({ type }: JobTypeBadgeProps): JSX.Element => {
  const [bgColor, setBgColor] = useState('#F5F6FB');

  useEffect(() => {
    if (JobTypes.Export === type) setBgColor('#F5F6FB');
    else if (JobTypes.Import === type) setBgColor('#FEF9E7');
    else setBgColor('#F5F6FB');
  }, [type]);

  return (
    <Badge
      data-tag="allowRowEvents"
      usecolor={bgColor}
      label={<span data-tag="allowRowEvents">{type}</span>}
    />
  );
};

export default JobTypeBadge;