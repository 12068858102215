import React, { forwardRef, Ref } from 'react';

import MaterialCheckbox from '@mui/material/Checkbox';
import CheckboxIcon from './CheckboxIcon';

const Checkbox = forwardRef((props, ref) => {
  return (
    <MaterialCheckbox
      {...props}
      icon={<CheckboxIcon />}
      checkedIcon={<CheckboxIcon checked/>}
      inputRef={ref as Ref<HTMLInputElement>}
    />
  );
})

Checkbox.displayName = "Checkbox";

export default Checkbox;

