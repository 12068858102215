import { useModal } from '../../contexts/modal-context';
import styled from 'styled-components';
import BaseModal from '../BaseModal/BaseModal';
import { Close as CloseIcon } from '@material-ui/icons';
import TruckCaddyQR from '../../assets/images/truck-caddy-qr.png';
import TruckCaddyLogo from '../../assets/images/logo.png';

const ModalContent = styled.article`
  display: flex;
  flex-direction: column;
  gap: 50px;
  position: relative;
  padding: 50px;
  width: 100%;
  max-width: 650px;
  background-color: white;
  font-family: Nunito, sans-serif;
  color: #16244a;
  box-shadow: 0 0 8px 0 #0000000f;
  outline: none;
  max-height: 80vh;
  overflow-y: auto;

  @media (max-width: 600px) {
    padding: 25px;
  }

  @media (max-width: 450px) {
    gap: 25px;
    font-size: 14px;
  }
`;

export const CloseIconContainer = styled.button`
  all: initial;
  position: absolute;
  right: 22px;
  top: 20px;
  cursor: pointer;
`;

const Title = styled.h2`
  font-size: 40px;
  text-align: center;
  margin: 0;

  @media (max-width: 450px) {
    font-size: 34px;
  }
`;

const DownloadSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-around;

  @media (max-width: 450px) {
    flex-direction: column;
    gap: 20px;
  }
`;

const Store = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 450px) {
    gap: 0;
  }
`;

// const Separator = styled.div`
//   height: 100%;
//   border-left: 1px solid #81869a59;

//   @media (max-width: 450px) {
//     width: 60%;
//     border-left: 0;
//     border-top: 1px solid #81869a59;
//   }
// `;

const About = styled.a`
  display: grid;
  place-items: center;
  background-color: #405daa;
  border-radius: 5px;
  color: white;
  padding: 15px 10px;
  text-align: center;
  height: min-content;
`;

const QRCode = styled.img`
  width: 200px;
`;

const DownloadAppModal: React.FC = () => {
  const { close } = useModal();

  return (
    <BaseModal style={{ backgroundColor: '#81869A59' }} useBackdrop={false}>
      <ModalContent>
        <CloseIconContainer onClick={close}>
          <CloseIcon />
        </CloseIconContainer>

        <div>
          <Title>Almost Done!</Title>
          <p style={{ textAlign: 'center', margin: '0', marginTop: '10px' }}>
            To complete the upcoming action seamlessly, you must download our
            app.
            <br />
            Scan the QR code below or click the link to access the app on your
            preferred platform.
          </p>
        </div>

        <DownloadSection>
          <Store>
            <QRCode
              src={TruckCaddyQR}
              alt='Scan the QR code to download on the App Store'
            />
          </Store>
        </DownloadSection>

        <About
          href='https://truckcaddyapp.com/'
          target='_blank'
          rel='noreferrer'
        >
          <p style={{ margin: '0', marginBottom: '7px' }}>
            Click here to learn more about our app and subscriptions on our
            website:
          </p>
          <img src={TruckCaddyLogo} alt='Truck Caddy logo' width={155} />
        </About>
      </ModalContent>
    </BaseModal>
  );
};

export default DownloadAppModal;
