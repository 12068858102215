import styled from 'styled-components';
import { ProfilesInformation } from '../../utils/constants';
import ProfileTypeCard from './ProfileTypeCard';
import PlainTextButton from '../PlainTextButton/PlainTextButton';
import { Button } from '../PersonalInfo/PersonalInfo';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { useHistory } from 'react-router-dom';
import { ProfileTypes } from '../../types/ProfileTypes';

type ProfileTypeSelectionProps = {
  selectedType: ProfileTypes | null;
  setSelectedType: (type: ProfileTypes) => void;
  nextStep: () => void;
};

const Container = styled.section`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 55px;
  width: fit-content;
  align-self: center;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    padding: 0;
    padding-bottom: 30px;
  }
`;

const Title = styled.h1`
  font-family: Cabin, sans-serif;
  font-size: 48px;
  margin: 0;
`;

const Subtitle = styled.h2`
  font-family: Roboto, sans-serif;
  font-size: 22px;
  font-weight: 300;
  color: #405daa;
  margin-top: 10px;
  margin-bottom: 30px;
`;

const TypesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  gap: 15px;
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 25px;
`;

const ProfileTypeSelection: React.FC<ProfileTypeSelectionProps> = (props) => {
  const { selectedType, setSelectedType, nextStep } = props;
  const history = useHistory();

  return (
    <Container>
      <Title>Select a profile type</Title>
      <Subtitle>Choose the option that suits your needs:</Subtitle>

      <TypesContainer>
        {ProfilesInformation.map((profile) => (
          <ProfileTypeCard
            key={profile.type}
            icon={profile.icon}
            title={profile.type}
            description={profile.description}
            selected={selectedType === profile.type}
            onClick={() => setSelectedType(profile.type)}
          />
        ))}
      </TypesContainer>

      <Options>
        <PlainTextButton text='CANCEL' handleClick={() => history.push('/')} />
        <Button type='button' onClick={nextStep} disabled={!selectedType}>
          Next
          <ChevronRightIcon />
        </Button>
      </Options>
    </Container>
  );
};

export default ProfileTypeSelection;
