import React from 'react';
import styled from "styled-components";
import uncheckedImage from '../../assets/images/unchecked.png';
import checkedImage from '../../assets/images/checked.png';

const Unchecked = styled.span`
  position: relative;
  background: url(${uncheckedImage});
  background-size: cover;
  width: 17px;
  height: 17px;

`;

const Checked = styled.span`
  position: absolute;
  top: Calc((17px - 11px) / 2);
  left: Calc((17px - 13px) / 2);
  z-index: 1;
  background: url(${checkedImage});
  width: 13px;
  height: 11px;
`;

interface CheckboxIconProps {
  checked?: boolean;
}

const CheckboxIcon = ({checked}: CheckboxIconProps): JSX.Element => {
  if (Boolean(checked)) return (
    <Unchecked>
      <Checked />
    </Unchecked>
  )
  return (
    <Unchecked />
  );
};

export default CheckboxIcon;
