type Props = {
    size: number;
};

const ArrowBottomIcon = ({ size }: Props): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 9" fill="none">
      <path
        d="M7.752 7.752L1 1M7.752 2.125v5.627H2.125"
        stroke="#1C3264"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowBottomIcon;
