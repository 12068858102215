import React, { MouseEvent, useState } from 'react';

// Libraries
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

// Material UI related
import MuiMenu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';

// Styles
const Wrapper = styled.div`
  position: relative;
  margin-left: auto;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 15px;

  @media (max-width: 992px) {
    display: none !important;
  }
`;

const Menu = styled(MuiMenu)`
  .MuiMenu-paper {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06) !important;
    border-radius: 1px;
  }

  .MuiMenu-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-width: 130px;
  }

  .MuiMenuItem-root {
    justify-content: flex-start;
    padding: 8px 12px;
    font-family: 'Open Sans', sans-serif;
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: 15px;
    color: #010c38;

    &:hover {
      background: #f6f7fc;
    }
  }

  & .MuiAvatar-root {
    width: 15px;
    height: 15px;
    margin-right: 6px;
  }

  .MuiListItemIcon-root {
    margin-right: 6px;
    min-width: auto !important;
  }

  .MuiSvgIcon-root {
    font-size: 15px;
    color: #91a5da;
  }
`;

const WelcomeMsg = styled.p`
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: 400;
  text-transform: capitalize;
  color: #fff;
`;

const UserName = styled.span`
  font-weight: 700;
`;

const ProfilePicture = styled.button`
  display: flex;
  align-content: center;
  justify-content: center;
  color: #f7d55e;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 22px;
  width: 31px;
  height: 31px;
  border: none;
  border-radius: 3px;
  background: rgba(255, 255, 255, 0.06);
  cursor: pointer;
`;

interface MenuProps {
  username?: string | null;
  profileLetter?: string;
  handleLogout: () => void;
}

const ProfileMenu = ({
  username,
  profileLetter,
  handleLogout,
}: MenuProps): JSX.Element => {
  // get access to the route history
  const history = useHistory();
  // defines if the menu should open or close
  const [openMenu, setOpenMenu] = useState(false);
  // reference element for the popover menu
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  // opens the menu
  const handleClick = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    setOpenMenu(true);
  };
  // closes the menu
  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };
  // redirect the user to the my-account view
  const handleMyAccountClick = () => {
    history.push(`/my-account`);
  };

  return (
    <Wrapper>
      <WelcomeMsg>
        Hi, <UserName>{username}!</UserName>
      </WelcomeMsg>
      <ProfilePicture onClick={handleClick}>{profileLetter}</ProfilePicture>

      <Menu
        id="profile-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={handleClose}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={handleMyAccountClick}>
          <ListItemIcon>
            <AccountCircleOutlinedIcon />
          </ListItemIcon>
          My account
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <Logout />
          </ListItemIcon>
          Sign out
        </MenuItem>
      </Menu>
    </Wrapper>
  );
};

export default ProfileMenu;
