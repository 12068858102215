import styled from 'styled-components';
import { useField } from 'formik';
import { FieldConfig } from 'formik/dist/Field';

interface WrapperInterface {
  disabled?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  box-sizing: border-box;

  & div:first-child {
    display: flex;
    align-items: center;
    color: #91a5da;
  }

  & div:nth-child(2) {
    font-family: 'Cabin', sans-serif;
    font-size: 14px;
  }

  & .switchFontStyle {
    font-family: ${({ disabled }: WrapperInterface) =>
      disabled ? 'Cabin, sans-serif' : 'Nunito, sans-serif'};
    font-size: ${({ disabled }: WrapperInterface) =>
      disabled ? '14px' : '12px'};
    font-weight: ${({ disabled }: WrapperInterface) =>
      disabled ? '700' : '300'};
  }
`;

interface InputInterface {
  disabled?: boolean;
  error?: boolean;
  bg?: string;
}

const Input = styled.input`
  box-sizing: border-box;
  width: 100%;
  padding: 9px 16px 9px 12px;
  border-radius: 3px;
  border: ${({ error, disabled }: InputInterface) =>
    error
      ? '1px solid #ff537c'
      : disabled
      ? '1px solid #e8ecf9;'
      : '1px solid #91a5da'};
  outline: none;

  background: ${({ disabled, bg }: InputInterface) =>
    disabled ? '#E8ECF9' : Boolean(bg) ? bg : '#FFF'};
  font-family: Cabin, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #16244a;
`;

const FeedbackContainer = styled.div`
  display: flex;
  justify-content: space-between;
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
`;

const Label = styled.label`
  color: #16244a;
  margin-right: 10px;
`;

const Error = styled.p`
  color: #ff537c;
  width: fit-content !important;
  flex-grow: 0 !important;
  flex-basis: 0 !important;
  margin: 0 !important;
`;

interface TextFieldProps extends FieldConfig {
  label: string;
  disabled?: boolean;
  dependsOf?: string;
  customBg?: string;
  switchFontStyle?: boolean;
  autoComplete?: string;
  type?: string;
}

const TextField = (props: TextFieldProps): JSX.Element => {
  const [field, meta] = useField(props);
  const customBg = props.customBg;
  const switchFontStyle = props.switchFontStyle;
  const disabled = props.disabled;
  const autoComplete = props.autoComplete;
  const type = props.type;
  return (
    <Wrapper disabled={disabled}>
      <FeedbackContainer>
        <Label>{props.label}</Label>
        {!!meta.error && <Error>{meta.error}</Error>}
      </FeedbackContainer>
      <Input
        {...field}
        {...props}
        type={type}
        autoComplete={autoComplete}
        error={meta.error != undefined}
        bg={customBg}
        className={Boolean(switchFontStyle) && 'switchFontStyle'}
      />
    </Wrapper>
  );
};

export default TextField;
