import { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useParams, useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import TruckCaddyLogo from '../../assets/images/logo.png';
import Pattern from '../../assets/images/b01.png';
import Banner from '../../assets/images/publicBanner03.jpg';
import { useNotification } from '../../contexts/notification-context';
import Button from './../../components/Button/Button';
import { confirmEmail } from '../../api/AuthenticationAPI';


const Container = styled(Grid)`
  height: 100vh;
  &.MuiGrid-container{
    flex-wrap: nowrap;
  }
`;

const SideBar = styled.div`
  box-sizing: border-box;
  background-image: url(${Banner});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-grow: 0;
  padding: 75px 85px 50px;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  @media (max-width: 900px) {
    display: none;
  }
`;
const SideBarContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const SideBarTitle = styled.h1`
  max-width: 300px;
  margin: 0;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.03em;
  line-height: 103.19%;
  color: #010C38;
`;

const Content = styled(Grid)`
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-image: url(${Pattern});
  background-position: bottom;
  background-repeat: no-repeat;
  background-position-x: right;
  padding-left: 60px;

  @media (max-width: 425px) {
    padding: 0 30px;
  }
  @media (max-width: 375px) {
    align-items: center;
  }
`;

const Title = styled.span`
  color: #16244A;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  letter-spacing: -0.015em;
  margin-bottom: 30px;

  @media (max-width: 375px) {
    text-align: center;
  }
`;

const Subtitle = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color: #91a5da;
  margin-bottom: 30px;
  width: 65%;
  
  @media (max-width: 425px) {
    width: fit-content;
    text-align: left;
  }
  @media (max-width: 375px) {
    text-align: center;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

`;
const TitleImage = styled.img`
  height: auto;
  max-width: 100%;
`;

const Loading = styled(CircularProgress)`
  margin: auto;
`;

const VerificationPage: FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  // get token from the url params
  const { token } = useParams<{ token: string }>();

  // get access to the history object
  const history = useHistory();

  // get method to be able to show alerts
  const { setNotification } = useNotification();

  // handle the confirmation of the email
  const handleConfirmation = useCallback(() => {
    setIsLoading(true);
    confirmEmail(token)
      .then(({ data }) => {
        if (data.success) {
          setNotification({ type: 'success', message: data.msg, open: true });
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
        setError(response.data.msg);
      })
      .finally(() => setIsLoading(false));
  }, [token, setNotification]);

  useEffect(() => {
    handleConfirmation();
  }, [handleConfirmation]);

  return (
    <Container container>
      <SideBar>
        <SideBarContent>
          <SideBarTitle>The safest way for you</SideBarTitle>
          <LogoContainer>
            <TitleImage src={TruckCaddyLogo} />
          </LogoContainer>
        </SideBarContent>
      </SideBar>
      <Content item xs={12} sm={12} md={8}>
        {!isLoading ? (
          <>
            <Title>{error ? error : 'Verified!'}</Title>
            <Subtitle>
              {error ? 'Please try again' : 'You have successfully verified your email address, now you can login'}
            </Subtitle>
            <Button
              text='Log in'
              handleClick={() => history.push('/login')}
              background='#405DAA'
              color='white'
              borderColor='#405DAA'
            />
          </>
        ) : (
          <Loading disableShrink size={60} />
        )}
      </Content>
    </Container>
  );
};

export default VerificationPage;
