import { FormControl, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';
import { KeyboardArrowDown } from '@material-ui/icons';

interface ControlProps {
  maxWidth?: string;
  padding?: string;
}

const Control = styled.div`
  margin-top: 5px;

  width: 100%;
  max-width: ${({ maxWidth }: ControlProps) =>
          !maxWidth ? '250px' : maxWidth};

  .MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    background: #f6f7fc;
    color: #1c3264;
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 13px;
    width: 100%;
    border: 1px solid #e8ecf9;
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-radius: 3px;
  }

  .MuiSelect-selectMenu {
    padding: ${({ padding }: ControlProps) =>
            !!padding ? padding : '14px 32px 14px 8px'};
  }

  /*  Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    max-width: 100% !important;
  }
  &.my-tickets{
    margin-top: 0;
    @media(max-width: 1050px){
      margin-top: 10px;
    }
  }
`;

interface DropdownProps {
  value: string;
  handleChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  options: { id: string; name: string }[];
  maxWidth?: string;
  customPadding?: string;
  className?: string;
}

function Dropdown({
  value,
  handleChange,
  options,
  maxWidth,
  customPadding,
  className,
}: DropdownProps): JSX.Element {
  return (
    <Control  className={className} maxWidth={maxWidth} padding={customPadding}>
      <FormControl variant='outlined'>
        <Select
          displayEmpty
          value={value}
          onChange={handleChange}
          IconComponent={(props) => <KeyboardArrowDown {...props} />}
        >
          <MenuItem value='' disabled>
            Filter By Status
          </MenuItem>
          {options.map(({ id, name }) => {
            return (
              <MenuItem key={id} value={id}>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Control>
  );
}

export default Dropdown;
