import styled from 'styled-components';
import GlobalJob from '../../types/GlobalJob';
import GlobalJobDetails from '../GlobalJobDetails/GlobalJobDetails';
import { Close as CloseIcon } from '@material-ui/icons';

type PopUpProps = {
  job: GlobalJob | null;
  onClose: () => void;
};

const Container = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background: #ffffff;
  box-shadow: -11px 4px 16px 0px #0000001f;
  border-radius: 1px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  height: max-content;
  max-height: 85vh;

  /*  Small devices */
  @media (max-width: 700px) {
    position: fixed;
    width: 100%;
    height: max-content;
    max-width: 90vw;
    max-height: 85vh;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const CloseButton = styled.button`
  all: initial;
  cursor: pointer;
  position: absolute;
  top: 0;
  left: -48px;
  z-index: 1;
  padding: 12px;
  background-color: #f5f5f5;
  border-radius: 15px 0 0 15px;
  box-shadow: -11px 4px 16px 0px #0000001f;

  /*  Small devices */
  @media (max-width: 700px) {
    left: initial;
    right: 2px;
    top: -40px;
    rotate: 90deg;
    padding: 8px;
  }
`;

const JobDetailsPopUp: React.FC<PopUpProps> = ({ job, onClose }) => {
  if (!job) return null;

  return (
    <Container>
      <CloseButton onClick={onClose}>
        <CloseIcon
          width={20}
          height={20}
          viewBox='0 0 20 20'
          style={{ color: '#B0BEE4', strokeWidth: 1, stroke: '#B0BEE4' }}
        />
      </CloseButton>
      <GlobalJobDetails job={job} />
    </Container>
  );
};

export default JobDetailsPopUp;
