import React, { FC } from 'react';

// Libraries
import { useHistory } from 'react-router-dom';

// Context
import { useModal } from '../../contexts/modal-context';

// Components
import Layout from '../../shared/components/Layout/Layout';
import LoadTypeBadge from '../../components/LoadTypeBadge/LoadTypeBadge';
import TracksMap from '../../components/TracksMap/TracksMap';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import CancelLoadModal from '../../components/ConfirmationModal/CancelLoadModal';

// Hooks
import useLoadDetail from '../../hooks/useLoadDetail';

// Types
import { AvailableLoadStatus } from '../../types/Status';

// Styles
import {
  MainContainer,
  Column,
  Row,
  MapRow,
  Cell,
  DataContainer,
  DetailData,
  HeaderData,
  RegularText,
  HighlightText,
  ArrowLeftIcon,
  MapPlaceHolder,
  NotesContainer,
  Comments,
  CancelLoad,
  CancelButtonContainer,
  CancellationMotive,
} from './load-detail.styles';
import LoadDetailBadge from '../../components/LoadDetailBadge/LoadDetailBadge';
import { formatCurrency } from '../../helpers/formats';

const LoadDetailPage: FC = () => {
  // Get access to the route history
  const history = useHistory();
  // Confirmation context
  const { requireModal } = useModal();
  // Get and update the data from the server
  const { loading, value, updateData, status, id } = useLoadDetail();

  // Opens the confirmation modal to cancel the load
  const cancelLoadHandler = () => {
    requireModal(<CancelLoadModal id={id} onSuccess={updateData} />);
  };

  return (
    <Layout>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <CancelButtonContainer isCancelButtonShowing={status === 'Scheduled'}>
            <CancelLoad type="button" onClick={cancelLoadHandler}>
              Cancel Load
            </CancelLoad>
          </CancelButtonContainer>
          <MainContainer isCancelButtonShowing={status === 'Scheduled'}>
            <Row borderBottom>
              <Cell smaller>
                <HeaderData>
                  <ArrowLeftIcon onClick={() => history.goBack()} />
                  <RegularText className="padTop">Load</RegularText>
                  <HighlightText>#{id}</HighlightText>
                </HeaderData>
              </Cell>
              <Cell smaller>
                <HeaderData>
                  <RegularText className="padTop">Date</RegularText>
                  <HighlightText>{value?.createdAt}</HighlightText>
                </HeaderData>
              </Cell>
              <Cell smaller>
                <HeaderData>
                  <RegularText>Status</RegularText>
                  <LoadDetailBadge value={status as AvailableLoadStatus} />
                </HeaderData>
              </Cell>
            </Row>
            <Row showBorder>
              <Cell backgroundColor="#FFF">
                <DataContainer>
                  <Column>
                    <RegularText>Trucker / Trucker Type</RegularText>
                    <HighlightText>{value?.trucker.name}</HighlightText>
                    <RegularText>{value?.trucker.truckTypes}</RegularText>
                  </Column>
                </DataContainer>
              </Cell>
              <Cell backgroundColor="#FFF">
                <DataContainer>
                  <Column>
                    <LoadTypeBadge
                      label={value?.type}
                      type={value?.type}
                      useIcon
                    />
                    <HighlightText>{value?.location.name}</HighlightText>
                    <RegularText>{value?.location.address}</RegularText>
                  </Column>
                </DataContainer>
              </Cell>
              <Cell backgroundColor="#FFF">
                <DataContainer>
                  <Column>
                    <LoadTypeBadge label="Job Site" />
                    <HighlightText>{value?.jobSite.name}</HighlightText>
                    <RegularText>{value?.jobSite.address}</RegularText>
                  </Column>
                </DataContainer>
              </Cell>
            </Row>
            <MapRow>
              <MapPlaceHolder>
                <TracksMap
                  tracking={value?.tracking || []}
                  jobSite={{
                    lat: value?.jobSite.latlng[0],
                    lng: value?.jobSite.latlng[1],
                  }}
                  location={{
                    lat: value?.location.latlng[0],
                    lng: value?.location.latlng[1],
                  }}
                />
              </MapPlaceHolder>
            </MapRow>

            <Row borderBottom showBorder>
              <Cell>
                <DetailData>
                  <RegularText>Start Hr</RegularText>
                  <HighlightText>{value?.startDate}</HighlightText>
                </DetailData>
              </Cell>
              <Cell>
                <DetailData>
                  <RegularText>Duration</RegularText>
                  <HighlightText>{`${value?.totalHours} HRS`}</HighlightText>
                </DetailData>
              </Cell>
              <Cell>
                <DetailData>
                  <RegularText>Rate</RegularText>
                  <HighlightText>{`$${value?.rate} Hourly`}</HighlightText>
                </DetailData>
              </Cell>
            </Row>
            <Row showBorder borderBottom>
              <Cell>
                <DetailData>
                  <RegularText>End Hr</RegularText>
                  <HighlightText>{value?.endDate}</HighlightText>
                </DetailData>
              </Cell>
              <Cell>
                <DetailData>
                  <RegularText>Distance</RegularText>
                  <HighlightText>{`${value?.distance} MI`}</HighlightText>
                </DetailData>
              </Cell>
              <Cell
                backgroundColor={status !== 'Scheduled' ? '#F7D868' : undefined}
              >
                <DetailData>
                  <RegularText>Total</RegularText>
                  <HighlightText>
                    {status === 'Scheduled'
                      ? '--'
                      : `${formatCurrency(value?.totalEstimated)}`}
                  </HighlightText>
                </DetailData>
              </Cell>
            </Row>

            <Column>
              <Cell backgroundColor="FFF">
                <NotesContainer>
                  Notes:
                  <Comments
                    className={status !== 'Canceled' ? 'mb' : undefined}
                  >
                    {value?.remarks}
                  </Comments>
                </NotesContainer>
              </Cell>
              {status === 'Canceled' ? (
                <Cell backgroundColor="FFF" className="remove-pt ">
                  <NotesContainer className="cancellation">
                    Cancellation motive
                    <CancellationMotive>
                      {value?.cancellationReason}
                    </CancellationMotive>
                  </NotesContainer>
                </Cell>
              ) : null}
            </Column>
          </MainContainer>
        </>
      )}
    </Layout>
  );
};

export default LoadDetailPage;