import { Dispatch, SetStateAction } from 'react';
import {
  KeyboardArrowDown,
  KeyboardArrowLeft,
  KeyboardArrowRight
} from '@material-ui/icons';
import { FormControl, MenuItem, Select } from '@material-ui/core';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 12px 0;
  @media (min-width: 320px) and (max-width: 991.98px) {
    flex-direction: column !important;
  }
`;

const PagesContainer = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  @media (min-width: 320px) and (max-width: 991.98px) {
    margin-bottom: 12px;
  }
`;

const Control = styled(FormControl)`
  .MuiInputBase-root {
    background: #f6f7fc;
    color: #81869a;
    font-family: 'Nunito';
    font-weight: 600;
    font-size: 13px;
    width: 70px;
    text-align: center;

    /*  Small devices (landscape phones, 576px and up) */
    @media (max-width: 576px) {
      width: 100% !important;
    }
  }

  .MuiOutlinedInput-notchedOutline {
    border: none !important;
    border-radius: 3px;
  }

  .MuiSelect-selectMenu {
    padding: 9px 32px 9px 8px;
  }
`;

interface PageButtonProps {
  isSelected?: boolean;
}

const PageButton = styled.button<PageButtonProps>`
  background-color: ${(props) =>
    `${props.isSelected ? '#F7D55E' : '#F6F7FC'} !important`};
  border: none;
  border-radius: 1px;
  color: ${(props) => (props.isSelected ? '#16244A' : '#81869A')};
  font-weight: 600;
  font-family: Nunito;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  margin: 0 5px;

  &:hover:enabled {
    ${(props) => !props.isSelected && 'background-color: #EEE !important;'}
  }

  &:disabled {
    opacity: 0.5;
  }
`;

const RowsPerPageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ShowingInfoText = styled.div`
  color: #1c3264;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  margin-left: 15px;
`;

interface ListPaginationProps<T> {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  rowsPerPageOptions: number[];
  onChangePage: (page: number) => void;
  onChangeRowsPerPage: (newLimit: number, currentPage: number) => void;
  setQueryParams: Dispatch<SetStateAction<T>>;
}

const ListPagination = <T extends Record<string, unknown>>({
  currentPage,
  rowsPerPage,
  rowCount,
  rowsPerPageOptions,
  onChangePage,
  onChangeRowsPerPage,
  setQueryParams
}: ListPaginationProps<T>): JSX.Element => {
  // get total pages
  const getTotalPages = (): number => Math.ceil(rowCount / rowsPerPage);

  // handle the change of page in the table
  const handleOnChangePage = (page: number): void => {
    onChangePage(page);

    setQueryParams((queryParams) => ({
      ...queryParams,
      page
    }));
  };

  // handle the change of limit rows per page
  const handleChangeRowsPerPage = (newLimit: number): void => {
    onChangeRowsPerPage(newLimit, currentPage);

    setQueryParams((queryParams) => ({
      ...queryParams,
      page: currentPage,
      limit: newLimit
    }));
  };

  const ShowingInfo = () => {
    const start = (currentPage - 1) * rowsPerPage + 1;
    let end = rowCount;

    if (rowsPerPage < rowCount) {
      end = rowsPerPage * currentPage;

      if (end > rowCount) end = rowCount;
    }

    return (
      <ShowingInfoText>
        Showing {`${start}-${end} of ${rowCount}`}
      </ShowingInfoText>
    );
  };

  return (
    <Container>
      <PagesContainer>
        <PageButton
          disabled={currentPage === 1}
          onClick={() => handleOnChangePage(currentPage - 1)}
        >
          <KeyboardArrowLeft />
        </PageButton>
        <PageButton
          disabled={currentPage >= getTotalPages()}
          onClick={() => handleOnChangePage(currentPage + 1)}
        >
          <KeyboardArrowRight />
        </PageButton>
      </PagesContainer>

      <RowsPerPageContainer>
        <Control variant='outlined'>
          <Select
            defaultValue={rowsPerPage}
            onChange={({ target }) =>
              handleChangeRowsPerPage(Number(target.value))
            }
            value={rowsPerPage}
            IconComponent={(props) => (
              <KeyboardArrowDown {...props} style={{ fontSize: 22 }} />
            )}
          >
            {rowsPerPageOptions.map((r) => (
              <MenuItem key={r} value={r}>
                {r}
              </MenuItem>
            ))}
          </Select>
        </Control>
        <ShowingInfo />
      </RowsPerPageContainer>
    </Container>
  );
};

export default ListPagination;
