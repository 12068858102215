import * as Yup from 'yup';
import { Formik, Form, FormikHelpers } from 'formik';
import { RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { adminsLogin } from '../../api/AuthenticationAPI';
import { useAuth } from '../../contexts/auth-context';
import { User } from '../../types/User';
import TruckCaddyLogo from '../../assets/images/logo.png';
import { useNotification } from '../../contexts/notification-context';
import OnBoardingTextField from '../../components/OnboardingTextField/OnBoardingTextField';
import {
  Container,
  Button,
  FieldsContainer,
  FormContainer,
  LogoContainer,
  MainContainer,
  PlainTextButton,
  SideBar,
  SideBarContent,
  SideBarTitle,
  Title,
  TitleImage
} from './login.styles';

const AdminsLoginPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { onLogin } = useAuth();
  const { setNotification } = useNotification();

  const handleSubmit = (
    values: { email: string; password: string },
    { setSubmitting }: FormikHelpers<{ email: string; password: string }>
  ): void => {
    const { email, password } = values;
    adminsLogin(email, password)
      .then(({ data }) => {
        if (data.success) {
          setSubmitting(false);
          const user = new User(data);
          onLogin(user);
          setNotification({
            type: 'success',
            message: 'Login Successful',
            open: true
          });
        }
      })
      .catch(({ response }) => {
        setSubmitting(false);
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true
        });
      });
  };

  // validations object for the login form
  const loginValidations = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required')
  });

  return (
    <Container>
      <MainContainer>
        <FormContainer>
          <Title>Admin Login</Title>
          <Formik
            initialValues={{ email: '', password: '' }}
            validationSchema={loginValidations}
            enableReinitialize
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <FieldsContainer>
                  <OnBoardingTextField label='Email' name='email' type='text' />
                  <OnBoardingTextField
                    label='Password'
                    name='password'
                    type='password'
                  />
                </FieldsContainer>
                {!isSubmitting ? (
                  <Button
                    disabled={!values.email || !values.password}
                    type='submit'
                  >
                    Log in
                  </Button>
                ) : (
                  <>
                    <div style={{ marginTop: '56px' }} />
                    <CircularProgress disableShrink />
                  </>
                )}
              </Form>
            )}
          </Formik>
          <PlainTextButton onClick={() => history.push('/forgot-password')}>
            Forgot Password?
          </PlainTextButton>
        </FormContainer>
      </MainContainer>

      <SideBar>
        <SideBarContent>
          <SideBarTitle>The safest way for you</SideBarTitle>
          <LogoContainer>
            <TitleImage src={TruckCaddyLogo} />
          </LogoContainer>
        </SideBarContent>
      </SideBar>
    </Container>
  );
};

export default AdminsLoginPage;
