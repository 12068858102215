import styled from 'styled-components';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const PickersWrapper = styled.div`
  border: 1px solid #91a5da;
  position: relative;

  .MuiFormControl-root {
    width: 50%;
  }

  &::before {
    left: 50%;
    top: 10%;
    z-index: 1;
    position: absolute;
    content: '';
    height: 80%;
    width: 1px;

    background-color: #91a5da;
  }

  .MuiFilledInput-root {
    background-color: #fff;
    width: 100%;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .MuiFilledInput-root.Mui-focused {
    background-color: #e8ecf9;
  }

  .MuiFilledInput-root:hover {
    background-color: #e8ecf9;
  }

  .MuiFilledInput-input {
    padding: 10px 12px;
    cursor: pointer;
  }

  .MuiInputBase-input {
    font-family: 'Nunito', sans-serif;
    font-weight: 300;
    font-size: 13px;
    text-align: center;
  }

  .MuiFilledInput-underline:after,
  .MuiFilledInput-underline:before,
  .MuiFilledInput-underline:hover:before {
    border-bottom: none;
  }
  &.my-tickets{
    @media(max-width: 1050px){
      margin-top: 10px;
    }
  }
`;

interface TimePickersProps {
  startHr: MaterialUiPickersDate;
  endHr: MaterialUiPickersDate;
  handleStartDateChange: (date: MaterialUiPickersDate) => void;
  handleEndDateChange: (date: MaterialUiPickersDate) => void;
  startLabel?: string;
  endLabel?: string;
  className?: string;
}

const TimePickersContainer = ({
  startHr,
  endHr,
  handleStartDateChange,
  handleEndDateChange,
  startLabel,
  endLabel,
  className
}: TimePickersProps): JSX.Element => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <PickersWrapper className={className}>
        <DatePicker
          emptyLabel={startLabel}
          value={startHr}
          onChange={handleStartDateChange}
          inputVariant="filled"
        />
        <DatePicker
          emptyLabel={endLabel}
          value={endHr}
          onChange={handleEndDateChange}
          inputVariant="filled"
        />
      </PickersWrapper>
    </MuiPickersUtilsProvider>
  );
};
export default TimePickersContainer;
