import React from 'react';
// Libraries
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import { RowRecord, TableColumn } from 'react-data-table-component';
// Components
import Layout from '../../shared/components/Layout/Layout';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import LaunchIcon from '@mui/icons-material/Launch';
import DataPresentation from '../../components/DataPresentation/DataPresentation';
import PayTicketButton from '../../components/PayTicketButton/PayTicketButton';
import TruckerInfoModal from '../../components/TruckerInfoModal/TruckerInfoModal';
import Table from '../../components/Table/Table';
import useGetTicketDetail from '../../hooks/useGetTicketDetail';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import RegularStyleCell from '../../components/RegularStyleCell/RegularStyleCell';
import DataWithSlash from '../../components/DataWithSlash/DataWithSlash';
// Others
import { useModal } from '../../contexts/modal-context';
import { TicketLoad } from '../../types/TicketDetail';


// Styles
const Card = styled.div`
  border-radius: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  margin: 20px 0;
`;
const DetailContainer = styled.div`
  padding: 15px 40px;
  background: #F6F7FC;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 360px) {
    padding: 15px;
  }
`;
const Row = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  justify-content: space-between;

`;
const Title = styled.h1`
  font-family: Cabin, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #16244A;
  text-transform: capitalize;
  margin: 0;
`;

const Cell = styled.div`
  background: #FFFFFF;
  border-radius: 1px;
  display: flex;
  justify-content: space-between;
  align-items: center;


  .MuiSvgIcon-root {
    color: #91A5DA;
`;
const CellName = styled.h3`
  font-size: 10px;
  font-family: Nunito, sans-serif;
  font-weight: 300;
  color: #1C3264;
  text-transform: uppercase;
  margin: 0;
`;
const CellValue = styled.span`
  font-size: 14px;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  color: #16244A;
  text-transform: capitalize;
`;
const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

const CellColumn = styled(Column)`
  padding: 7px 15px;
`;

const DetailGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 1fr auto;
  column-gap: 15px;
  row-gap: 10px;
  @media (max-width: 1050px) {
    grid-template-columns: 1fr;
    grid-template-rows: repetat(6, auto);
  }
  @media (max-width: 992px) {
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr auto;
  }
  @media (max-width: 750px) {
    grid-template-columns: 1fr;
    grid-template-rows: repetat(6, auto);
  }
`;
const CellIconWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #FFF;
  border-radius: 1px;
  margin-left: 5px;
  cursor: pointer;
  transition: background 0.2s;
  padding: 0 10px;
  height: 100%;

  &:hover {
    background: #E8ECF9;
  }
`;
const TableContainer = styled.div`
  padding: 15px 40px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media (max-width: 360px) {
    padding: 15px;
  }
`;

interface urlParams {
  id: string;
}

const TicketDetailPage = (): JSX.Element => {
  // Get access to the route history
  const history = useHistory();
  // modal handler
  const { requireModal } = useModal();
  // access to the url params
  const { id }: urlParams = useParams();
  // get the ticket detail
  const { loading, detail, fetchDetail } = useGetTicketDetail(id);

  // calls the modal context with the trucker info modal
  const showTruckerDetail = () => {
    requireModal(<TruckerInfoModal data={detail?.trucker} />);
  };
  // navigate to the load detail
  const handleRowClicked = ({ id }: RowRecord): void => {
    history.push(`/load-details/${id}`);
  };
  // navigate to the job detail
  const navigateToJobDetail = (): void => {
    history.push(`/job-details/${detail?.jobId}`);
  };

  // data table columns
  const columns: TableColumn<TicketLoad>[] = [
    {
      name: '#',
      selector: (currentLoad: TicketLoad) => currentLoad.numberOfLoad,
      sortable: true,
      center: true,
      cell: function renderNumberOfLoad(currentLoad: TicketLoad) {
        return <RegularStyleCell value={currentLoad.numberOfLoad} />;
      },
      width: '100px',
    },
    {
      name: 'Load id',
      selector: (currentLoad: TicketLoad) => currentLoad.id,
      sortable: true,
      center: true,
    },
    {
      name: 'Start hr',
      selector: (currentLoad: TicketLoad) => currentLoad.startDate,
      sortable: true,
      center: true,
      cell: function renderStartDate(currentLoad: TicketLoad) {
        return <RegularStyleCell value={currentLoad.startDate} />;
      },
      minWidth: '120px',
    },
    {
      name: 'End hr',
      selector: (currentLoad: TicketLoad) => currentLoad.endDate,
      sortable: true,
      center: true,
      cell: function renderEndDate(currentLoad: TicketLoad) {
        return <RegularStyleCell value={currentLoad.endDate} />;
      },
      minWidth: '120px',
    },
    {
      name: 'Duration',
      selector: (currentLoad: TicketLoad) => currentLoad.duration,
      sortable: true,
    },
    {
      name: 'Distance',
      selector: (currentLoad: TicketLoad) => currentLoad.distance,
      sortable: true,
      minWidth: '120px',
    },
    {
      name: 'Total / Rate',
      selector: (currentLoad: TicketLoad) => currentLoad.total,
      sortable: true,
      cell: function renderTotalRate(currentLoad: TicketLoad) {
        return <DataWithSlash
          left={currentLoad.total}
          right={`${currentLoad.rate} Hourly`}
        />;
      },
      minWidth: '180px',
    },
  ];
  return (
    <Layout>
      {loading ? <LoadingScreen /> : <Card>
        <DetailContainer>
          <Row>
            <div>
              <Title>
                Ticket #{detail?.id}
              </Title>
            </div>
            <PayTicketButton ticketId={id} status={detail?.status} onFinalize={fetchDetail} />
          </Row>
          <DetailGrid>
            <Cell>
              <CellColumn>
                <CellName>Job Site</CellName>
                <CellValue>{detail?.jobSite}</CellValue>
              </CellColumn>
              {loading ? null :
                <CellIconWrapper onClick={navigateToJobDetail}>
                  <KeyboardArrowRightIcon />
                </CellIconWrapper>
              }
            </Cell>
            <Cell>
              <CellColumn>
                <CellName>Date</CellName>
                <CellValue>{detail?.date}</CellValue>
              </CellColumn>
            </Cell>
            <Cell>
              <CellColumn>
                <CellName>Driver and Truck Type</CellName>
                <CellValue>{detail?.trucker.name} - {detail?.trucker.truckType}</CellValue>
              </CellColumn>
              {loading ? null :
                <CellIconWrapper onClick={showTruckerDetail}>
                  <LaunchIcon />
                </CellIconWrapper>
              }
            </Cell>
            <DataPresentation name='Total loads' value={detail?.loadsCount} className='ticket-detail' />
            <DataPresentation name='Total Duration (HRS)' value={detail?.totalDuration} className='ticket-detail' />
            <DataPresentation name='Total Amount' value={detail?.totalAmount} className='ticket-detail' />
          </DetailGrid>
        </DetailContainer>
        <TableContainer>
          <Table
            className='ticket-detail'
            columns={columns}
            data={detail?.loads || []}
            handleRowClicked={handleRowClicked}
            isLoading={loading}
            tableName='loads'
            disablePagination
          />
        </TableContainer>

      </Card>}

    </Layout>

  );
};

export default TicketDetailPage;
