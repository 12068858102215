import { Grid } from '@material-ui/core';
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import styled from 'styled-components';

import GoogleSearch from '../GoogleSearch/GoogleSearch';

const Wrapper = styled.div`
  height: 300px;
`;

const Error = styled.span`
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

const MapContainer = (props) => {
  const {
    initialLocation,
    handleLocation,
    google,
    searchValue,
    handleSearchValue,
    isSubmitted,
  } = props;

  /**
   * handle the drag and drop event from the markers and store the new coordinates
   * @param {object} coord coordinates of the marker.
   */
  const handleOnDragEnd = (
    current: string,
    map: string,
    coord: Record<string, { lat: () => number; lng: () => number }>
  ) => {
    const { latLng } = coord;

    const lat = latLng.lat();
    const lng = latLng.lng();
    handleLocation({ lat, lng });
  };

  const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%',
  };

  const style = {
    display: 'flex',
    height: '300px',
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <GoogleSearch
          handleSearch={handleSearchValue}
          value={searchValue}
          handleMap={handleLocation}
        />
        {(!searchValue.address || !searchValue.components.address) &&
          isSubmitted ? (
          <Error>Required</Error>
        ) : null}
      </Grid>
      <Grid item xs={12}>
        <Wrapper>
          <Map
            style={style}
            containerStyle={containerStyle}
            google={google}
            initialCenter={initialLocation}
            center={initialLocation}

          >
            <Marker
              position={initialLocation}
              draggable
              onDragend={handleOnDragEnd}
            />
          </Map>
        </Wrapper>
      </Grid>
    </Grid>
  );
};

export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyBG7dgqYVoOnxtU6doKTM_iWqmfwXQUry4',
  language: 'en',
}))(MapContainer);
