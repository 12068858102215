import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse, AxiosError, AxiosPromise } from 'axios';
import { useNotification } from '../contexts/notification-context';

interface returnValue {
  loading: boolean;
  value?: Record<string, unknown>;
}


export default function useInitValues(
  callback: () => AxiosPromise
): returnValue {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<Record<string, unknown>>();

  const { setNotification } = useNotification();

  const callbackMemoized = useCallback(() => {
    setLoading(true);
    setValue(undefined);
    callback()
      .then(({ data }: AxiosResponse) => {
        setValue(data);
      })
      .catch(({ response }: AxiosError) => {
        setNotification({
          type: 'error',
          message: response?.data.message,
          open: true,
        });
      })
      .finally(() => setLoading(false));
  }, [setNotification, callback]);

  useEffect(() => {
    callbackMemoized();
  }, [callbackMemoized]);


  return { loading, value };
}
