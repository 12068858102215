import API from './API';
import { endpoints } from './Constants';
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { UserListResponse } from '../types/responses/users-list';

export const getUsers = async (
  config?: AxiosRequestConfig
): Promise<AxiosResponse<UserListResponse>> => {
  return await API.get(endpoints.users.list, config);
};
