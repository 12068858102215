import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

import {
  AvailableStatus,
  StatusColors,
  StatusTextColors,
} from '../../types/Status';

interface ContainerProps {
  bgcolor: string;
  textcolor: string;
}

const Container = styled(Chip)<ContainerProps>`
  background-color: ${(props) => `${props.bgcolor} !important`};
  text-transform: capitalize !important;
  font-weight: 700;
  min-width: 88px;
  height: 27px !important;
  color: ${(props) => `${props.textcolor} !important`};
  cursor: pointer !important;
`;

function Badge({ value }: { value: AvailableStatus }): JSX.Element {
  const color = StatusColors[value];
  const textcolor = StatusTextColors[value];

  return (
    <Container
      data-tag="allowRowEvents"
      label={<div data-tag="allowRowEvents">{value}</div>}
      bgcolor={color}
      textcolor={textcolor}
    />
  );
}

export default Badge;
