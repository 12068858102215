import styled from 'styled-components';

import { pattern } from '../../assets/images';
import { useHistory } from 'react-router-dom';
import { PlainTextButton } from '../../pages/Login/login.styles';

const Content = styled.div`
  background-image: url(${pattern});
  background-size: cover;
  height: 70vh;
  @media (max-width: 425px) {
    padding: 0 30px;
  }
`;

const Title = styled.h2`
  margin: 15vh 0;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  letter-spacing: -0.015em;
  color: #16244a;
`;

const Hint = styled.p`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 172.19%;
  color: #16244a;

  @media (max-width: 425px) {
    width: fit-content;
    text-align: left;
  }
`;

const Confirmation = (): JSX.Element => {
  const history = useHistory();
  return (
    <Content>
      <Title>Registration completed!</Title>
      <Hint>
        Click{' '}
        <PlainTextButton
          style={{ fontSize: '16px', fontWeight: 'bold' }}
          onClick={() => history.push('/login')}
        >
          here
        </PlainTextButton>{' '}
        to log in to your new account
      </Hint>
    </Content>
  );
};

export default Confirmation;
