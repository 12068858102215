import React, { Dispatch, SetStateAction } from 'react';
// Libraries
import DataTable, { ConditionalStyles, RowRecord, TableColumn } from 'react-data-table-component';
// Components
import SortIcon from '@material-ui/icons/ArrowDownward';
import { CircularProgress } from '@material-ui/core';
import ListPagination from '../ListPagination/ListPagination';
import EmptyTable from '../EmptyTable/EmptyTable';
import Checkbox from '../Checkbox/Checkbox';
// Types
import { LoadsQueryParams, QueryParamsProps } from '../../api/JobsAPI';
import { TicketsQueryParams } from '../../api/TicketsAPI';
// Styles
import { Wrapper } from './Table.styles';
import { JobBoardQueryParams } from '../../pages/JobBoard/job-board-constants';

interface tableProps<T, U> {
  columns: TableColumn<T>[];
  data: T[];
  totalRows?: number;
  paginationLimit?: number;
  setQueryParams?: Dispatch<SetStateAction<U>>;
  isLoading: boolean;
  handleRowClicked: (row: RowRecord) => void;
  tableName: string;
  className?: string;
  selectableRows?: boolean;
  handleSelectRow?: (selected: {
    allSelected: boolean;
    selectedCount: number;
    selectedRows: T[];
  }) => void;
  askToCreateFirst?: boolean;
  disablePagination?: boolean;
  conditionalRowStyles?: ConditionalStyles<T>[];
}

const Table = <T extends RowRecord,
  U extends QueryParamsProps | LoadsQueryParams | TicketsQueryParams | JobBoardQueryParams | unknown>({
  columns,
  data,
  totalRows,
  paginationLimit,
  setQueryParams,
  isLoading,
  handleRowClicked,
  tableName,
  className,
  selectableRows,
  handleSelectRow,
  askToCreateFirst,
  disablePagination,
  conditionalRowStyles
}: tableProps<T, U>): JSX.Element => {
  // rows to display per page options
  const rowsPerPageOptions = [3, 10, 15, 20, 25, 30];

  return (
    <Wrapper className={className}>
      <DataTable
        columns={columns}
        data={data}
        sortIcon={<SortIcon />}
        highlightOnHover
        pointerOnHover
        pagination={!Boolean(disablePagination)}
        paginationServer
        paginationTotalRows={totalRows}
        paginationPerPage={paginationLimit}
        paginationComponent={(props) => (
          <ListPagination
            {...props}
            setQueryParams={setQueryParams}
            rowsPerPageOptions={rowsPerPageOptions}
          />
        )}
        progressPending={isLoading}
        progressComponent={<CircularProgress disableShrink />}
        noDataComponent={<EmptyTable tableName={tableName} askToCreateFirst={askToCreateFirst} />}
        onRowClicked={handleRowClicked}
        selectableRows={selectableRows}
        onSelectedRowsChange={handleSelectRow}
        selectableRowsComponent={Checkbox}
        customStyles={{ rows: { style: { color: '#16244a' } } }}
        conditionalRowStyles={conditionalRowStyles}
      />
    </Wrapper>
  );
};

export default Table;
