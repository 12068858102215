import styled from 'styled-components';

const Empty = styled.div`
  background: #e8ecf9;
  border-radius: 1px;
  display: flex;
  width: 100%;
  padding: 19px 22px;
  margin: 20px 0px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #91a5da;
`;

interface EmptyTableProps {
  tableName: string;
  askToCreateFirst?: boolean;
}

function EmptyTable({ tableName, askToCreateFirst }: EmptyTableProps): JSX.Element {
  if (Boolean(askToCreateFirst)) return (
    <Empty>
      Empty {tableName} list (Create your fist {tableName})
    </Empty>
  );
  return (
    <Empty>
      Empty {tableName} list
    </Empty>
  );
}

export default EmptyTable;
