import styled from 'styled-components';


export const Container = styled.div`
  position: relative;
  padding: 60px 60px 50px;
  width: 100%;
  max-width: 650px;
  background-color: white;
  font-family: Nunito, sans-serif;
  color: #16244a;
  border: none;
  border-radius: 3px;
  box-shadow: 0 0 8px 0 #0000000f;
  outline: none;

  @media (max-width: 450px) {
    padding: 50px 30px 25px;
  }
  @media (max-width: 365px) {
    padding: 50px 15px 12px;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  letter-spacing: -0.015em;
  line-height: 136.19%;
  margin: 0;
  text-align: center;
`;

export const Subtitle = styled.div`
  font-weight: 300;
  font-size: 16px;
  text-align: center;
  line-height: 27.55px;
`;

interface ModalInputProps {
  hasError?: boolean;
}

export const ModalInput = styled.textarea`
  display: flex;
  box-sizing: border-box;
  border: ${({ hasError }: ModalInputProps) =>
    hasError ? '1px solid #ff537c' : '1px solid #91a5da'};
  border-radius: 3px;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 35px;
  outline: none;
  padding: 5px;
  flex: 1;
`;

export const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 15px;
`;
export const ConfirmButton = styled.button`
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 14px;
  text-align: center;
  line-height: 161.19%;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  background-color: #ff537c;
  color: #fff;
  border: none;
  border-radius: 3px;
  padding: 10px;
  width: 135px;
  transition: background-color 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #e9426a;
  }
`;

export const DenyButton = styled(ConfirmButton)`
  background-color: #e8ecf9;
  color: #16244b;

  &:hover {
    background-color: #ccd1e2;
  }
`;

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 40px;
  top: 26px;
  cursor: pointer;
  @media (max-width: 600px) {
    right: 15px;
    top: 15px;
  }
`;
export const ErrorMsg = styled(Subtitle)`
  color: #ff537c;
`;

export const Separator = styled.div`
  width: 100%;
  margin-top: 25px;
`;

