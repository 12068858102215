import {
  GoogleAddress,
  GoogleAddressComponents,
} from '../types/GoogleAddress';

/**
 * format date to yyyy-mm-dd for the api or to mm/dd/yyyy for the UI
 * @param dateToFormat raw date without formatting
 * @param prettify makes the function return the date in a optional prettier format for the user
 */
export const formatDate = (
  dateToFormat: string,
  prettify?: boolean,
): string => {
  const date = new Date(dateToFormat);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  if (prettify) {
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
    return `${month}/${day}/${year}`;
  }
  const month = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(date);
  return `${year}-${month}-${day}`;
};

// format date to: Aug/11/2021
export const formatDateWithSlash = (dateToFormat: string): string => {
  const date = new Date(dateToFormat);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
  return `${month}/${day}/${year}`;
};

// format date to: August 11, 2021
export const formatDateLongMonth = (dateToFormat: string): string => {
  const date = new Date(dateToFormat);
  const year = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(date);
  const day = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(date);
  const month = new Intl.DateTimeFormat('en', { month: 'long' }).format(date);
  return `${month} ${day}, ${year} `;
};

// format hour to the specific format: 10:00 AM
export const formatHour = (hour: string): string => {
  const newHour = new Date(hour);
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
  };
  return newHour.toLocaleTimeString('en-US', options);
};

// format hour to the specific format: 10:00:00 AM
export const formatHourWithSeconds = (hour: string): string => {
  const newHour = new Date(hour);
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  };
  return newHour.toLocaleTimeString('en-US', options);
};

// format a number to local us string
export const parseNumberToLocale = (num = 0): number => {
  const newNumber = Number(num).toLocaleString('en-US', {maximumFractionDigits: 2});
  return parseFloat(newNumber);
};

// format a number to local us string
export const parseNumberToLocaleString = (num = 0): string => {
  return Number(num).toLocaleString('en-US', {maximumFractionDigits: 2});
};

// format a number to have al least 3 digits
export const padNumber = (num: number, minimunDigits: number): string => {
  return num.toLocaleString('en', { minimumIntegerDigits: minimunDigits, useGrouping: false });
};

// format a number into a currency string
export const formatCurrency = (value?: number): string => {
  return '$' + parseNumberToLocaleString(value);
};

// format a google location into a valid object to store in the database
export const formatAddress = (
  addressComponents: GoogleAddressComponents[],
): GoogleAddress => {
  const result = {
    address: '',
    city: '',
    state: '',
    stateAbbr: '',
    zipCode: '',
    country: 'USA',
  };
  const streetNum = findByPropertyAndValue('street_number', addressComponents);
  const route = findByPropertyAndValue('route', addressComponents);
  const city = findByPropertyAndValue('locality', addressComponents);
  const state = findByPropertyAndValue(
    'administrative_area_level_1',
    addressComponents,
  );
  const zipCode = findByPropertyAndValue('postal_code', addressComponents);
  result.address =
    streetNum && route ? `${streetNum.long_name} ${route.long_name}` : '';
  result.city = city?.long_name || '';
  result.state = state?.long_name || '';
  result.stateAbbr = state?.short_name || '';
  result.zipCode = zipCode?.long_name || '';
  return result;
};

/**
 *  find an element based on a property value
 * @param {string} value value to search for.
 * @param {array} array array where to search for the value passed
 */
const findByPropertyAndValue = (
  value: string,
  array: {
    long_name: string;
    short_name: string;
    types: string[];
  }[],
):
  | {
  long_name: string;
  short_name: string;
  types: string[];
}
  | undefined => {
  return array.find((component) => component.types[0] == value);
};

// return truck types list as a string and separate it by a coma
export const getTruckTypesAsString = (array: string[]): string => {
  return array.join(', ');
};

// Format a number into phone number +xx (xxx) xxx-xxxxx
export const formatPhoneNumber = (num: number): string | null => {
  // Convert the input into a string
  const convertedValue = num.toString();
  //Check if the input is of correct length and expected format
  const match = convertedValue.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);

  if (match) {
    return `+${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
  }

  return null;
};

// Format a date only string into a string like AUG/11/2021
export const formatDateOnly = (dateToFormat: string): string => {
  //Check if the input is of correct length and expected format
  const match = dateToFormat.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) return dateToFormat;
  const year = match[1];
  const month = nameOfTheMonths[match[2] as keyof typeof nameOfTheMonths];
  const day = match[3];
  return `${month}/${day}/${year}`;
}

// Format a date only string into a string like Aug 11,2021
export const formatDateOnlyPrettier = (dateToFormat: string): string => {
  //Check if the input is of correct length and expected format
  const match = dateToFormat.match(/^(\d{4})-(\d{2})-(\d{2})$/);
  if (!match) return dateToFormat;
  const year = match[1];
  const month = nameOfTheMonths[match[2] as keyof typeof nameOfTheMonths];
  const day = match[3];
  return `${month} ${day}, ${year}`;
}

const nameOfTheMonths = {
  '01': 'Jan',
  '02': 'Feb',
  '03': 'Mar',
  '04': 'Apr',
  '05': 'May',
  '06': 'Jun',
  '07': 'Jul',
  '08': 'Aug',
  '09': 'Sep',
  '10': 'Oct',
  '11': 'Nov',
  '12': 'Dec',
}
// Format a decimal number into its equivalent in hours
export const decimalToHour = (decimal: number): string => {
  return `${Math.floor(decimal)}`;
}
// Format a decimal number into its equivalent in minutes ignoring the hours
export const decimalToMinute = (decimal: number): string => {
  const hours =  Math.floor(decimal);
  const minutes =  (decimal-hours) * 60;
  return padNumber(Math.floor(minutes), 2);
}