import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { HttpGetLoadList, LoadsQueryParams } from '../api/JobsAPI';
import { Load } from '../types/Load';
import { useNotification } from '../contexts/notification-context';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { formatDate } from '../helpers/formats';

interface returnType {
  loading: boolean;
  loads: Load[] | [];
  totalLoads: number;
  since: MaterialUiPickersDate;
  until: MaterialUiPickersDate;
  queryParams: LoadsQueryParams;
  setQueryParams: Dispatch<SetStateAction<LoadsQueryParams>>;
  handleStartDateChange: (date: MaterialUiPickersDate) => void;
  handleEndDateChange: (date: MaterialUiPickersDate) => void;
  handleSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
}

export default function useGetLoads(
  jobId: string,
  loadStatusList: { id: string; name: string }[]
): returnType {
  // notifications handler
  const { setNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const [loads, setLoads] = useState<Load[] | []>([]);
  const [totalLoads, setTotalLoads] = useState(0);

  const [since, setSince] = useState<MaterialUiPickersDate>(null);
  const [until, setUntil] = useState<MaterialUiPickersDate>(null);
  const [queryParams, setQueryParams] = useState<LoadsQueryParams>({
    page: 1,
    limit: 10,
    since: '',
    until: '',
    status: '',
  });

  // Make the request to bring the loads of the job
  const getLoadList = useCallback(() => {
    setLoading(true);
    HttpGetLoadList(queryParams, jobId)
      .then(({ data }) => {
        if (data.success) {
          console.log(data.loads);
          const loadsList = data.loads.map((load) => new Load(load));
          setLoads(loadsList);
          setTotalLoads(data.totalLoads);
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => setLoading(false));
  }, [queryParams, jobId, setNotification]);

  useEffect(() => {
    getLoadList();
  }, [getLoadList]);

  const handleEndDateChange = (date: MaterialUiPickersDate): void => {
    if (!!date) {
      setUntil(date);
      const formattedDate = formatDate(date?.toString());
      setQueryParams({ ...queryParams, until: formattedDate });
    }
  };

  const handleStartDateChange = (date: MaterialUiPickersDate): void => {
    if (!!date) {
      setSince(date);
      const formattedDate = formatDate(date?.toString());
      setQueryParams({ ...queryParams, since: formattedDate });
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const statusSelected = event.target.value;
    const lastStatusID = loadStatusList.length;
    if (statusSelected === lastStatusID) {
      setQueryParams({ ...queryParams, status: '', page: 1 });
    } else
      setQueryParams({
        ...queryParams,
        status: statusSelected as string,
        page: 1,
      });
  };

  return {
    loading,
    loads,
    totalLoads,
    queryParams,
    setQueryParams,
    handleEndDateChange,
    handleStartDateChange,
    handleSelectChange,
    since,
    until,
  };
}
