import { FC } from 'react';
import { useField } from 'formik';
import styled from 'styled-components';
import TextField from '@material-ui/core/TextField';

const Input = styled(TextField)`
  width: 373px;
  padding: 10px;

  @media (max-width: 375px) {
    width: 100%;
  }

  & label {
    font-family: Nunito;
    color: #16244a;
    font-size: 16px;
  }

  & label.Mui-focused {
    color: #405daa;
    font-size: 16px;
  }

  & .MuiInputBase-input {
    font-family: Nunito;
    font-weight: 600;
    font-size: 14px;
    color: #16244a;
    background: white;
  }

  & .MuiOutlinedInput-root {
    & fieldset {
      border-color: #91a5da;
    }

    &:hover fieldset {
      border-color: #91a5da;
    }

    &.Mui-focused fieldset {
      border-color: #405daa;
      box-shadow: 0px 4px 4px #eef1f9;
    }
  }
`;

const TextInput: FC = (props) => {
  const [field, meta] = useField(props);
  const isInvalid = !!(meta.touched && meta.error);
  return (
    <Input
      variant="outlined"
      {...field}
      {...props}
      error={isInvalid}
      helperText={isInvalid ? meta.error : ''}
    />
  );
};

export default TextInput;
