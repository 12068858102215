import { FC } from 'react';
import Layout from '../../shared/components/Layout/Layout';

const BillingInfoPage: FC = () => {
  return (
    <Layout>
      <div>Billing Info Page</div>
    </Layout>
  );
};

export default BillingInfoPage;
