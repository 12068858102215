const { REACT_APP_API_URL } = process.env;

export const endpoints = {
  BASE_URL: REACT_APP_API_URL,
  auth: {
    base: 'auth/',
    login: 'login/',
    adminsLogin: 'superadmin/login',
    logout: 'logout/',
    refreshToken: 'refresh-token/',
    register: 'register-webuser/',
    confirmEmail: 'confirmemail/',
    forgotPass: 'forgotpassword/',
    resetPass: 'passwordreset/',
  },
  lists: {
    base: 'lists/',
    countries: 'countries/',
    states: 'states/',
    jobStatus: 'job-status/',
    loadStatus: 'load-status',
    truckTypes: 'truck-types/',
    jobTypes: 'job-types/',
    ticketStatus: 'ticket-status/',
    roles: 'roles/',
  },
  jobs: {
    base: 'gc/jobs/',
    myJobs: 'my-jobs/',
    detail: 'detail/',
    loads: 'loads/',
    jobStatus: 'job-status/',
    jobBoard: 'tn/jobs/global-jobs',
  },
  loads: {
    base: 'gc/loads/',
    detail: 'detail/',
    cancel: 'cancel-load/',
  },
  locations: {
    base: 'gc/locations/',
  },
  projects: {
    base: 'gc/projects/',
  },
  account: {
    base: 'profile/',
    myAccount: 'my-account/',
    password: 'change-password/',
  },
  tickets: {
    base: 'gc/tickets/',
    pay: 'pay-tickets/',
  },
  users: {
    list: 'super/users/'
  }
};

export const endpointsUnprotected = [
  `${endpoints.auth.base}${endpoints.auth.login}`,
];
