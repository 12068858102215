import styled from 'styled-components';

const Wrapper = styled.div`
  font-weight: 400;
  text-transform: none;
`;

interface CellInterface {
  value: string | number;
}

const RegularStyleCell = ({ value }: CellInterface): JSX.Element => {
  return <Wrapper data-tag='allowRowEvents'>{value}</Wrapper>;
};

export default RegularStyleCell;