import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import styled from 'styled-components';
import { FormControl, Input, MenuItem, ListItemText } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';

const Field = styled(FormControl)`
  width: 100%;
  background-color: white !important;
  border-radius: 4px;
  border: ${({ $empty }: { $empty: boolean }) =>
    $empty ? '1px solid red !important' : '1px solid #405daa !important'};

  .MuiInput-underline:before {
    border: none !important;
  }

  .MuiInput-underline:after {
    border: none !important;
  }

  .MuiSelect-root {
    padding: 10px 15px !important;
    font-family: Nunito !important;
    color: ${({ $empty }: { $empty: boolean }) =>
      $empty ? 'red !important' : '#16244a !important'};
    font-size: 14px !important;
    :hover {
      background-color: none !important;
    }
  }
`;

const Placeholder = styled.span`
  font-family: Nunito;
  color: #16244a;
  font-size: 14px;
  color: ${({ $empty }: { $empty: boolean }) => ($empty ? 'red' : '#808080')};
`;

const Error = styled.p`
  color: #f44336;
  font-size: 0.75rem;
  margin-top: 3px;
  text-align: left;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.66;
  letter-spacing: 0.03333em;
`;

interface MultipleSelectProps {
  options: { id: number; name: string }[];
  placeholder: string;
  onChange: (value: { id: number; name: string }[]) => void;
  selected: { id: number; name: string }[];
  isSubmitted: boolean;
}

const MultipleSelect = ({
  options,
  placeholder,
  selected,
  onChange,
  isSubmitted,
}: MultipleSelectProps): JSX.Element => {
  // handle multiple select change event
  const handleChange = (
    event: React.ChangeEvent<{
      name: string | undefined;
      value: { id: number; name: string }[];
    }>
  ): void => {
    onChange(event.target.value);
  };

  // check if the value is the option is already selected
  const isChecked = (option: { id: number; name: string }): boolean => {
    return !!selected.find((val) => val == option);
  };

  return (
    <>
      <Field $empty={!selected.length && isSubmitted}>
        <Select
          name="MultiSelect"
          displayEmpty
          multiple
          MenuProps={{ getContentAnchorEl: null }}
          value={selected}
          onChange={handleChange}
          input={<Input placeholder="Placeholder" />}
          IconComponent={(props) => <KeyboardArrowDown {...props} style={{ marginRight: '5px' }} />}
          renderValue={(selected: { id: number; name: string }[]) => {
            if (!selected.length) {
              return (
                <Placeholder $empty={!selected.length && isSubmitted}>
                  {placeholder}
                </Placeholder>
              );
            }
            const optionsSelected = selected.map((option) => option?.name);
            return optionsSelected.join(', ');
          }}
        >
          {options.map((option: { id: number; name: string }) => (
            <MenuItem key={option.id} value={option}>
              <Checkbox color="primary" checked={isChecked(option)} />
              <ListItemText primary={option.name} />
            </MenuItem>
          ))}
        </Select>
      </Field>
      {!selected.length && isSubmitted ? <Error>Required</Error> : null}
    </>
  );
};

export default MultipleSelect;
