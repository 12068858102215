import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto auto;
  column-gap: 12px;
  row-gap: 6px;
  @media (max-width: 600px) {
    column-gap: 6px;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 5px;
  background: #E8ECF9;
  transition: background 0.7s ease-in-out;

  &.active {
    background: #405DAA;
  }
`;

const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 10px;
  @media (max-width: 600px) {
    align-items: flex-start;
  }
`;

const Circle = styled.span`
  margin: 0;
  background: #FFF;
  border-radius: 50%;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  color: #405DAA;
  height: 18px;
  width: 18px;
  text-align: center;
  padding-top: 2px;
  box-shadow: inset 0 0 0 1px #E8ECF9;
  transition: all 0.7s ease-in-out;

  &.active {
    box-shadow: unset;
    background: #F7D55E;
  }

  @media (max-width: 600px) {
    display: none;
  }
`;

const CurrentStep = styled.span`
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 14px;
  color: #16244A;
  margin-left: 5px;
  letter-spacing: 0.075em;
  text-transform: uppercase;
  @media (max-width: 600px) {
    margin-left: 0;
    font-size: 11px;
    letter-spacing: -0.05em;
  };
`;

interface StepperProps {
  steps: string[];
  activeStep: number;
}


const CustomStepper = ({ steps, activeStep }: StepperProps): JSX.Element => {
  return (
    <Wrapper>
      <Grid>
        {steps.map((_, index) => (
          <Line key={index} className={activeStep >= index ? 'active' : undefined} />
        ))}
        {steps.map((name, index) => (
          <Label key={index}>
            <Circle className={activeStep >= index ? 'active' : undefined}>{index + 1}</Circle>
            <CurrentStep>{name}</CurrentStep>
          </Label>
        ))}

      </Grid>
    </Wrapper>
  );
};

export default CustomStepper;
