export class User {
  name?: string;
  lastName?: string;
  email: string;
  phone?: string;
  companyName?: string;
  country?: number;
  confirmedEmail: boolean;
  token: string;
  refreshToken: string;
  roles?: number[];

  constructor(data: {
    user: Record<string, never>;
    token: string;
    refresh_token: string;
  }) {
    this.name = data.user.userProfile && data.user.userProfile['name'];
    this.lastName = data.user.userProfile && data.user.userProfile['lastname'];
    this.email = data.user.email;
    this.phone = data.user.userProfile && data.user.userProfile['phone'];
    this.companyName =
      data.user.userProfile && data.user.userProfile['companyName'];
    this.country =
      data.user.userProfile && data.user.userProfile['companyCountry'];
    this.confirmedEmail = data.user['confirmedEmail'];
    this.token = data.token;
    this.refreshToken = data.refresh_token;
    this.roles = data.user.roles;
  }
}

export interface UserProfile {
  userType: number;
  name: string;
  lastname: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;
  companyName: string;
  location?: { state: string; country: string; stateAbbr: string };
}
