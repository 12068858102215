import { useState } from 'react';
import { IMarkerProps, markerEventHandler } from 'google-maps-react';

interface returnType {
  showInfo: boolean;
  activeMarker: google.maps.Marker | undefined;
  onMarkerClick: markerEventHandler;
  handleClose: () => void;
}

export default function useMarkers(): returnType {
  // show or hide the InfoWindow Component
  const [showInfo, setShowInfo] = useState(false);
  // reference of the most recently clicked marker
  const [activeMarker, setActiveMarker] = useState<google.maps.Marker>();

  // set the marker reference and value
  const onMarkerClick: markerEventHandler = (
    props?: IMarkerProps,
    marker?: google.maps.Marker
  ) => {
    if (showInfo) {
      setShowInfo(false);
    }
    setActiveMarker(marker);
    setShowInfo(true);
  };

  // control the closing of the InfoWindow
  const handleClose = () => {
    setShowInfo(false);
  };

  return { showInfo, activeMarker, onMarkerClick, handleClose };
}