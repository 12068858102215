import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import PlaceIcon from '@material-ui/icons/RoomOutlined';
import styled from 'styled-components';
import { StylesConfig, GroupBase } from 'react-select';
import { Option } from 'react-google-places-autocomplete/build/types';

export const Container = styled.div`
  display: flex;
  align-items: center;
  border-radius: 4px;
  font-size: 14px;
  cursor: text;
  position: relative;
  color: #16244a;
  border: 1px solid #405daa;
`;

type Props = {
  value?: string;
  placeholder: string;
  onChange: (value: string) => void;
};

const GooglePlacesInput: React.FC<Props> = ({
  value,
  placeholder,
  onChange
}) => {
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  // https://react-select.com/props
  const styles: StylesConfig<Option, false, GroupBase<Option>> = {
    control: (provided) => ({
      ...provided,
      border: 'none',
      backgroundColor: 'transparent',
      boxShadow: 'none',
      cursor: 'text',
      padding: '3px 8px 3px 18px'
    }),
    dropdownIndicator: () => ({
      display: 'none'
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    container: (provided) => ({
      ...provided,
      minWidth: '140px',
      width: '100%'
    }),
    clearIndicator: (provided) => ({
      ...provided,
      cursor: 'pointer',
      padding: '0px'
    }),
    loadingIndicator: (provided) => ({
      ...provided,
      padding: '0px'
    })
  };

  return (
    <Container>
      <PlaceIcon
        style={{
          color: '#91A5DA',
          width: 20,
          height: 20,
          position: 'absolute',
          marginLeft: 5
        }}
      />
      <GooglePlacesAutocomplete
        apiKey={apiKey}
        debounce={400}
        autocompletionRequest={{
          types: ['(cities)'],
        }}
        selectProps={{
          isClearable: true,
          value: value ? { label: value, value } : undefined,
          onChange: (newValue) => onChange(newValue?.label ?? ''),
          placeholder,
          noOptionsMessage: () => 'No results found',
          styles
        }}
      />
    </Container>
  );
};

export default GooglePlacesInput;
