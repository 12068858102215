import styled from 'styled-components';

const Container = styled.div`
  background-color: ${({ time, unit }: { time: string; unit: string }) => {
    if (+time <= 0) {
      return '#E5F7E7';
    } else if (+time <= 24 && unit.toLowerCase() === 'h') {
      return '#FFE9C8';
    } else {
      return '#E3E5EB';
    }
  }};
  color: ${({ time, unit }: { time: string; unit: string }) => {
    if (+time <= 0) {
      return '#069A49';
    } else if (+time <= 24 && unit.toLowerCase() === 'h') {
      return '#D87417';
    } else {
      return '#4c556c';
    }
  }};
  text-align: center;
  border-radius: 4px;
  padding: 4px 2px;
  min-width: 40px;
`;

const JobAge: React.FC<{ age: string }> = ({ age }) => {
  const time = age.match(/\d+/g)?.[0] ?? '0';
  const unit = age.charAt(age.length - 1);

  return (
    <Container time={time} unit={unit}>
      <span>{age}</span>
    </Container>
  );
};

export default JobAge;
