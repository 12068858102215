import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components';

import LocationSelector from '../LocationSelector/LocationSelector';
import { NewJob } from '../../types/GoogleAddress';

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

const PrevButton = styled.button`
  width: 176px;
  height: 40px;
  background-color: #F6F7FC;
  color: #91A5DA;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
  cursor: pointer;
`;

const NextButton = styled.button`
  margin-left: 16px;
  width: 176px;
  height: 40px;
  background-color: #F7D55E;
  color: #16244A;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
  cursor: pointer;

  &:disabled {
      background-color: #FDF2CA;
  }
`;

interface PickupDropoffInfoProps {
  job: NewJob;
  updateJob: Dispatch<SetStateAction<NewJob>>;
  handleSubmitJob: (draft: boolean) => void;
  prevStep: () => void;
}

const PickupDropoffInfo = ({
  job,
  handleSubmitJob,
  prevStep,
  updateJob,
}: PickupDropoffInfoProps): JSX.Element => {
  const onLocationChange = (location: number) => {
    // update location on job state
    updateJob(job => ({
      ...job,
      location,
    }));
  }

  const handleSubmitAsDraft = () => handleSubmitJob(true);
  const handleSubmitAndPublish = () => handleSubmitJob(false);

  return (
    <>
      <LocationSelector
        key="pickup-dropoff-info"
        selected={job.location}
        onLocationChange={onLocationChange}
      />
      <ButtonsContainer>
        <PrevButton onClick={prevStep}>Prev</PrevButton>
        <NextButton disabled={!job.location} onClick={handleSubmitAsDraft}>Save as draft</NextButton>
        <NextButton disabled={!job.location} onClick={handleSubmitAndPublish}>Save and publish</NextButton>
      </ButtonsContainer>
    </>
  );
}

export default PickupDropoffInfo;
