import { FC, useState, useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StepContent, Step, StepLabel } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import UIStepper from '@material-ui/core/Stepper';
import styled from 'styled-components';

import { useNotification } from '../../contexts/notification-context';
import Layout from '../../shared/components/Layout/Layout';
import JobInfo from '../../components/JobInfo/JobInfo';
import JobSiteInfo from '../../components/JobSiteInfo/JobSiteInfo';
import PickupDropoffInfo from '../../components/PickupDropoffInfo/PickupDropoffInfo';
import { NewJob } from '../../types/GoogleAddress';
import { Job } from '../../types/Job';
import { Location } from '../../types/Location';
import { createJob, getDetails, update } from '../../api/JobsAPI';

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#91A5DA',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#91A5DA',
    },
  },
  line: {
    borderColor: '#91A5DA',
    borderWidth: 2,
  },
})(StepConnector);

const StepperContainer = styled(UIStepper)`

  .MuiStepContent-root:not(.MuiStepContent-last) {
    border-left: 2px solid #91A5DA !important;
  }

  .MuiStepLabel-label {
    font-family: Cabin;
    font-size: 22px;
    font-weight: 500;
    color: #81869A;
  }

  .MuiStepLabel-label.MuiStepLabel-active {
    font-weight: 700;
    color: #16244A !important;
  }

  .MuiStepLabel-label.MuiStepLabel-completed {
    color: #16244A !important;
  }

  .MuiStepIcon-text {
    font-family: Cabin;
    font-weight: bold;
    font-size: 18px;
    fill: #FFF;
    transform: translate(0, 2px);
  }

  .MuiStepIcon-active .MuiStepIcon-text {
    fill: #16244A
  }

  .MuiStepIcon-root {
    color: #C4C4C4;
  }

  .MuiStepIcon-root.MuiStepIcon-active {
    color: #F7D55E;
  }
`;

const StepContainer = styled(StepContent)`
  padding: 12px 36px !important;
`;

const Content = styled.div`
  margin: 17px 0px;
  width: 100%;
`;

const JobFormPage: FC = () => {
  // get the id from the url params
  const { id } = useParams();

  // handle ui notifications
  const { setNotification } = useNotification();

  // get access to the route history
  const history = useHistory();

  // step state
  const [activeStep, setActiveStep] = useState<number>(0);

  const [jobType, setJobType] = useState(1)

  const [formValues, setFormValues] = useState<NewJob>({
    name: '',
    material: '',
    type: '',
    loads: 0,
    loadsNotification: 0,
    startDate: '',
    rate: '',
    remarks: '',
    draft: false,
    jobSiteLocation: 0,
    location: 0,
    truckTypes: [],
    weight: '0',
  });

  // lists of steps available for the job creation
  const steps = ['Create Job', 'Job Site', 'Location'];

  const handleServerResponse = useCallback(
    (type: 'error' | 'success', message: string) => {
      setNotification({
        type,
        message,
        open: true,
      });
    },
    [setNotification]
  );

  // set response values into the form fields and state
  const setJobValuesIntoForm = useCallback(
    (values: Job): void => {
      const { id: locationId } = values.jobLocation as Location;
      const { id: jobSiteLocationId } = values.jobSiteLocation as Location;

      if (!locationId || !jobSiteLocationId || !values.typeId) return;

      setFormValues({
        jobSiteLocation: jobSiteLocationId,
        location: locationId,
        name: values.name,
        material: values.material,
        type: values.typeId,
        loads: values.loads,
        loadsNotification: values.loadsNotification,
        startDate: values.startDate,
        rate: values.rate,
        remarks: values.remarks,
        draft: false,
        truckTypes: values.truckType,
        weight: values.weight
      });
    }, []);

  // get the details of the selected job
  useEffect(() => {
    if (id) {
      getDetails(id)
        .then(({ data }) => {
          const details = new Job(data.job);
          setJobValuesIntoForm(details);
        })
        .catch(({ response }) => {
          handleServerResponse('error', response.data.msg);
        });
    }
  }, [id, handleServerResponse, setJobValuesIntoForm]);

  const handleSubmitJob = (draft: boolean) => {
    const newJob = { ...formValues, draft };

    if (id) {
      updateJob(
        newJob,
        (serverResponse: string) => {
          handleServerResponse('success', serverResponse);
          history.push('/my-jobs');
        },
        (serverResponse: string) => {
          handleServerResponse('error', serverResponse);
        }
      );
    } else {
      storeJob(
        newJob,
        (serverResponse: string) => {
          handleServerResponse('success', serverResponse);
          history.push('/my-jobs');
        },
        (serverResponse: string) => {
          handleServerResponse('error', serverResponse);
        }
      );
    }
  }

  const storeJob = (
    job: NewJob,
    onSuccess: (serverResponse: string) => void,
    onError: (serverResponse: string) => void
  ): void => {
    createJob(job)
      .then(({ data }) => {
        if (data.success) {
          onSuccess(data.msg);
        }
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          onError(response.data.errors[0].msg);
        } else {
          onError(response.data.msg);
        }
      });
  }

  const updateJob = (
    job: NewJob,
    onSuccess: (serverResponse: string) => void,
    onError: (serverResponse: string) => void
  ): void => {
    update(id, job)
      .then(({ data }) => {
        if (data.success) {
          onSuccess(data.msg);
        }
      })
      .catch(({ response }) => {
        if (response.data.errors) {
          onError(response.data.errors[0].msg);
        } else {
          onError(response.data.msg);
        }
      });
  };

  const handleNextStep = () => {
    if (activeStep === steps.length - 1) return;

    setActiveStep(step => step + 1);
  }

  const handlePrevStep = () => {
    if (activeStep === 0) return;

    setActiveStep(step => step - 1);
  }

  const getContentByStep = (stepIndex: number): JSX.Element => {
    switch (stepIndex) {
      case 0:
        return (
          <JobInfo
            job={formValues}
            setJobType={setJobType}
            updateJob={setFormValues}
            nextStep={handleNextStep}
          />
        );
      case 1:
        return (
          <JobSiteInfo
            job={formValues}
            updateJob={setFormValues}
            nextStep={handleNextStep}
            prevStep={handlePrevStep}
          />
        );
      case 2:
        return (
          <PickupDropoffInfo
            job={formValues}
            updateJob={setFormValues}
            handleSubmitJob={handleSubmitJob}
            prevStep={handlePrevStep}
          />
        );
      default:
        return (
          <div></div>
        );
    }
  }

  return (
    <Layout>
      <Content>
        <StepperContainer
          orientation="vertical"
          connector={<QontoConnector />}
          activeStep={activeStep}
        >
          {steps.map((step, index) => (
            <Step key={step}>
              <StepLabel>
                {index === 2
                  ? jobType === 1 ? 'Dropoff Location' : 'Pickup Location'
                  : step
                }
              </StepLabel>
              <StepContainer>
                {getContentByStep(index)}
              </StepContainer>
            </Step>
          ))}
        </StepperContainer>
      </Content>
    </Layout>
  );
}

export default JobFormPage;
