import React from 'react';

import styled from 'styled-components';

const Regular = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  padding: 9px 16px 9px 12px;
  border: 1px solid #e8ecf9;
  box-sizing: border-box;
  font-family: 'Nunito', sans-serif;
  font-weight: 700;
  font-size: 10px;
  color: #16244a;
  text-transform: uppercase;
  background: #e8ecf9;
  line-height: 17px;
  white-space: nowrap;
  &.ticket-detail{
    margin: 0;
    @media (max-width: 1050px) {
      flex-direction: column;
    }
    @media (max-width: 992px) {
      flex-direction: row;
    }
    @media (max-width: 750px) {
      flex-direction: column;
    }
  }
`;

const Green = styled(Regular)`
  background: rgba(130, 192, 29, 0.1);
  border: 1px solid #82c01d;
  font-weight: 400;
`;

const Name = styled.span`
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
`;

const Value = styled.div`
  font-weight: 700;
  font-family: 'Cabin', sans-serif;
  font-size: 14px;
`;

interface DataPresentationProps {
  variant?: string;
  name?: string;
  value?: string | number;
  className?: string;
}

const DataPresentation = ({
  variant,
  name,
  value,
  className
}: DataPresentationProps): JSX.Element => {
  if (variant === 'green')
    return (
      <Green>
        <Name>{!!name && name}</Name>
        <Value>{!!value && value}</Value>
      </Green>
    );
  else
    return (
      <Regular className={className}>
        <Name>{name}</Name>
        <Value>{value}</Value>
      </Regular>
    );
};

export default DataPresentation;