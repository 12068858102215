import { AxiosResponse } from 'axios';
import API from './API';
import { endpoints } from './Constants';

const BASE_URL = endpoints.lists.base;

/**
 * get the list of states based on a country id
 * @param {number} countryId country selected.
 */
export const statesByCountryId = async (
  countryId: number
): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.states}${countryId}`);
};

// get the list of job status
export const jobStatus = async (): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.jobStatus}`);
};

// get the list of load status
export const loadStatus = async (): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.loadStatus}`);
};

// get the list of truck types
export const getTruckTypes = async (): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.truckTypes}`);
};

// get the list of job types
export const getJobTypes = async (): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.jobTypes}`);
};

// get the list of ticket status
export const httpGetTicketStatus = async (): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.ticketStatus}`);
};

// get the list of roles
export const getRoles = async (only_creatable_roles : boolean): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${endpoints.lists.roles}${only_creatable_roles}`);
};

