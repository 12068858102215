import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

import {
  AvailableLoadStatus,
  LoadStatusColors,
  LoadStatusTextColors,
} from '../../types/Status';

interface ContainerProps {
  bgcolor: string;
  textcolor: string;
}

const Badge = styled(Chip)<ContainerProps>`
  background-color: ${(props) => `${props.bgcolor} !important`};
  text-transform: capitalize !important;
  font-weight: 700;
  min-width: 88px;
  height: 27px !important;
  color: ${(props) => `${props.textcolor} !important`};
`;

function LoadDetailBadge({
  value,
}: {
  value?: AvailableLoadStatus;
}): JSX.Element {
  if (!value) return <Badge label="-" bgcolor="##6CBD2C" textcolor="#FFF" />;
  const color = LoadStatusColors[value];
  const textcolor = LoadStatusTextColors[value];
  return <Badge label={value} bgcolor={color} textcolor={textcolor} />;
}

export default LoadDetailBadge;
