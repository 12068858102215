import React, { useEffect, useRef } from 'react';
import { Route, Redirect, useHistory } from 'react-router-dom';
import { RouteProps } from 'react-router';
import { useAuth } from '../contexts/auth-context';
import LoadingScreen from '../components/LoadingScreen/LoadingScreen';
import { Roles } from '../types/Roles';

interface RouteWrapperProps extends RouteProps {
  isPrivate?: boolean;
}

const truckersRoutes = ['/job-board', '/my-account'];

export default function RouteWrapper({
  component,
  isPrivate,
  path,
  exact
}: RouteWrapperProps): JSX.Element | null {
  const { user } = useAuth();

  const {
    location: { pathname: currentPath }
  } = useHistory();

  const isSuperAdmin = user?.roles?.includes(Roles.SUPER_ADMIN);
  const prefix = isSuperAdmin ? '/administrator' : '';

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    }
  });

  /**
   * Check if the app is rendering for the first time to avoid any
   * redirection on the first render where there's no user set.
   */
  if (isFirstRender.current) return <LoadingScreen />;

  /**
   * Redirect user to SignIn page if he tries to access a private route
   * without authentication.
   */
  if (isPrivate && !user) {
    return <Redirect to='/' />;
  }

  /**
   * Redirect user to Main page if he tries to access a non private route
   * (SignIn or SignUp) after being authenticated.
   */
  if (!isPrivate && user) {
    return <Redirect to={`${prefix}/job-board`} />;
  }

  const isTrucker = user?.roles?.includes(Roles.TRUCKER);

  /**
   * Redirect truckers to the Job Board when they try to access routes
   * not allowed for them.
   */
  if (isTrucker && path) {
    if (typeof path === 'string' && !truckersRoutes.includes(path)) {
      return <Redirect to='/job-board' />;
    }

    if (
      path instanceof Array &&
      !path.some((p) => truckersRoutes.includes(p))
    ) {
      return <Redirect to='/job-board' />;
    }
  }

  /* Handling the redirection logic for super admin users. */
  if (isSuperAdmin) {
    if (path instanceof Array) {
      const adminPath = path.find((p) => p.includes('/administrator'));
      if (!adminPath) {
        return <Redirect to={`${prefix}/job-board`} />;
      }

      if (adminPath !== currentPath) {
        return <Redirect to={adminPath} />;
      }
    }

    if (typeof path === 'string' && !path.includes('/administrator')) {
      return <Redirect to={`${prefix}${path}`} />;
    }
  }

  /**
   * If not included on the previous cases, redirect user to the desired route.
   */
  return <Route component={component} path={path} exact={exact} />;
}
