import { useCallback, useEffect, useRef, useState } from 'react'
import {
  Grid,
  List,
  InputLabel,
  CircularProgress,
  RadioGroup,
  FormControlLabel,
  Radio,
  RadioProps,
} from '@material-ui/core';
import { RadioButtonUncheckedOutlined, RadioButtonCheckedOutlined } from '@material-ui/icons';
import { Formik, FormikProps } from 'formik';
import styled from 'styled-components';
import * as Yup from 'yup';

import { useNotification } from '../../contexts/notification-context';
import GoogleMap from '../GoogleMap/GoogleMap';
import SearchInput from '../SearchInput/SearchInput';
import TextInput from '../TextInput/TextInput';
import { searchLocation, createLocation } from '../../api/LocationAPI';
import { Location } from '../../types/Location';
import { LocationResponse } from '../../types/responses/job-response';
import { GoogleMapLocation, NewLocation } from '../../types/GoogleAddress';

interface GridProps {
  selectedoption: LocationOption;
}

const MainGrid = styled(Grid)`
    .search-input-wrapper {
      width: 100% !important;
      margin: 6px 0 12px !important;
      padding: 0 !important;
      background-color: #fafbfd;
      border: 1px solid #c2cdea;

      & input {
        width: 100% !important;
        padding: 9px 8px !important;
        background-color: #fafbfd !important;
        border: 0 !important;
        color: #16244A;
        font-size: 16px;
      }
      & svg{
        align-self: center !important;
        margin: 0 0 0 9px !important;
      }
  }
`;

const MapGrid = styled(Grid) <GridProps>`
  position: relative;
  transition: opacity 300ms ease;
  opacity: ${({ selectedoption }) =>
    selectedoption !== 'newLocation' ? 0.30 : 1 };
`;

const LocationForm = styled(Grid)`
  width: 100%;
  margin-top: 12px !important;

  .MuiInputBase-input {
    padding: 10px 15px !important;
    background-color: #FAFBFD !important;
    color: #16244A !important;
  }

  .MuiGrid-item {
    padding: 3px 11px !important;
  }

  .MuiInputBase-multiline {
    background-color: #fafbfd !important;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 36px;
  background-color: #fdf2ca;
  color: #16244A;
  border: 0;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 700;
  font-family: Nunito;
  cursor: pointer;
  outline: 0 !important;
`;

const LocationList = styled(List)`
  margin-top: 10px !important;
  font-family: Nunito, sans-serif;
  width: 100% !important;
  overflow: auto !important;
  height: 430px !important;
  border: 1px solid #c2cdea;
  border-radius: 3px !important;
  padding: 0 !important;

  &::-webkit-scrollbar {
      width: 8px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f3f5fb;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(145, 165, 218, 0.8);
  }
`;

const LocationListItem = styled.li`
  padding: 12px 24px !important;
  cursor: pointer;

  &.selected {
    background-color: #fef9e7 !important;

    &>div {
      color: #16244a !important;
    }
  }

  &:not(:first-child) {
    border-top: 1px solid #c2cdea !important;
  }
`;

const LocationName = styled.div`
  font-weight: 600;
  color: #16244a;
  font-size: 16px;
`;

const LocationAddress = styled.div`
  font-weight: 300;
  color: #91a5da;
  font-size: 14px;
`;

const MapOverlay = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: transparent;
    z-index: 9999;
    cursor: no-drop;
`;

const ListOverlay = styled.div`
  position: absolute;
  top: 10px;
  bottom: 0;
  right: 0;
  left: 0;
  background: transparent;
  z-index: 9999;
  cursor: no-drop;
`;

const Label = styled(InputLabel)`
  font-family: Nunito !important;
  font-weight: 300 !important;
  color: #16244A !important;
  margin: 4px 6px !important;
`;

const CheckboxGrid = styled(Grid)`
  align-items: center;
  display: flex;
  margin-bottom: 6px !important;
`;

const CheckboxText = styled.span`
  color: #16244A;
  font-weight: 600;
  font-size: 20px;
  font-family: Nunito, sans-serif;
`;

interface ControlLabelProps {
  selected: boolean;
}

const ControlLabel = styled(FormControlLabel)<ControlLabelProps>`
  width: 100% !important;
  background-color: ${({ selected }) =>
    selected ? '#e8ecf9' : '#f6f7fc'} !important;
  margin: 0 !important;
  border-radius: 3px;
  padding-left: 6px;
`;

const LocationListGrid = styled(Grid) <GridProps>`
  position: relative;
  transition: opacity 300ms ease;
  opacity: ${({ selectedoption }) =>
    selectedoption !== 'preSaved' ? 0.37 : 1 };
`;

// validations object
const validations = Yup.object({
  locationName: Yup.string().required('Required'),
  startTime: Yup.string().required('Required'),
  endTime: Yup.string().required('Required')
});

interface FormValues {
  locationName: string;
  startTime: string;
  endTime: string;
}

type LocationOption = 'newLocation' | 'preSaved';

interface LocationSelectorProps {
  selected?: number;
  onLocationChange: (locationId: number) => void;
  onCoordinatesChange?: (coordinates: { lat: number; lng: number }) => void;
}

const LocationSelector = ({
  selected,
  onLocationChange
}: LocationSelectorProps): JSX.Element => {

  // formik reference
  const formikRef = useRef<FormikProps<FormValues>>(null);

  // handle ui notifications
  const { setNotification } = useNotification();

  const [isSearching, setIsSearching] = useState(true);

  // state for selected radio button
  const [selectedOption, setSelectedOption] = useState<LocationOption>('newLocation');

  // locations state
  const [locations, setLocations] = useState<Location[]>([]);

  // search input value for frequent locations
  const [searchInput, setSearchInput] = useState('');

  // selected location item state
  const [selectedLocation, setSelectedLocation] = useState<number>(0);

  // job site marker coordinates
  const [locationCoordinates, setLocationCoordinates] = useState({
    lat: 49.2827291,
    lng: -123.1207375,
  });

  // job site google address location
  const [currentLocation, setCurrentLocation] = useState<GoogleMapLocation>({
    address: '',
    components: {},
  });

  // handle form submission for not valid formik inputs
  const [submitted, setSubmitted] = useState(false);

  const [formInitValues] = useState<FormValues>({
    locationName: '',
    startTime: '',
    endTime: '',
  });

  // handle on location list item click
  const handleLocationClick = (id?: number) => {
    if (!id) return;

    setSelectedLocation(id);
    onLocationChange(id);
  }

  // handle search input change event
  const handleSearchInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => setSearchInput(event.target.value);

  // handle enter key press to search
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setIsSearching(true);
    }
  };

  // get locations
  const getLocations = useCallback(() => {
    if (isSearching) {
      searchLocation(searchInput)
        .then(({ data }) => {
          if (data.success) {
            const locations = data.locations.map((location: LocationResponse) => new Location(location));
            setLocations(locations);
          }
        })
        .catch(() => {
          setLocations([]);
        })
        .finally(() => setIsSearching(false));
    }
  }, [isSearching, searchInput]);

  useEffect(() => {
    getLocations();
  }, [getLocations]);

  useEffect(() => {
    if (selected) {
      setSelectedLocation(selected);
      setSelectedOption('preSaved');
    }
  }, [selected]);

  const handleLocation = (coordinates: { lat: number, lng: number }) => {
    setLocationCoordinates(coordinates);
  }

  const handleSubmit = (
    values: FormValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void }
  ): void => {
    setSubmitted(true);

    if (!currentLocation.address) {
      setSubmitting(false);
      return;
    }

    const newLocation: NewLocation = {
      address: currentLocation.address,
      city: currentLocation.components.city,
      country: currentLocation.components.country,
      haulingHour: {
        startTime: values.startTime,
        endTime: values.endTime,
      },
      latlng: `${locationCoordinates.lat}, ${locationCoordinates.lng}`,
      name: values.locationName,
      state: currentLocation.components.state,
      stateAbbr: currentLocation.components.stateAbbr,
      zipCode: currentLocation.components.zipCode,
    };

    createLocation(newLocation)
      .then(({ data }) => {
        if (data.success) {
          // add new location added to the list
          setLocations(prevLocations => [new Location(data.location), ...prevLocations]);
          handleLocationClick(data.location.id);
          setSelectedOption('preSaved');

          // reset current location data
          setCurrentLocation({
            address: '',
            components: {},
          });

          // reset form values
          formikRef.current?.resetForm();

          setNotification({
            type: 'success',
            message: data.msg,
            open: true,
          });
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => {
        setSubmitting(false);
        setSubmitted(false);
      });
  }

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    setSelectedOption(value as LocationOption);
  }

  const RadioButton = (props: RadioProps) => (
    <Radio
      style={{ color: '#728CD1' }}
      icon={<RadioButtonUncheckedOutlined />}
      checkedIcon={<RadioButtonCheckedOutlined />}
      {...props}
    />
  );

  return (
    <RadioGroup value={selectedOption} onChange={handleRadioChange}>
      <MainGrid container spacing={6}>
        <Grid item xs={12} lg={6}>
          <CheckboxGrid item xs={12}>
            <ControlLabel
              selected={selectedOption === 'newLocation'}
              value="newLocation"
              control={<RadioButton />}
              label={<CheckboxText>Search for new location</CheckboxText>}
            />
          </CheckboxGrid>
          <MapGrid container selectedoption={selectedOption}>
            {selectedOption === 'preSaved' && <MapOverlay />}
            <Grid item xs={12}>
              <GoogleMap
                initialLocation={locationCoordinates}
                handleLocation={handleLocation}
                searchValue={currentLocation}
                handleSearchValue={setCurrentLocation}
                isSubmitted={submitted}
              />
            </Grid>
            <Grid item xs={12}>
              <LocationForm container spacing={3}>
                <Formik
                  initialValues={formInitValues}
                  validationSchema={validations}
                  enableReinitialize
                  onSubmit={handleSubmit}
                  innerRef={formikRef}
                >
                  {({ isSubmitting, handleSubmit }) => (
                    <>
                      <Grid item xs={12}>
                        <Label>Name</Label>
                        <TextInput fullWidth={true} name="locationName" type="text"/>
                      </Grid>

                      <Grid item xs={12} lg={4}>
                        <Label>
                          Hauling hours <b>(Open)</b>
                        </Label>
                        <TextInput fullWidth={true} name="startTime" type="time" />
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        <Label>
                          Hauling hours <b>(Close)</b>
                        </Label>
                        <TextInput fullWidth={true} name="endTime" type="time" />
                      </Grid>
                      <Grid item xs={12} lg={4} style={{ alignSelf: 'flex-end' }}>
                        {!isSubmitting ? (
                          <Button disabled={isSubmitting} onClick={handleSubmit} type="button">
                            Save location
                          </Button>
                        ) : (
                          <>
                            <CircularProgress size={32} disableShrink />
                          </>
                        )}
                      </Grid>
                    </>
                  )}
                </Formik>
              </LocationForm>
            </Grid>
          </MapGrid>
        </Grid>
        <Grid item xs={12} lg={6}>
          <CheckboxGrid item xs={12}>
            <ControlLabel
              selected={selectedOption === 'preSaved'}
              value="preSaved"
              control={<RadioButton />}
              label={<CheckboxText>Pre-saved locations</CheckboxText>}
            />
          </CheckboxGrid>
          <LocationListGrid container selectedoption={selectedOption}>
            {selectedOption === 'newLocation' && <ListOverlay />}
            <Grid item xs={12}>
              <SearchInput
                maxWidth="100%"
                placeholder={'Search for your frequent locations..'}
                value={searchInput}
                onChange={handleSearchInputChange}
                onKeyPress={handleSearch}
              />
            </Grid>
            <Grid item xs={12}>
              <LocationList>
                {locations.length ? (
                  locations.map(location => (
                    <LocationListItem
                      key={location.id}
                      onClick={() => handleLocationClick(location.id)}
                      className={selectedLocation === location.id ? 'selected' : ''}
                    >
                      <LocationName>{location.name}</LocationName>
                      <LocationAddress>{location.fullAddress}</LocationAddress>
                    </LocationListItem>
                  ))
                ) : (
                  <>
                    {!isSearching && (
                      <LocationListItem key="no-locations-found">
                        <LocationName>No locations were found</LocationName>
                      </LocationListItem>
                    )}
                  </>
                )}
              </LocationList>
            </Grid>
          </LocationListGrid>
        </Grid>
      </MainGrid>
    </RadioGroup>
  );
}

export default LocationSelector
