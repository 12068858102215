import React, { FC } from 'react';

// Libraries
import { RowRecord, TableColumn } from 'react-data-table-component';
import { useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useHistory } from 'react-router-dom';

// Components
import Layout from '../../shared/components/Layout/Layout';
import Dropdown from '../../components/Dropdown/Dropdown';
import Table from '../../components/Table/Table';
import DataPresentation from '../../components/DataPresentation/DataPresentation';
import TextField from '../../components/TextField/TextField';
import TimePicker from '../../components/TimePicker/TimePicker';

// Cell's Related Components
import DataWithSlash from '../../components/DataWithSlash/DataWithSlash';
import RegularStyleCell from '../../components/RegularStyleCell/RegularStyleCell';
import LoadStatusLink from '../../components/LoadStatusLink/LoadStatusLink';
import LoadStatusBadge from '../../components/LoadStatusBadge/LoadStatusBadge';

// Hooks
import useGetLoads from '../../hooks/useGetLoads';
import useJobDetail from '../../hooks/useJobDetail';

// Others
import {
  formatCurrency,
  formatDate,
  formatDateWithSlash,
} from '../../helpers/formats';
import { JobStatusDisplayColor, StatusId } from '../../types/Status';
import { Load } from '../../types/Load';
import useLoadStatusList from '../../hooks/useLoadStatusList';
import { jobDetailValidations } from '../../helpers/validations';

// Styles
import {
  Card,
  FlexRow,
  Title,
  Header,
  TableContainer,
  Separator,
  SaveButton,
  EditButton,
  ActivationButton,
  EmptyColumn,
  DisplayStatus,
  ButtonsContainer,
} from './job-detail.styles';
import useUpdate from '../../hooks/useUpdate';
import { useModal } from '../../contexts/modal-context';
import UpdateMyAccountModal from '../../components/ConfirmationModal/UpdateJobStatusModal';
import { HttpUpdateJobStatus } from '../../api/JobsAPI';

// Local interfaces/types
interface urlParams {
  id: string;
}

const JobDetailPage: FC = () => {
  // Get access to the route history
  const history = useHistory();
  // Access to the url params
  const { id: jobId }: urlParams = useParams();
  // Open the confirmation modal
  const { requireModal } = useModal();
  // Get the list of available load status from the server
  const { list } = useLoadStatusList();
  // Get the list of loads and manage its mutations
  const loadsList = useGetLoads(jobId, list);
  // Get and update the job detail data
  const detail = useJobDetail(jobId);
  // Handle the update of the status
  const updateStatus = useUpdate();

  // Activate/Deactivate the job status
  const handleStatusUpdate = () => {
    if (Boolean(detail.jobStatus)) {
      const newStatus = detail.jobStatus !== 'Active' ? 'Active' : 'Inactive';
      const newStatusId = StatusId[newStatus];
      const action = detail.jobStatus !== 'Active' ? 'activate' : 'deactivate';
      requireModal(
        <UpdateMyAccountModal
          action={action}
          submit={() =>
            updateStatus.update({
              callback: () => HttpUpdateJobStatus(jobId, newStatusId),
              onSuccess: () => detail.setJobStatus(newStatus),
            })
          }
        />
      );
    }
  };

  // navigate to the load detail
  const handleRowClicked = ({ id }: RowRecord): void => {
    history.push(`/load-details/${id}`);
  };

  // data table columns
  const columns: TableColumn<Load>[] = [
    {
      name: '#Load',
      selector: (currentLoad: Load) => currentLoad.id,
      sortable: true,
      maxWidth: '100px',
      center: true,
    },
    {
      name: 'Trucker / Truck Type',
      selector: (currentLoad: Load) =>
        `${currentLoad.trucker.name} / ${currentLoad.getTruckTypesAsString()}`,
      cell: function renderDataWithTruckSlash(currentLoad: Load) {
        const trucks = currentLoad.getTruckTypesAsString();
        const trucker = currentLoad.trucker.name;
        return <DataWithSlash left={trucker} right={trucks} />;
      },
      sortable: true,
      minWidth: '140px',
      allowOverflow: false,
    },
    {
      name: 'Date',
      selector: (currentLoad: Load) => formatDate(currentLoad.createdAt),
      cell: function renderRegularStyleCell(currentLoad: Load) {
        const formattedDate = formatDateWithSlash(currentLoad.createdAt);
        return <RegularStyleCell value={formattedDate} />;
      },
      sortable: true,
      width: '120px',
      center: true,
    },
    {
      name: 'Start Hr',
      selector: (currentLoad: Load) => currentLoad,
      cell: function renderRegularStyleCell(currentLoad: Load) {
        return <RegularStyleCell value={currentLoad.startDate} />;
      },
      sortable: true,
      width: '110px',
      center: true,
    },
    {
      name: 'End Hr',
      selector: (currentLoad: Load) => currentLoad,
      cell: function renderRegularStyleCell(currentLoad: Load) {
        return <RegularStyleCell value={currentLoad.endDate} />;
      },
      sortable: true,
      width: '110px',
      center: true,
    },
    {
      name: 'Duration',
      selector: (currentLoad: Load) => `${currentLoad.totalHours} HRS`,
      sortable: true,
      width: '120px',
      center: true,
    },
    {
      name: 'Distance',
      selector: () => `${detail.staticData.distance} MI`,
      sortable: true,
      maxWidth: '130px',
      center: true,
    },
    {
      name: 'Total / Rate',
      selector: (currentLoad: Load) =>
        `${currentLoad.rate} / ${currentLoad.totalEstimated} hourly`,

      cell: function renderDataWithTruckSlash(currentLoad: Load) {
        return (
          <DataWithSlash
            left={`${formatCurrency(currentLoad.rate)}`}
            right={`${formatCurrency(currentLoad.totalEstimated)} Hourly`}
          />
        );
      },
      sortable: true,
      maxWidth: '200px',
      center: true,
    },
    {
      name: 'Status',
      selector: (currentLoad: Load) => currentLoad.status,
      cell: function GetBadge({ status }: Load) {
        return <LoadStatusBadge value={status} />;
      },
      sortable: true,
      center: true,
      width: '110px',
    },
    {
      name: '',
      sortable: false,
      cell: function GetJobStatusLink({ status }: Load) {
        return <LoadStatusLink status={status} />;
      },
      width: '40px',
    },
  ];

  return (
    <Layout>
      <Card>
        <Formik
          initialValues={detail.initialValues}
          validationSchema={jobDetailValidations.detail}
          enableReinitialize
          onSubmit={detail.updateJobDetail}
        >
          <Form>
            <Header>
              <FlexRow>
                <Title>
                  #{jobId} {detail.staticData.name}{' '}
                  <DisplayStatus
                    currentStatus={
                      detail.jobStatus as keyof typeof JobStatusDisplayColor
                    }
                  >
                    {detail.jobStatus}
                  </DisplayStatus>
                </Title>
                <EmptyColumn />
                <ButtonsContainer>
                  <ActivationButton
                    status={detail.jobStatus}
                    type="button"
                    hide={detail.jobStatus === 'Completed'}
                    disabled={detail.loading || updateStatus.loading}
                    onClick={handleStatusUpdate}
                  >
                    {detail.jobStatus === 'Active'
                      ? 'Deactivate Job'
                      : detail.jobStatus === 'Inactive'
                      ? 'Activate Job'
                      : 'Complete Job'}
                  </ActivationButton>
                  {detail.isEditionActive ? (
                    <SaveButton
                      type="submit"
                      hide={detail.jobStatus === 'Completed'}
                      disabled={detail.loading || updateStatus.loading}
                    >
                      Save changes
                    </SaveButton>
                  ) : (
                    <EditButton
                      type="button"
                      hide={detail.jobStatus === 'Completed'}
                      disabled={detail.loading || updateStatus.loading}
                      onClick={detail.handleEditButton}
                    >
                      Edit
                    </EditButton>
                  )}
                </ButtonsContainer>
              </FlexRow>

              <FlexRow>
                <DataPresentation
                  name="Estimated Cost"
                  value={detail.staticData.estimatedCost}
                />
                <DataPresentation
                  name="Total Hours"
                  value={detail.staticData.totalHours}
                />
                <TextField
                  name="rate"
                  label="Job Rate"
                  disabled={
                    detail.jobStatus === 'Completed' || !detail.isEditionActive
                  }
                />
              </FlexRow>

              <FlexRow>
                <TextField
                  name="loads"
                  label="Loads"
                  disabled={
                    detail.jobStatus === 'Completed' || !detail.isEditionActive
                  }
                />
                <TextField
                  name="loadsNotification"
                  label="Load Notification"
                  disabled={
                    detail.jobStatus === 'Completed' || !detail.isEditionActive
                  }
                />
                <TextField
                  name="material"
                  label="Material"
                  disabled={
                    detail.jobStatus === 'Completed' || !detail.isEditionActive
                  }
                />
              </FlexRow>

              <FlexRow>
                <TextField
                  name="remarks"
                  label="Notes"
                  disabled={
                    detail.jobStatus === 'Completed' || !detail.isEditionActive
                  }
                />
              </FlexRow>
            </Header>
          </Form>
        </Formik>

        <TableContainer>
          <FlexRow>
            <DataPresentation
              variant="green"
              name="Loads Progress"
              value={detail.staticData.loadsProgress}
            />
            <TimePicker
              startHr={loadsList.since}
              endHr={loadsList.until}
              handleStartDateChange={loadsList.handleStartDateChange}
              handleEndDateChange={loadsList.handleEndDateChange}
              startLabel="Start Date"
              endLabel="End Date"
            />
            <Dropdown
              value={loadsList.queryParams.status}
              handleChange={loadsList.handleSelectChange}
              options={list}
              maxWidth="100%"
              customPadding="10px 32px 10px 8px"
            />
          </FlexRow>
          <Separator />
          <Table
            columns={columns}
            data={loadsList.loads}
            totalRows={loadsList.totalLoads}
            paginationLimit={loadsList.queryParams.limit}
            setQueryParams={loadsList.setQueryParams}
            handleRowClicked={handleRowClicked}
            isLoading={loadsList.loading}
            tableName="load"
          />
        </TableContainer>
      </Card>
    </Layout>
  );
};

export default JobDetailPage;
