import { AxiosResponse } from 'axios';
import API from './API';
import { endpoints } from './Constants';
import { MyAccountData } from '../types/Account';

// url = gc/profile/my-account/
const BASE_URL = endpoints.account.base;

export interface MyAccountResponse {
  success: boolean;
  user: {
    profile: MyAccountData;
  };
}

export interface updatePasswordInterface {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

// get the user profile data
export const getMyAccount = (): Promise<AxiosResponse<MyAccountResponse>> => {
  return API.get(`${BASE_URL}${endpoints.account.myAccount}`);
};

// update the user profile data
export const updateMyAccount = (
  data: MyAccountData
): Promise<AxiosResponse> => {
  return API.put(`${BASE_URL}${endpoints.account.myAccount}`, {
    profile: { ...data },
  });
};

// update the user password
export const updatePassword = ({
  currentPassword,
  password,
  confirmPassword,
}: updatePasswordInterface): Promise<AxiosResponse> => {
  return API.put(`${BASE_URL}${endpoints.account.password}`, {
    currentPassword,
    password,
    confirmPassword,
  });
};
