import { Drawer, List, ListItem } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../contexts/auth-context';
import { Roles } from '../../../types/Roles';

const Menu = styled(Drawer)`
  .MuiDrawer-paper {
    top: 64px;
    width: 50vw;
    background: #1e3363;
  }
`;

const Option = styled(NavLink)`
  color: white;
  font-size: 16px;
  font-family: Nunito, sans-serif;
  font-weight: lighter;
  text-decoration: none;
  padding: 5px 11px;

  &.active {
    background: #16244a;
    width: 100%;
    border-radius: 3px;

    font-weight: bold;
  }
`;

const LogoutOption = styled.div`
  color: white;
  font-size: 16px;
  font-family: Nunito, sans-serif;
  font-weight: lighter;
  text-decoration: none;
  padding: 5px 11px;
`;

interface MobileMenuProps {
  isOpen: boolean;
  handleMenu: () => void;
  handleLogout: () => void;
}

function MobileMenu({
  isOpen,
  handleMenu,
  handleLogout
}: MobileMenuProps): JSX.Element {
  const { user } = useAuth();
  const isTrucker = user?.roles?.includes(Roles.TRUCKER);
  const isSuperAdmin = user?.roles?.includes(Roles.SUPER_ADMIN);

  return (
    <Menu anchor='left' open={isOpen} onClose={handleMenu}>
      <List>
        <ListItem button key={Math.random()}>
          <Option to='/my-account' activeClassName='active'>
            My Account
          </Option>
        </ListItem>
        <ListItem button>
          <Option to='/job-board' activeClassName='active'>
            Job Board
          </Option>
        </ListItem>
        {isSuperAdmin && (
          <ListItem button>
            <Option to='/administrator/users' activeClassName='active'>
              Users
            </Option>
          </ListItem>
        )}
        {!isTrucker && !isSuperAdmin && (
          <>
            <ListItem button key={Math.random()}>
              <Option to='/my-jobs' activeClassName='active'>
                My Jobs
              </Option>
            </ListItem>
            <ListItem button key={Math.random()}>
              <Option to='/my-tickets' activeClassName='active'>
                Tickets
              </Option>
            </ListItem>
          </>
        )}
        <ListItem button key={Math.random()}>
          <LogoutOption onClick={handleLogout}>Sign out</LogoutOption>
        </ListItem>
      </List>
    </Menu>
  );
}

export default MobileMenu;
