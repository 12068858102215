import GeneralContractorIcon from '../components/Icons/GeneralContractorIcon';
import IndividualProfileIcon from '../components/Icons/IndividualProfileIcon';
import TruckerProfileIcon from '../components/Icons/TruckerProfileIcon';
import { ProfileTypes } from '../types/ProfileTypes';

export const distanceOptions = [
  {
    label: 'Anywhere',
    value: '0'
  },
  {
    label: 'Local (0-50 Miles Roundtrip)',
    value: '0,50'
  },
  {
    label: 'Short (50-100 Miles) Roundtrip)',
    value: '50,100'
  },
  {
    label: 'Long (100+ Miles Roundtrip)',
    value: '100'
  }
];

export const ProfilesInformation = [
  {
    type: ProfileTypes.GENERAL_CONTRACTOR,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna lectus magna fringilla urna.',
    icon: GeneralContractorIcon
  },
  {
    type: ProfileTypes.TRUCKER,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna lectus magna fringilla urna.',
    icon: TruckerProfileIcon
  },
  {
    type: ProfileTypes.INDIVIDUAL,
    description:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna lectus magna fringilla urna.',
    icon: IndividualProfileIcon
  }
];

export const navbarUrlVariants = {
  jobBoard: ['/job-board', '/administrator/job-board'],
  myAccount: ['/my-account', '/administrator/my-account']
};
