import { AvailableStatus } from './Status';
import { Location } from './Location';
import { JobResponse } from './responses/job-response';
import { JobTypes } from './JobTypes';

export class Job {
  id: number;
  projectId: string;
  name: string;
  type: JobTypes;
  typeId?: number;
  rate: string;
  status: AvailableStatus;
  loads: number;
  loadsRemaining: number;
  loadsNotification: number;
  pickupLocation: Location;
  dropoffLocation: Location;
  jobSiteLocation: Location | number;
  jobLocation: Location | number;
  distance: string;
  truckType: string[];
  startDate: string;
  remarks: string;
  material: string;
  weight: string;

  constructor(data: JobResponse) {
    this.id = data.id;
    this.name = data.name;
    this.distance = Math.floor(data.distance).toLocaleString('en-US');
    this.weight = data.weight;
    this.startDate = data.startDate;
    this.rate = data.rate;
    this.remarks = data.remarks;
    this.pickupLocation = new Location(data.pickupLocation);
    this.dropoffLocation = new Location(data.dropoffLocation);
    this.jobSiteLocation = new Location(data.jobSiteLocation);
    this.jobLocation = new Location(data.jobLocation);
    this.truckType = data.truckTypes;
    this.material = data.material;
    this.status = data.status;
    this.loads = data.loads;
    this.loadsRemaining = data.loadsRemaining;
    this.type = data.type;
    this.typeId = data.typeId;
    this.projectId = data.projectId;
    this.loadsNotification = data.loadsNotification;
  }
}

export interface UpdatedJob {
  rate: number;
  loads: number;
  loadsNotification: number;
  material: string;
  remarks: string;
}
