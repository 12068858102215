import styled from 'styled-components';

export const MainContainer = styled.div`
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 1px;
  margin: 20px 0;
`;

export const Card = styled.div`
  background: #ffffff;
  padding: 20px 35px;

  &.table {
    padding: 0 35px 20px;
  }
`;

export const Title = styled.p`
  font-family: Cabin, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #16244A;
  text-transform: capitalize;
  margin: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
  @media (max-width: 1050px) {
    flex-direction: column;
    justify-content: space-around;
    gap: 0;
  }
`;
export const FlexRow = styled(Row)`
  & > div, p {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
  }
`;
export const SelectTicketsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #F6F7FC;
  padding: 10px 35px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
export const ButtonsContainer = styled.div`
  display: flex;
  gap: 6px;
  @media (max-width: 500px) {
    flex-direction: column;
    margin-top: 10px;
  }
`;

export const TicketSelectionContainer = styled.div`
  display: flex;
  align-items: flex-end;
  font-family: Nunito, sans-serif;
  font-size: 10px;
  font-weight: 700;
  color: #16244A;
  text-transform: uppercase;
  @media (max-width: 500px) {
    width: 100%;
    justify-content: center;
  }
`;
export const NumberOfTicketsTag = styled.div`
  margin-top: 0;
  margin-bottom: 0;
  margin-right: 10px;
  font-family: Cabin, sans-serif;
  font-size: 14px;
`;
export const Button = styled.button`
  background: #6CBD2C;
  border-radius: 3px;
  color: #FFF;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 10px;
  padding: 5px 0;
  border: none;
  text-align: center;
  width: 95px;
  transition: background 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #579626;
  }

  &.secondary {
    background: #ff537c;

    &:hover {
      background: #F24F76;
    }
  }
  
  &:disabled{
    background-color: #E8ECF9;
    color: #16244A;
    &:hover{
      background-color: #E8ECF9;
    }
  }

  @media (max-width: 500px) {
    width: 100%;
  }
`;