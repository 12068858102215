import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useAuth } from '../../../contexts/auth-context';
import { Roles } from '../../../types/Roles';
import { navbarUrlVariants } from '../../../utils/constants';

const Container = styled.div`
  height: 38px;
  background-color: #1e3363;
  padding: 4px 20px;
  display: none;
  box-sizing: border-box;

  /*  Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 4px 40px;
  }

  /*  Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 4px 180px;
    display: flex;
    align-items: center;
  }

  /*  X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    padding: 4px 234px;
  }
`;

const Button = styled(NavLink)`
  padding: 6px 11px;
  font-family: Nunito, sans-serif;
  font-size: 14px;
  line-height: 19px;
  color: #91a5da;
  text-decoration: none;
  margin-right: 30px;

  &.active {
    color: white;
    font-weight: bold;
    background-color: #16244a;
    border-radius: 3px;
    border: none;
  }

  &:hover {
    cursor: pointer;
  }
`;

function Navbar(): JSX.Element {
  const { user } = useAuth();
  const isTrucker = user?.roles?.includes(Roles.TRUCKER);
  const isSuperAdmin = user?.roles?.includes(Roles.SUPER_ADMIN);

  return (
    <Container>
      <Button
        to='/job-board'
        activeClassName='active'
        isActive={(_, location) => {
          return navbarUrlVariants.jobBoard.some(
            (path) => path === location.pathname
          );
        }}
      >
        Job Board
      </Button>

      {isSuperAdmin && (
        <Button to='/administrator/users' activeClassName='active'>
          Users
        </Button>
      )}

      {!isTrucker && !isSuperAdmin && (
        <>
          <Button to='/my-jobs' activeClassName='active'>
            My Jobs
          </Button>
          <Button to='/my-tickets' activeClassName='active'>
            Tickets
          </Button>
        </>
      )}
    </Container>
  );
}

export default Navbar;
