import styled from 'styled-components';
import { Location } from '../../types/Location';
import ArrowBottomIcon from '../Icons/ArrowBottomIcon';
import ArrowUpIcon from '../Icons/ArrowUpIcon';

const Container = styled.article`
  display: flex;
  flex-direction: column;
  font-family: Nunito;
  font-size: 14px;
  flex: 1;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: ${({ isPickUp }: { isPickUp: boolean }) =>
    isPickUp ? 'flex-start' : 'flex-end'};
  gap: 10px;
  height: 40px;
  background-color: ${({ isPickUp }: { isPickUp: boolean }) =>
    isPickUp ? '#F7D55E' : '#B0BEE4'};
  padding: 0 15px;
  font-weight: 700;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  padding: 12px;
  flex: 1;
  background-color: ${({ isPickUp }: { isPickUp: boolean }) =>
    isPickUp ? '#F7D55E24' : '#C2CDEA52'};
`;

const Hours = styled.footer`
  display: flex;
  justify-content: space-between;
  gap: 5px;
`;

const LightText = styled.span`
  color: #405daa;
`;

const DarkText = styled.span`
  color: #1c3264;
`;

const FullAddress = styled.span`
  color: #1c3264;
  font-weight: 600;
`;

interface LocationCardProps {
  location: Location;
  isPickUp: boolean;
}

const JobLocationCard: React.FC<LocationCardProps> = ({
  location,
  isPickUp
}) => (
  <Container>
    <Header isPickUp={isPickUp}>
      {isPickUp ? <ArrowUpIcon size={10} /> : <ArrowBottomIcon size={10} />}
      <DarkText>{isPickUp ? 'Pick up' : 'Drop off'}</DarkText>
    </Header>

    <Content isPickUp={isPickUp}>
      <FullAddress>{`${location.address} ${location.zipCode}`}</FullAddress>

      <Hours>
        <LightText>Hauling hours</LightText>
        <DarkText>{location.haulingHours}</DarkText>
      </Hours>
    </Content>
  </Container>
);

export default JobLocationCard;
