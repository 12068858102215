import { ButtonContainer, ConfirmButton, DenyButton, Separator, Title } from './Modal.styles';
import React from 'react';
import { useModal } from '../../contexts/modal-context';
import ConfirmationModalWrapper from './ConfirmationModalWrapper';

interface ModalProps {
  submit: () => void;
  status: string;
}

function MarkTicketAsPaidModal({submit, status}: ModalProps): JSX.Element {
  const { close } = useModal();
  return (
    <ConfirmationModalWrapper>
      <Title>Are you sure you want set the selected tickets as {status}?</Title>
      <Separator />
      <ButtonContainer>
        <ConfirmButton type="button" onClick={submit}>
          Yes
        </ConfirmButton>
        <DenyButton type="button" onClick={close}>
          No
        </DenyButton>
      </ButtonContainer>
    </ConfirmationModalWrapper>

  );
}

export default  MarkTicketAsPaidModal;