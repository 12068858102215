import styled from 'styled-components';
import { JobStatusDisplayColor } from '../../types/Status';

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 1px;
  margin: 20px 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  white-space: nowrap;

  /*  Small devices (landscape phones, 665px and up) */
  @media (max-width: 665px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const FlexRow = styled(Row)`
  gap: 12px;

  & > div,
  p {
    width: 100%;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 25%;
  }
`;

export const Title = styled.p`
  font-family: Cabin, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #16244a;
  margin: 0;
  white-space: break-spaces;
`;

export const Header = styled.div`
  padding: 20px 37px 16px 22px;
  background-color: #f6f7fc;
`;

export const TableContainer = styled.div`
  padding: 13px 37px 13px 22px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  gap: 10px;
  align-self: flex-start;
  // small devices
  @media (max-width: 400px) {
    flex-direction: column;
  }
`;

export const Separator = styled.div`
  margin-top: 10px;
`;

interface ButtonProps {
  hide?: boolean;
  status?: string;
}

export const SaveButton = styled.button`
  display: ${({ hide }: ButtonProps) => hide && 'none'};
  background: #f7d55e;
  border-radius: 3px;
  padding: 6px 21px !important;
  border: none;
  text-transform: uppercase;
  color: #16244a;
  font-family: Nunito, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.06em;
  transition: 0.25s;
  width: 100%;

  &:disabled {
    background-color: #cccccc;
    color: #666666;

    &:hover {
      cursor: default;
      box-shadow: none;
      background-color: #cccccc;
    }
  }

  &:hover {
    cursor: pointer;
    background: #e2b824cc;
  }
`;

export const EditButton = styled(SaveButton)``;

export const ActivationButton = styled(SaveButton)`
  background: #e8ecf9;
  color: ${({ status }: ButtonProps) =>
    status === 'Active' ? '#FF537C' : '#82C01D'};

  &:hover {
    background: #c5cef0;
  }
`;

export const EmptyColumn = styled.div``;

interface DisplayStatusProps {
  currentStatus: keyof typeof JobStatusDisplayColor;
}

export const DisplayStatus = styled.span`
  color: ${({ currentStatus }: DisplayStatusProps) =>
    JobStatusDisplayColor[currentStatus]};
  text-transform: uppercase;
`;