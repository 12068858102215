import React from 'react';
import styled from "styled-components";

export const BackdropStyled = styled.div`
  z-index: -1;
  position: fixed;
  transform: translate(0, 102px);
  inset: 0;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-tap-highlight-color: transparent;

  /*  Large devices (desktops, 992px and up) */
  @media (max-width: 992px) {
    transform: translate(0, 64px);
  }
`;

const Backdrop = (): JSX.Element => {
  return <BackdropStyled />;
};

export default Backdrop;
