import { AvailableLoadStatus } from '../../types/Status';
import { ArrowForwardIos } from '@material-ui/icons';
import styled from 'styled-components';

const ArrowRightIcon = styled(ArrowForwardIos)`
  color: #91a5da;
  font-size: 18px !important;
`;

interface LoadStatusLinkProps {
  status: AvailableLoadStatus;
}

const LoadStatusLinkProps = ({ status }: LoadStatusLinkProps): JSX.Element => {
  const JobStatusIcon = (): JSX.Element => {
    switch (status) {
      default:
        return <ArrowRightIcon titleAccess="View details" />;
    }
  };
  return <JobStatusIcon />;
};

export default LoadStatusLinkProps;