import React, { useState } from 'react';

// Context
import { useModal } from '../../contexts/modal-context';
// API
import { cancelLoad } from '../../api/LoadsAPI';
//Components
import SpinnerContainer from '../SpinnerContainer/SpinnerContainer';
// styles
import {
  Title,
  Subtitle,
  ModalInput,
  ButtonContainer,
  ConfirmButton,
  DenyButton,
  ErrorMsg,
} from './Modal.styles';
import { useNotification } from '../../contexts/notification-context';
import ConfirmationModalWrapper from './ConfirmationModalWrapper';

interface Props {
  id: string;
  onSuccess: () => void;
}

const CancelLoadModal = ({ id, onSuccess }: Props): JSX.Element | null => {
  const { close } = useModal();

  // notifications handler
  const { setNotification } = useNotification();

  // message required by the api
  const [motive, setMotive] = useState('');

  // checks if there's an error in the cancel form
  const [hasError, setHasError] = useState(false);

  // state of the request
  const [loading, setLoading] = useState(false);

  // control the changes in the input
  const handleTextboxChange = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    setMotive(event.target.value);
  };

  // handles all the resetting and closing after the request finalize
  const onFinalize = () => {
    setLoading(false);
    close();
  };

  const submit = () => {
    if (motive === '') {
      setHasError(true);
      return;
    }
    setLoading(true);
    cancelLoad(id, motive)
      .then(({ data }) => {
        if (data.success) {
          onSuccess();
          setNotification({
            type: 'success',
            message: data.msg,
            open: true,
          });
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => onFinalize());
  };

  if (loading) return <SpinnerContainer pd="30px" />;
  else
    return (
      <ConfirmationModalWrapper>
        <Title>Are you sure you want to cancel this Load?</Title>
        <Subtitle>Please tell us your motive</Subtitle>
        {hasError && <ErrorMsg>Motive of cancellation is needed</ErrorMsg>}
        <ModalInput
          rows={8}
          value={motive}
          onChange={handleTextboxChange}
          hasError={hasError}
        />
        <ButtonContainer>
          <ConfirmButton type="button" onClick={submit}>
            Yes
          </ConfirmButton>
          <DenyButton type="button" onClick={close}>
            No
          </DenyButton>
        </ButtonContainer>
      </ConfirmationModalWrapper>
    );
};

export default CancelLoadModal;
