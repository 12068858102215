import { FC, useState } from 'react';
// Context
import { useAuth } from '../../../contexts/auth-context';
// Libraries
import styled from 'styled-components';
// Material
import { AppBar, Toolbar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';

// Components
import { logo } from '../../../assets/images/index';
import MobileMenu from './MobileMenu';
import Navbar from './Navbar';
import Menu from './Menu';
import { logout } from '../../../api/AuthenticationAPI';

// Styles
const AppContainer = styled.div`
  min-height: 100svh;
  background-color: #f6f7fc;
`;

const Container = styled.div`
  flex-grow: 1;
`;

const ToolbarWrapper = styled(AppBar)`
  background-color: #010c38 !important;
  height: 64px;
  padding: 0 10px;
  justify-content: center;

  /*  Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 0 20px;
  }

  /*  Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 0 160px;
  }

  /*  X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    padding: 0 212px;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
`;

const Image = styled.img`
  height: 28px;
`;

const Content = styled.div`
  position: relative;
  padding: 4px 20px;

  /*  Small devices (landscape phones, 576px and up) */
  @media (min-width: 576px) {
    padding: 4px 40px;
  }

  /*  Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    padding: 4px 180px;
  }

  /*  X-Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    padding: 4px 234px;
  }
`;

const ToggleButton = styled(IconButton)`
  /*  Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    display: none !important;
  }
`;

interface LayoutProps {
  children: JSX.Element;
}

const Layout: FC<LayoutProps> = ({ children }) => {
  // access user name
  const { user } = useAuth();
  // logout functionality
  const { onLogout } = useAuth();

  const [openMobileMenu, setOpenMobileMenu] = useState(false);

  const firstLetter = user?.name?.charAt(0);

  // logout the user from the web app, destroy token
  const handleLogout = () => {
    logout()
      .then(({ data }) => {
        if (data.success) {
          onLogout();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <AppContainer>
      <Container>
        <ToolbarWrapper position='static'>
          <Toolbar>
            <ToggleButton
              edge='start'
              color='inherit'
              aria-label='menu'
              onClick={() => setOpenMobileMenu(!openMobileMenu)}
            >
              <MenuIcon />
            </ToggleButton>
            <Logo>
              <Image src={logo} />
            </Logo>
            <Menu
              username={user?.name}
              profileLetter={firstLetter}
              handleLogout={handleLogout}
            />
          </Toolbar>
        </ToolbarWrapper>
        <MobileMenu
          handleLogout={handleLogout}
          isOpen={openMobileMenu}
          handleMenu={() => setOpenMobileMenu(false)}
        />
        <Navbar />
      </Container>
      <Content>{children}</Content>
    </AppContainer>
  );
};

export default Layout;
