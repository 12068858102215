import { AvailableTicketStatus } from './Status';
import { formatCurrency, formatDateOnly, parseNumberToLocaleString } from '../helpers/formats';

export class TicketRow {
  date: string;
  id: number;
  loads: number;
  status: AvailableTicketStatus;
  totalAmount: string;
  driver: string;
  jobSiteName: string;
  driverId: number;

  constructor(data: ticketData) {
    this.id = data.id;
    this.driverId = data.truckerId;
    this.driver = `#${this.driverId} - ${data.truckerName.toUpperCase()}`;
    this.loads = data.loads;
    this.totalAmount = formatCurrency(data.totalAmount);
    this.jobSiteName = `#${data.jobId} - ${data.jobSite}`;
    this.date = formatDateOnly(data.date);
    this.status = data.status;


  }

}

export class TicketGeneralInfo {
  totalAmountDue: string;
  totalAmountPaid: string;
  totalHours: string;
  totalTickets: string;
  constructor(data: generalInfoInterface) {
    this.totalAmountDue = formatCurrency(data.totalAmountDue);
    this.totalAmountPaid = formatCurrency(data.totalAmountPaid);
    this.totalHours = parseNumberToLocaleString(data.totalHours);
    this.totalTickets = parseNumberToLocaleString(data.totalTickets);
  }
}

export interface TicketListResponse{
  generalInfo: generalInfoInterface,
  tickets: ticketData[],
}

export interface generalInfoInterface {
  totalAmountDue: number;
  totalAmountPaid: number;
  totalHours: number;
  totalTickets: number;
}

interface ticketData {
  date: string;
  id: number;
  jobSite: string;
  loads: number;
  status: AvailableTicketStatus;
  totalAmount: number;
  truckerName: string;
  jobId: number;
  truckerId: number;
}
