import { FC, useEffect, useState } from 'react';
import Layout from '../../shared/components/Layout/Layout';
import { getGlobalJobs } from '../../api/JobsAPI';
import SearchInput from '../../components/SearchInput/SearchInput';
import { useNotification } from '../../contexts/notification-context';
import Table from '../../components/Table/Table';
import { Card, Row, Title, Column, Button } from '../MyJobs/my-jobs-page';
import GlobalJob from '../../types/GlobalJob';
import GooglePlacesInput from '../../components/GooglePlacesInput/GooglePlacesInput';
import MultipleSelect from '../../components/MultipleSelect/MultipleSelect';
import { getTruckTypes } from '../../api/ListsAPI';
import { MenuItem } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import { distanceOptions } from '../../utils/constants';
import DateInput from '../../components/DateInput/DateInput';
import { format, subDays } from 'date-fns';
import JobDetailsPopUp from '../../components/JobDetailsPopUp/JobDetailsPopUp';
import {
  Filters,
  Select,
  TableFilters,
  initialFilters,
  JobBoardQueryParams,
  tableCols
} from './job-board-constants';

const JobBoard: FC = () => {
  const [jobs, setJobs] = useState<GlobalJob[]>([]);
  const [totalJobs, setTotalJobs] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedJob, setSelectedJob] = useState<GlobalJob | null>(null);
  const [selectedTruckType, setSelectedTruckType] = useState<
    { id: number; name: string }[]
  >([]);
  const [truckTypesList, setTruckTypesList] = useState<
    { id: number; name: string }[]
  >([]);
  const [tableFilters, setTableFilters] =
    useState<TableFilters>(initialFilters);
  const [queryParams, setQueryParams] = useState<JobBoardQueryParams>({
    page: 1,
    limit: 10,
    ...initialFilters
  });

  const { setNotification } = useNotification();

  useEffect(() => {
    const getJobs = async () => {
      setIsLoading(true);
      try {
        const { data } = await getGlobalJobs({ params: queryParams });

        if (data.success) {
          const jobs = data.jobs.map((job) => new GlobalJob(job));
          setJobs(jobs);
          setTotalJobs(data.totalJobs);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.log(error);
          setNotification({
            type: 'error',
            message: 'Something went wrong. Please try again later.',
            open: true
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    getJobs();
  }, [queryParams, setNotification]);

  useEffect(() => {
    getTruckTypes()
      .then(({ data }) => {
        if (data.success) {
          setTruckTypesList(data.truckTypes);
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true
        });
      });
  }, [setNotification]);

  const onFilterChange = ({
    filter,
    value
  }: {
    filter: keyof TableFilters;
    value: string | number;
  }): void => {
    setTableFilters({ ...tableFilters, [filter]: value });
  };

  const onTruckTypeChange = (
    selected: { id: number; name: string }[]
  ): void => {
    setSelectedTruckType(selected);
    onFilterChange({
      filter: 'truckType',
      value: selected.map((t) => t.id).join(',')
    });
  };

  const onSearchWithFilters = (): void => {
    setQueryParams({
      page: queryParams.page,
      limit: queryParams.limit,
      ...tableFilters
    });
  };

  return (
    <Layout>
      <>
        <JobDetailsPopUp
          job={selectedJob}
          onClose={() => setSelectedJob(null)}
        />

        <Card>
          <Row style={{ marginBottom: '20px' }}>
            <Title>Job board</Title>
          </Row>

          <Column>
            <Filters>
              <GooglePlacesInput
                placeholder='Pick up'
                value={tableFilters.pickup}
                onChange={(newVal) =>
                  onFilterChange({ filter: 'pickup', value: newVal })
                }
              />
              <DateInput
                id='start-date'
                name='start-date'
                min={format(subDays(new Date(), 14), 'yyyy-MM-dd')}
                onChange={(e) =>
                  onFilterChange({ filter: 'startDate', value: e.target.value })
                }
                placeholder='Start date'
                value={tableFilters.startDate}
              />
              <GooglePlacesInput
                placeholder='Drop off'
                value={tableFilters.dropoff}
                onChange={(newVal) =>
                  onFilterChange({ filter: 'dropoff', value: newVal })
                }
              />
              <Select
                displayEmpty
                disableUnderline
                variant='standard'
                value={tableFilters.distance}
                onChange={(e) => {
                  onFilterChange({
                    filter: 'distance',
                    value: e.target.value as string
                  });
                }}
                IconComponent={(props) => (
                  <KeyboardArrowDown
                    {...props}
                    style={{ marginRight: '3px' }}
                  />
                )}
              >
                {distanceOptions.map(({ label, value }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    style={{ fontSize: '14px', fontFamily: 'Nunito' }}
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>

              <MultipleSelect
                placeholder='Truck type'
                selected={selectedTruckType}
                options={truckTypesList}
                onChange={onTruckTypeChange}
                isSubmitted={false}
              />
              <SearchInput
                placeholder='Material'
                value={tableFilters.material}
                onChange={(e) =>
                  onFilterChange({ filter: 'material', value: e.target.value })
                }
                backgroundColor='#fff'
                borderColor='#405daa'
                noMargin
              />
              <Button
                disabled={isLoading}
                onClick={onSearchWithFilters}
                style={{ maxWidth: 'min-content' }}
              >
                Search
              </Button>
            </Filters>

            <Table
              columns={tableCols}
              data={jobs}
              totalRows={totalJobs}
              paginationLimit={queryParams.limit}
              setQueryParams={setQueryParams}
              handleRowClicked={setSelectedJob}
              isLoading={isLoading}
              tableName='Job Board'
              conditionalRowStyles={[
                {
                  when: (row) => row.id === selectedJob?.id,
                  style: { backgroundColor: '#405DAA', color: 'white' }
                }
              ]}
              askToCreateFirst
            />
          </Column>
        </Card>
      </>
    </Layout>
  );
};

export default JobBoard;
