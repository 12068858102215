import { useCallback, useEffect, useState } from 'react';
import { AxiosResponse, AxiosError } from 'axios';
import { useNotification } from '../contexts/notification-context';
import { LoadDetail } from '../types/LoadDetail';
import { getLoadDetail } from '../api/LoadsAPI';
import { AvailableLoadStatus } from '../types/Status';
import { useParams } from 'react-router-dom';

interface returnValue {
  loading: boolean;
  value?: LoadDetail;
  status?: string;
  id: string;
  updateData: () => void;
}

interface urlParams {
  id: string;
}

export default function useLoadDetail(): returnValue {
  // notifications handler
  const { setNotification } = useNotification();
  // access to the url params
  const { id }: urlParams = useParams();

  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<LoadDetail>();
  const [status, setStatus] = useState<AvailableLoadStatus>();

  // Set the cancellation motive
  const updateData = () => {
    callbackMemoized();
  };

  const callbackMemoized = useCallback(async () => {
    setLoading(true);
    getLoadDetail(id)
      .then(({ data }: AxiosResponse) => {
        if (data.success) {
          const newLoadDetail = new LoadDetail(data.load);
          setStatus(newLoadDetail.status);
          setValue(newLoadDetail);
        }
      })
      .catch(({ response }: AxiosError) => {
        setNotification({
          type: 'error',
          message: response?.data.msg,
          open: true,
        });
      })
      .finally(() => setLoading(false));
  }, [setNotification, id]);

  useEffect(() => {
    callbackMemoized();
  }, [callbackMemoized]);

  return { value, loading, status, id, updateData };
}