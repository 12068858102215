import styled from 'styled-components';
import Chip from '@material-ui/core/Chip';

const Contaniner = styled(Chip)`
  background-color: transparent !important;
  border: ${({ usecolor }: { usecolor: string }) =>
    `2px solid ${usecolor} !important`};
  min-width: 88px;
  height: 27px !important;
  cursor: pointer !important;

  .MuiChip-label {
    padding: 0;
  }
`;

const LoadsTaken = styled.span`
  font-weight: bolder !important;
`;

const LoadsRemaining = styled.span`
  font-weight: 400 !important;
`;

interface LoadsBadgeProps {
  loads: number;
  loadsRemaining: number;
}

const LoadsBadge = ({
  loads,
  loadsRemaining,
}: LoadsBadgeProps): JSX.Element => {
  const color = ((): string => {
    if (loadsRemaining > 0 && loadsRemaining <= 5) return '#FF537C';

    if (loadsRemaining > 0 && loadsRemaining < 10) return '#F7D55E';

    return '#82C01D';
  })();

  const loadsTaken = loads - loadsRemaining;

  return (
    <Contaniner
      data-tag="allowRowEvents"
      usecolor={color}
      label={
        <>
          <LoadsTaken data-tag="allowRowEvents">{loadsTaken}</LoadsTaken> /{' '}
          <LoadsRemaining data-tag="allowRowEvents">{loads}</LoadsRemaining>
        </>
      }
    />
  );
};

export default LoadsBadge;
