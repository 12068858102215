import { AxiosResponse } from 'axios';
import { endpoints } from './Constants';
import API from './API';
import { TicketListResponse } from '../types/TicketRow';
import { TicketDetailResponse } from '../types/responses/ticket-detail-response';

// url = pending
const BASE_URL = endpoints.tickets.base;

export interface TicketsQueryParams {
  limit: number;
  page: number;
  since: string;
  until: string;
  status: string;
}

export interface AxiosTicketList {
  success: boolean;
  totalPages: number;
  count: number;
  data: TicketListResponse;
}

export interface AxiosTicketDetailResponse{
  success: boolean;
  ticket: TicketDetailResponse;
}

// get the list of tickets
export const httpGetTicketList = async (
  query: TicketsQueryParams,
): Promise<AxiosResponse<AxiosTicketList>> => {
  const url = `${BASE_URL}`;
  const { limit, page, since, until, status } = query;
  let params: Record<string, unknown> = { page, limit };
  if (query.status !== '') {
    params = { ...params, status };
  }
  if (query.since !== '') {
    params = { ...params, since };
  }
  if (query.until !== '') {
    params = { ...params, until };
  }
  return await API.get(url, { params });
};

// set tickets as paid
export const httpSetTicketAsPaid = async (tickets: number[]): Promise<AxiosResponse> => {
  const url = `${BASE_URL}${endpoints.tickets.pay}`;
  return await API.put(url, {
    tickets
  });
}

// set tickets as not paid
export const httpSetTicketAsNotPaid = async (tickets: number[]): Promise<AxiosResponse> => {
  const url = `${BASE_URL}${endpoints.tickets.pay}`;
  return await API.delete(url, {
    data: { tickets }
  });
}

// get the detail of a specific ticket
export const httpGetTicketDetail = async (id: string): Promise<AxiosResponse<AxiosTicketDetailResponse>> => {
  return await API.get(`${BASE_URL}${id}`);
}