import { Dispatch, SetStateAction } from 'react'
import styled from 'styled-components';

import LocationSelector from '../LocationSelector/LocationSelector';
import { NewJob } from '../../types/GoogleAddress';

const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;
`;

const PrevButton = styled.button`
  width: 176px;
  height: 40px;
  background-color: #F6F7FC;
  color: #91A5DA;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
  cursor: pointer;
  margin-right: 20px;
`;

const NextButton = styled.button`
  width: 176px;
  height: 40px;
  background-color: #F7D55E;
  color: #16244A;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
  cursor: pointer;

  &:disabled {
      background-color: #FDF2CA;
  }
`;

interface JobSiteInfoProps {
  job: NewJob;
  updateJob: Dispatch<SetStateAction<NewJob>>;
  nextStep: () => void;
  prevStep: () => void;
}

const JobSiteInfo = ({
  job,
  nextStep,
  prevStep,
  updateJob,
}: JobSiteInfoProps): JSX.Element => {
  // handle next step click
  const handleNextStep = () => {
    if (!job.jobSiteLocation) return;
    nextStep();
  };

  const onLocationChange = (location: number) => {
    // update job state
    updateJob(job => ({
      ...job,
      jobSiteLocation: location,
    }));
  }

  return (
    <>
      <LocationSelector
        key="job-site-info"
        selected={job.jobSiteLocation}
        onLocationChange={onLocationChange}
      />
      <ButtonsContainer>
        <PrevButton onClick={prevStep}>Prev</PrevButton>
        <NextButton disabled={!job.jobSiteLocation} onClick={handleNextStep}>Next</NextButton>
      </ButtonsContainer>
    </>
  );
}

export default JobSiteInfo;
