import React from 'react';
import styled from 'styled-components';
import textField from '@mui/material/TextField';
import { useField } from 'formik';
import CheckIcon from '@mui/icons-material/Check';
// Styles
const Container = styled.div`
    position: relative;
`;
const TextField = styled(textField)`
  &.MuiTextField-root {
    width: 100%;
  }

  .MuiInputBase-input {
    border-radius: 3px;
    border: 1px solid #91A5DA;
    font-family: Nunito, sans-serif;
    font-weight: 600;
    font-size: 16px;
    color: #16244A;
    padding: 8px 30px 8px 15px;
  }

  .MuiInput-root:before {
    background: white;
    z-index: -1;
    height: 39px;
    border-radius: 3px;
  }

  .MuiInput-root:hover:not(.Mui-disabled):before {
    border-bottom: unset;
  }

  .MuiInput-root:after {
    display: none;
  }

  .MuiInputLabel-root {
    left: 15px;
    top: 6px;
    font-family: Nunito, sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #16244A;

    &.Mui-focused {
      left: 0;
      top: 0;
      color: #405DAA;
    }

    &.MuiFormLabel-filled {
      left: 0;
      top: 0;
    }
  }

  &.completed .MuiInput-root:before {
    background: red;
  }

  &.completed {
    .MuiInput-root:before {
      background: #F6F7FC;
    }
  }

  &.Mui-error {
    .MuiInputBase-input {
      border: 1px solid red;
    }
  }
`;
const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 26px;
  right: 10px;
  z-index: 1;
  .MuiSvgIcon-root{
    color: #405DAA;
    font-size: 18px;
  }
`;

interface Props {
  label: string;
  type: string;
  name: string;
  select?: boolean;
  number?: string;
  children?: React.ReactNode;
  hideIcon?: boolean;
}

const OnBoardingTextField = (props: Props): JSX.Element => {
  const [field, meta] = useField(props);
  const isInvalid = !!(meta.touched && meta.error);
  const isCompleted = meta.touched && !meta.error;
  return (
    <Container>
      {isCompleted && !props.hideIcon ? <IconWrapper><CheckIcon /></IconWrapper>: null}
      <TextField
        {...field}
        {...props}
        error={isInvalid}
        helperText={isInvalid ? meta.error : ''}
        variant='standard'
        className={isCompleted ? 'completed' : undefined}
      />
    </Container>
  );
};


export default OnBoardingTextField;
