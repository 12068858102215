import styled from 'styled-components';
import { Select as MSelect } from '@material-ui/core';
import { TableColumn } from 'react-data-table-component';
import GlobalJob from '../../types/GlobalJob';
import JobAge from '../../components/JobAge/JobAge';
import { formatDate } from '../../utils/dateFormats';
import ArrowUpIcon from '../../components/Icons/ArrowUpIcon';
import ArrowBottomIcon from '../../components/Icons/ArrowBottomIcon';
import { getTruckTypesAsString } from '../../helpers/formats';
import currencyFormat from '../../utils/currencyFormat';
import JobStatusLink from '../../components/JobStatusLink/JobStatusLink';

export type JobBoardQueryParams = {
  page: number;
  limit: number;
  status: string;
  pickup: string;
  dropoff: string;
  distance: string;
  truckType: string;
  material: string;
  startDate: string;
};

export type TableFilters = Omit<JobBoardQueryParams, 'page' | 'limit'>;

export const initialFilters = {
  status: '1',
  pickup: '',
  dropoff: '',
  distance: '0',
  truckType: '',
  material: '',
  startDate: ''
};

export const Filters = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`;

export const Select = styled(MSelect)`
  border: 1px solid #405daa;
  border-radius: 4px;
  color: #16244a;

  .MuiInputBase-input {
    padding: 10px 15px;
    padding-right: 32px !important;
    font-size: 14px !important;
    font-family: 'Nunito';
  }
`;

export const TableIcon = styled.div`
  display: grid;
  place-items: center;
  padding: 3px;
  margin-right: 10px;
  background-color: ${({ isPickUp }: { isPickUp?: boolean }) =>
    isPickUp ? '#F7D55E' : '#B0BEE4'};
`;

const EllipsisText = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

export const tableCols: TableColumn<GlobalJob>[] = [
  {
    name: 'Age',
    selector: (row) => row.createdAt,
    cell: (row) => <JobAge age={row.age} />,
    width: '75px',
    sortable: true
  },
  {
    name: 'Start date',
    selector: (row) => row.startDate,
    cell: ({ startDate }) =>
      formatDate(new Date(startDate.replaceAll('-', '/'))),
    maxWidth: 'max-content',
    sortable: true
  },
  {
    name: 'Pick up',
    minWidth: '220px',
    selector: ({ pickupLocation }) =>
      `${pickupLocation.city}, ${pickupLocation.state}`,
    cell: ({ pickupLocation }) => (
      <>
        <TableIcon isPickUp>
          <ArrowUpIcon size={8} />
        </TableIcon>
        {pickupLocation.city}, {pickupLocation.state}
      </>
    ),
    sortable: true
  },
  {
    name: 'Drop off',
    selector: ({ dropoffLocation }) =>
      `${dropoffLocation.city}, ${dropoffLocation.state}`,
    cell: ({ dropoffLocation }) => (
      <>
        <TableIcon>
          <ArrowBottomIcon size={8} />
        </TableIcon>
        {dropoffLocation.city}, {dropoffLocation.state}
      </>
    ),
    minWidth: '220px',
    sortable: true
  },
  {
    name: 'Distance',
    selector: ({ distance }) => `${distance} miles`,
    sortable: true,
    maxWidth: 'max-content'
  },
  {
    name: 'Truck Type',
    selector: ({ truckType }) => getTruckTypesAsString(truckType),
    sortable: true,
    width: '150px',
    cell: ({ truckType }) => (
      <EllipsisText>{getTruckTypesAsString(truckType)}</EllipsisText>
    )
  },
  {
    name: 'Material',
    selector: ({ material }) => material,
    sortable: true,
    width: '140px',
    cell: ({ material }) => <EllipsisText>{material}</EllipsisText>
  },
  {
    name: 'Price',
    selector: ({ price }) => price,
    cell: ({ price }) => currencyFormat(price),
    sortable: true,
    center: true,
    maxWidth: 'max-content'
  },
  {
    name: '',
    sortable: false,
    cell: ({ status, id }) => <JobStatusLink status={status} id={id} />,
    maxWidth: 'max-content'
  }
];
