import { LocationResponse } from './responses/job-response';

export class Location {
  id?: number;
  address: string;
  city: string;
  state: string;
  stateAbbr: string;
  country: string;
  zipCode: string;
  coordinates: { lat: number; lng: number };
  fullAddress: string;
  haulingHours: string;
  name: string;

  constructor(data: LocationResponse) {
    this.id = data.id;
    this.address = data.address;
    this.city = data.city;
    this.state = data.state;
    this.stateAbbr = data.stateAbbr.toUpperCase();
    this.country = data.country;
    this.zipCode = data.zipCode;
    this.coordinates = this.generateCoordinates(data.latlng);
    this.fullAddress = `${this.address}, ${this.city}, ${this.stateAbbr} ${this.zipCode}`;
    this.haulingHours = this.generateHaulingSchedule(data.haulingHour);
    this.name = data.name;
  }

  // generate a coordinates object in based of an array of numbers
  private generateCoordinates(coordinates: number[]): {
    lat: number;
    lng: number;
  } {
    return {
      lat: coordinates[0],
      lng: coordinates[1],
    };
  }

  private generateHaulingSchedule(haulingTimes: {
    startTime: string;
    endTime: string;
  }): string {
    const startAt = haulingTimes.startTime.slice(0, 5);
    const endAt = haulingTimes.endTime.slice(0, 5);
    return `${startAt}-${endAt}`;
  }
}
