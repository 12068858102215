import styled from 'styled-components';

interface WrapperProps {
  size?: string;
}

const Wrapper = styled.div`
  font-size: ${({ size }: WrapperProps) => (!!size ? size : 'inherit')};
  font-weight: 400;
  text-transform: capitalize;
  white-space: normal;
`;
const LeftValue = styled.span`
  font-weight: 700;
`;

const RightValue = styled.span``;

interface DataWithSlashProps {
  left: string | number;
  right: string | number;
  size?: string;
}

const DataWithSlash = ({
  left,
  right,
  size,
}: DataWithSlashProps): JSX.Element => {
  return (
    <Wrapper size={size} data-tag="allowRowEvents">
      <LeftValue data-tag="allowRowEvents">{left}</LeftValue> / <RightValue data-tag="allowRowEvents">{right}</RightValue>
    </Wrapper>
  );
};

export default DataWithSlash;