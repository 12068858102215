import React from 'react';
import styled from 'styled-components';
import { useModal } from '../../contexts/modal-context';
import { Close } from '@material-ui/icons';
import BaseModal from '../BaseModal/BaseModal';
import { TicketDetailTrucker } from '../../types/TicketDetail';

// Styles
const Wrapper = styled.div`
  background: #FFF;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.06);
  border-radius: 3px;
  padding: 25px 30px;
  font-family: Nunito, sans-serif;
  color: #16244A;
  font-weight: 700;
  width: 100%;
  max-width: 470px;

  /*  Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    margin: 0 20px;
  }
  /*  Large devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    margin: 0 180px;
  }
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
`;
const Title = styled.h1`
  font-size: 18px;
`;
const CloseIconWrapper = styled.div`
  color: #91A5DA;
  margin-left: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const Grid = styled.div`
  display: grid;
  border-top: 1px solid #C2CDEA;
  grid-template-columns: 1fr 1fr;
`;

const FieldName = styled.h4`
  font-weight: 300;
  font-size: 12px;
  margin: 0;
`;
const FieldValue = styled.p`
  font-size: 12px;
  margin: 0;
`;
const LeftColumn = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #C2CDEA;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-right: 5px;
`;
const RightColumn = styled(LeftColumn)`
  justify-content: flex-end;
  text-align: right;
  border-bottom: 1px solid #C2CDEA;
  padding-right: 0;
  padding-left: 5px;
`;

interface Props {
  data?: TicketDetailTrucker;
}

const TruckerInfoModal = ({data}: Props): JSX.Element => {
  const { close } = useModal();
  return (
    <BaseModal>
      <Wrapper>
        <Header>
          <Title>{data?.name}</Title>
          <CloseIconWrapper onClick={close}>
            <Close />
          </CloseIconWrapper>
        </Header>
        <Grid>
          <LeftColumn>
            <FieldName>
              Email
            </FieldName>
          </LeftColumn>
          <RightColumn>
            <FieldValue>
              {data?.email}
            </FieldValue>
          </RightColumn>

          <LeftColumn>
            <FieldName>
              Cell phone
            </FieldName>
          </LeftColumn>
          <RightColumn>
            <FieldValue>
              {data?.phone}
            </FieldValue>
          </RightColumn>

          <LeftColumn>
            <FieldName>
              Company name
            </FieldName>
          </LeftColumn>
          <RightColumn>
            <FieldValue>
              {data?.companyName}
            </FieldValue>
          </RightColumn>

          <LeftColumn>
            <FieldName>
              Truck type
            </FieldName>
          </LeftColumn>
          <RightColumn>
            <FieldValue>
              {data?.truckType}
            </FieldValue>
          </RightColumn>


        </Grid>
      </Wrapper>
    </BaseModal>
  );
};

export default TruckerInfoModal;
