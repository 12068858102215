import { AxiosResponse } from 'axios';
import API from './API';
import { endpoints } from './Constants';
import { NewLocation } from '../types/GoogleAddress';

// url = gc/jobs/
const BASE_URL = endpoints.locations.base;

// create new location
export const createLocation = async (location: NewLocation): Promise<AxiosResponse> => {
  return API.post(BASE_URL, location);
}

// search user's locations
export const searchLocation = async (search: string): Promise<AxiosResponse> => {
  const params: Record<string, unknown> = { search };
  return await API.get(BASE_URL, { params });
};

