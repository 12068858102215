import { useNotification } from '../contexts/notification-context';
import { useState } from 'react';
import { AxiosResponse, AxiosError, AxiosPromise } from 'axios';
import { useModal } from '../contexts/modal-context';

type updateType = (actions: UpdateInterface) => void;

interface UpdateInterface {
  callback: () => AxiosPromise;
  onFinalize?: () => void;
  onSuccess?: () => void;
}

interface useUpdateReturn {
  loading: boolean;
  update: updateType;
}

export default function useUpdate(): useUpdateReturn {
  const [loading, setLoading] = useState(false);

  const { setNotification } = useNotification();

  const { close } = useModal();

  const update = ({
    callback,
    onFinalize = () => null,
    onSuccess = () => null,
  }: UpdateInterface): void => {
    callback()
      .then(({ data }: AxiosResponse) => {
        if (data.success) {
          onSuccess();
          setNotification({
            type: 'success',
            message: data.msg,
            open: true,
          });
        }
      })
      .catch(({ response }: AxiosError) => {
        setNotification({
          type: 'error',
          message: response?.data.msg,
          open: true,
        });
      })
      .finally(() => {
        onFinalize();
        close();
        setLoading(false);
      });
  };

  return { loading, update };
}
