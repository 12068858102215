import { GlobalJobResponse } from './responses/job-response';
import { Job } from './Job';

export default class GlobalJob extends Job {
  createdAt: string;
  price: number;
  age: string;

  constructor(data: GlobalJobResponse) {
    const { createdAt, price, age, ...rest } = data;
    super({ ...rest });
    this.createdAt = createdAt;
    this.price = price;
    this.age = age;
  }
}
