import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Grid, InputLabel, MenuItem, Select } from '@material-ui/core';
import { Form, Formik, FormikValues } from 'formik';
import { KeyboardArrowDown } from '@material-ui/icons';
import * as Yup from 'yup';
import styled from 'styled-components';

import { useNotification } from '../../contexts/notification-context';
import MultipleSelect from '../MultipleSelect/MultipleSelect';
import TextInput from '../TextInput/TextInput';
import { NewJob } from '../../types/GoogleAddress';
import { getTruckTypes, getJobTypes } from '../../api/ListsAPI';

const JobForm = styled(Form)`
  margin: 0 24px !important;

  .MuiInputBase-input {
    padding: 10px 15px !important;
    background-color: #FAFBFD !important;
    border-radius: 4px !important;
    font-family: Nunito;
    font-weight: 400 !important;
    font-size: 14px;
    color: #16244a;
  }

  .MuiSelect-select.MuiSelect-selectMenu {
    padding: 8px 15px !important;
  }

  .MuiGrid-item {
    padding: 10px 12px !important;
  }

  .MuiInputBase-multiline {
    background-color: #FAFBFD !important;
  }

  & fieldset {
    border-color: #405daa !important;
  }
`;

const JobTypeSelect = styled(Select)`
  & fieldset {
    border-color: #405daa !important;
  }
  &:hover fieldset {
    border-color: #405daa !important;
  }
  &.Mui-focused fieldset {
    border-color: #405daa;
    box-shadow: 0px 4px 4px #eef1f9;
  }
`;

const Label = styled(InputLabel)`
  font-family: Nunito !important;
  font-weight: 300 !important;
  color: #16244A !important;
  margin: 4px 6px !important;
`;

const NextButton = styled.button`
  width: 176px;
  height: 40px;
  background-color: #F7D55E;
  color: #16244A;
  border: 0;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  font-family: Nunito;
  cursor: pointer;

  &:disabled {
    background-color: #FDF2CA
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 24px;

  @media (min-width: 320px) and (max-width: 991.98px) {
    width: 100%;
  }
`;

const FakeInput = styled.div`
  width: 100%;
  border: 1px solid #405daa;
  background-color: #fafbfd;
  border-radius: 4px;
  padding: 18px 0;
`;

// validations object
const validations = Yup.object({
  name: Yup.string().required('Required'),
  material: Yup.string().required('Required'),
  type: Yup.string().required('Required'),
  loads: Yup.number().required('Required'),
  loadsNotification: Yup.number().required('Required'),
  startDate: Yup.string().required('Required'),
  rate: Yup.number().max(9999).required('Required'),
  remarks: Yup.string().required('Required'),
});

interface JobInfoProps {
  job: NewJob;
  setJobType: Dispatch<SetStateAction<number>>;
  updateJob: Dispatch<SetStateAction<NewJob>>;
  nextStep: () => void;
}

const JobInfo = ({
  job,
  setJobType,
  updateJob,
  nextStep,
}: JobInfoProps): JSX.Element => {

  const { setNotification } = useNotification();

  // handle form submission for not valid formik inputs
  const [submitted, setSubmitted] = useState(false);

  // list of available truck types to display on the multi select component
  const [listTrucktypes, setListTrucktypes] = useState<
    { id: number; name: string }[]
  >([]);

  // list of available truck types to display on the multi select component
  const [listJobTypes, setListJobTypes] = useState<
    { id: number; name: string }[]
  >([]);

  // truck types selected by the user
  const [trucktypeSelected, setTrucktypeSelected] = useState<({ id: number, name: string } | undefined)[]>([]);

  const { truckTypes } = job

  const handleSubmit = (
    values: FormikValues,
    { setSubmitting }: { setSubmitting: (value: boolean) => void }
  ) => {
    setSubmitted(true);

    if (isFormValid()) {
      setSubmitting(false);
      return;
    }

    const truckTypes = trucktypeSelected.map(t => t.id);

    updateJob({
      ...job,
      ...values,
      truckTypes
    });

    nextStep();
  }

  useEffect(() => {
    if (listTrucktypes.length) {
      const selectedTypes = truckTypes.map((type) => {
        return listTrucktypes.find((listType) => {
          return listType.name === type || listType.id === type;
        });
      });

      if (selectedTypes.length) {
        setTrucktypeSelected(selectedTypes);
      }
    }
  }, [listTrucktypes, truckTypes])

  // get the list of available truck types and job types
  useEffect(() => {
    getTruckTypes()
      .then(({ data }) => {
        if (data.success) {
          setListTrucktypes(data.truckTypes);
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      });

    getJobTypes()
      .then(({ data }) => {
        if (data.success) {
          setListJobTypes(data.jobTypes);
        }
      }).catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
  }, [setNotification]);

  // validate form
  const isFormValid = (): boolean => {
    return !!(!trucktypeSelected.length);
  };


  return (
    <Formik
      initialValues={job}
      validationSchema={validations}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({ isValid, values, handleChange }) => (
        <>
          <JobForm key="job-form">
            <Grid container>
              <Grid item xs={12} md={4}>
                <Label name="lblName">Name</Label>
                <TextInput fullWidth={true} name="name" type="text" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label name="lbltype">Job type</Label>
                {listJobTypes.length ? (
                  <JobTypeSelect
                    defaultValue=""
                    fullWidth
                    variant="outlined"
                    name="type"
                    displayEmpty
                    onChange={(e) => {
                      handleChange(e);
                      setJobType(e.target.value);
                    }}
                    value={values.type}
                    IconComponent={(props) => <KeyboardArrowDown {...props} />}
                  >
                    {listJobTypes.map(({ id, name }) => {
                      return (
                        <MenuItem key={id} value={id}>{name}</MenuItem>
                      );
                    })}
                  </JobTypeSelect>
                ) : (
                  <FakeInput />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <Label name="lblStartDate">Start Date</Label>
                <TextInput fullWidth={true} name="startDate" type="date" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={4}>
                <Label name="lblLoads">Estimated loads</Label>
                <TextInput fullWidth={true} name="loads" type="number" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label name="lblLoadsNotification">Loads remaining notification</Label>
                <TextInput fullWidth={true} name="loadsNotification" type="number" />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label name="lblRate">Rate per hour</Label>
                <TextInput fullWidth={true} name="rate" type="number" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={8}>
                <Label name="lblTruckTypes">Truck Types</Label>
                <MultipleSelect
                  name="truckTypesSelect"
                  selected={trucktypeSelected}
                  options={listTrucktypes}
                  onChange={setTrucktypeSelected}
                  isSubmitted={submitted}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <Label name="lblMaterial">Material</Label>
                <TextInput fullWidth={true} name="material" type="text" />
              </Grid>
            </Grid>
            <Grid container>
              <Grid item xs={12} md={12}>
                <Label name="lblRemarks">Notes</Label>
                <TextInput multiline={true} name="remarks" type="text" fullWidth={true} />
              </Grid>
            </Grid>
            <ButtonsContainer>
              <NextButton name="bntNext" type="submit" disabled={!isValid}>Next</NextButton>
            </ButtonsContainer>
          </JobForm>
        </>
      )}
    </Formik>
  );
}

export default JobInfo;
