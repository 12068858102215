import { FC, useState } from 'react';
import styled from 'styled-components';

import Banner from '../../assets/images/publicBanner02.jpg';
import Pattern from '../../assets/images/b01.png';
import TruckCaddyLogo from '../../assets/images/logo.png';

import PersonalInfo from '../../components/PersonalInfo/PersonalInfo';
import AditonalInfo from '../../components/AditionalInfo/AditonalInfo';
import Confirmation from '../../components/Confirmation/Confirmation';
import { UserProfile } from '../../types/User';
import CustomStepper from '../../components/CustomStepper/CustomStepper';
import ProfileTypeSelection from '../../components/ProfileType/ProfileTypeSelection';
import { ProfileTypes } from '../../types/ProfileTypes';

const Container = styled.div`
  height: 100vh;
  display: flex;

  &.MuiGrid-container {
    flex-wrap: nowrap;
  }
`;

const SideBar = styled.div`
  box-sizing: border-box;
  background-image: url(${Banner});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-grow: 0;
  padding: 75px 85px 50px;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  @media (max-width: 900px) {
    display: none;
  }
`;
const SideBarContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const SideBarTitle = styled.h1`
  max-width: 300px;
  margin: 0;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.5);
  line-height: 103.19%;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TitleImage = styled.img`
  height: auto;
  max-width: 100%;
`;
const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 75px 45px;
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right bottom;
    background-image: url(${Pattern});
  }

  @media (max-width: 900px) {
    max-width: 100%;
    flex-basis: 100%;
  }
  @media (max-width: 600px) {
    &:before {
      display: none;
    }
  }
  @media (max-width: 400px) {
    padding: 75px 25px;
  }
`;

const Header = styled.div`
  flex: 1;
  width: 100%;
`;

const Content = styled.div`
  flex: 3;
`;

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const OnBoardingPage: FC = () => {
  // new user state
  const [newUser, setNewUser] = useState<UserProfile>({
    userType: -1,
    name: '',
    lastname: '',
    email: '',
    phone: '',
    companyName: '',
    password: '',
    confirmPassword: ''
  });

  // step state
  const [activeStep, setActiveStep] = useState(-1);

  // Account type
  const [selectedType, setSelectedType] = useState<ProfileTypes | null>(null);

  // lists of steps available
  const steps = ['Personal Info', 'Additional Info', 'Confirmation'];

  // move to the next step
  const handleNextStep = () => {
    if (activeStep === steps.length - 1) return;
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  // get back to the prev strep
  const handleBack = () => {
    if (activeStep === 0) return;
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  /**
   * render a specific component based on the step index entered
   * @param {number} stepIndex index of the step selected to render.
   */
  const getContent = (stepIndex: number): JSX.Element => {
    switch (stepIndex) {
      case -1:
        return (
          <ProfileTypeSelection
            selectedType={selectedType}
            setSelectedType={setSelectedType}
            nextStep={() => setActiveStep(0)}
          />
        );
      case 0:
        return (
          <PersonalInfo
            user={newUser}
            updateUser={setNewUser}
            nextStep={handleNextStep}
          />
        );
      case 1:
        return (
          <AditonalInfo
            user={newUser}
            selectedType={selectedType}
            updateUser={setNewUser}
            nextStep={handleNextStep}
            prevStep={handleBack}
          />
        );
      case 2:
        return <Confirmation />;
      default:
        return (
          <PersonalInfo
            user={newUser}
            updateUser={setNewUser}
            nextStep={handleNextStep}
          />
        );
    }
  };

  return (
    <Container>
      <SideBar>
        <SideBarContent>
          <SideBarTitle>The safest way for you</SideBarTitle>
          <LogoContainer>
            <TitleImage src={TruckCaddyLogo} />
          </LogoContainer>
        </SideBarContent>
      </SideBar>
      <MainContainer>
        <Main>
          {activeStep !== -1 && (
            <Header>
              <CustomStepper activeStep={activeStep} steps={steps} />
            </Header>
          )}
          <Content>{getContent(activeStep)}</Content>
        </Main>
      </MainContainer>
    </Container>
  );
};

export default OnBoardingPage;
