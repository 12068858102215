import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert, { Color } from '@material-ui/lab/Alert';

export interface NotificationProps {
  message: string;
  type: Color | undefined;
  open: boolean;
  handleClose?: () => void;
}

export const Notification = ({
  message,
  type,
  open,
  handleClose,
}: NotificationProps): JSX.Element => {
  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <MuiAlert
        elevation={6}
        variant="filled"
        severity={type}
        onClose={handleClose}
      >
        {message}
      </MuiAlert>
    </Snackbar>
  );
};
