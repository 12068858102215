import { AvailableLoadStatus } from './Status';
import { AvailableJobTypes } from './JobTypes';
import { loadDetailResponse } from './responses/load-detail-response';
import { formatDateLongMonth, formatHour } from '../helpers/formats';

export class LoadDetail {
  createdAt: string;
  endDate: string;
  startDate: string;
  rate: number;
  status: AvailableLoadStatus;
  totalEstimated: number;
  totalHours: 0;
  trucker: {
    name: string;
    truckTypes: string;
  };
  jobSite: {
    address: string;
    latlng: number[];
    name: string;
  };
  location: {
    address: string;
    latlng: number[];
    name: string;
  };
  distance: number;
  type: AvailableJobTypes;
  remarks: string;
  tracking: Tracks[];
  cancellationReason: string;

  constructor(data: loadDetailResponse) {
    this.createdAt = formatDateLongMonth(data.createdAt);
    this.endDate = formatHour(data.endDate);
    this.startDate = formatHour(data.startDate);
    this.status = data.status;
    this.totalEstimated = data.totalEstimated;
    this.totalHours = data.totalHours;
    this.trucker = data.trucker;
    this.jobSite = data.jobSite;
    this.location = data.location;
    this.distance = data.distance;
    this.type = data.type;
    this.rate = data.rate;
    this.remarks = data.remarks;
    this.tracking = data.tracking;
    this.cancellationReason =
      data.cancellationReason;
  }
}

export interface Tracks {
  date: string;
  position: number[];
}
