import { InputHTMLAttributes } from 'react';
import styled from 'styled-components';

const Input = styled.input`
  padding: 10px 15px;
  font-family: Nunito;
  border-radius: 4px;
  border: 1px solid #405daa;
  color: #1c3264;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
`;

const Label = styled.label`
  position: absolute;
  background: white;
  width: 60%;
  height: 90%;
  top: 50%;
  left: 14px;
  transform: translate(0%, -50%);
  display: flex;
  align-items: center;
  color: #808080;
  font-size: 14px;
`;

const Container = styled.div`
  position: relative;
`;

const DateInput: React.FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
  return (
    <Container>
      {!props.value && props.placeholder && <Label htmlFor={props.id}>{props.placeholder}</Label>}
      <Input type='date' {...props} />
    </Container>
  );
};

export default DateInput;
