import React, { useState } from 'react';

// Forms
import { Form, Formik, FormikHelpers } from 'formik';
import { myAccountValidations } from '../../helpers/validations';

// Components
import TextField from '../../components/TextField/TextField';
import { CircularProgress } from '@material-ui/core';
import UpdateMyAccountModal from '../../components/ConfirmationModal/UpdateMyAccountModal';

// Fetching API
import {
  getMyAccount,
  MyAccountResponse,
  updateMyAccount,
  updatePassword,
  updatePasswordInterface
} from '../../api/AccountAPI';
import { MyAccountData } from '../../types/Account';

// Context
import Layout from '../../shared/components/Layout/Layout';
import { useAuth } from '../../contexts/auth-context';
import { useModal } from '../../contexts/modal-context';

// Hooks
import useInitValues from '../../hooks/useInitValues';
import useUpdate from '../../hooks/useUpdate';

// Styles
import {
  Card,
  Row,
  Title,
  TitleRow,
  SaveButton,
  EditButton,
  EmptyColumn,
  Divider,
  SpinnerContainer,
  Instructions,
  DownloadSection,
  Store
} from './my-account.styles';

import TruckCaddyQR from '../../assets/images/truck-caddy-qr.png';
import { Roles } from '../../types/Roles';
import { PlainTextButton } from '../Login/login.styles';

const MyAccountPage = (): JSX.Element => {
  // confirmation modal context
  const { requireModal } = useModal();

  // functionality to update the user object from localStorage & global state
  const { user, updateUser } = useAuth();

  // get the user profile data
  const { loading, value } = useInitValues(getMyAccount);

  // update data functionality
  const passUpdate = useUpdate();
  const accountUpdate = useUpdate();

  // control whether or not its posible to edit the form data
  const [isEditionActive, setIsEditionActive] = useState(false);

  const isTrucker = user?.roles?.includes(Roles.TRUCKER);

  // sets the read only fields to editable
  const handleOnEdit = () => {
    setIsEditionActive(true);
  };

  // send a request to update the user account data
  const submitMyAccountUpdate = (values: MyAccountData) => {
    requireModal(
      <UpdateMyAccountModal
        submit={() => {
          accountUpdate.update({
            callback: () => updateMyAccount(values),
            onSuccess: () => updateUser(values)
          });
        }}
      />
    );
  };

  // send a request to update the user password
  const submitPasswordUpdate = (
    values: updatePasswordInterface,
    actions: FormikHelpers<updatePasswordInterface>
  ) => {
    passUpdate.update({
      callback: () => updatePassword(values),
      onFinalize: () => actions.resetForm()
    });
  };

  return (
    <Layout>
      {loading || passUpdate.loading || accountUpdate.loading ? (
        <SpinnerContainer>
          <CircularProgress disableShrink />
        </SpinnerContainer>
      ) : (
        <>
          <Card>
            <Formik
              initialValues={
                (value as unknown as MyAccountResponse)?.user.profile
              }
              validationSchema={myAccountValidations.user}
              enableReinitialize
              onSubmit={submitMyAccountUpdate}
            >
              <Form>
                <TitleRow>
                  <Title>Personal info</Title>
                  {isEditionActive ? (
                    <SaveButton type='submit'>Save changes</SaveButton>
                  ) : (
                    <EditButton type='button' onClick={handleOnEdit}>
                      Edit info
                    </EditButton>
                  )}
                </TitleRow>
                <Divider />
                <Row>
                  <TextField
                    name='name'
                    autoComplete='given-name'
                    label='First Name'
                    customBg='#FAFBFD'
                    disabled={!isEditionActive}
                    switchFontStyle
                  />
                  <TextField
                    name='lastname'
                    autoComplete='family-name'
                    label='Last Name'
                    customBg='#FAFBFD'
                    disabled={!isEditionActive}
                    switchFontStyle
                  />
                  <TextField
                    name='phone'
                    label='Cell phone'
                    customBg='#FAFBFD'
                    disabled={!isEditionActive}
                    switchFontStyle
                  />
                </Row>
                <Row>
                  <TextField
                    name='companyName'
                    autoComplete='organization'
                    label='Company name'
                    customBg='#FAFBFD'
                    disabled={!isEditionActive}
                    switchFontStyle
                  />
                  <TextField
                    name='email'
                    label='E-mail'
                    disabled={true}
                    switchFontStyle
                  />
                  <EmptyColumn />
                </Row>
              </Form>
            </Formik>
          </Card>
          <Card className='mb'>
            <Formik
              initialValues={{
                currentPassword: '',
                password: '',
                confirmPassword: ''
              }}
              validationSchema={myAccountValidations.password}
              enableReinitialize
              onSubmit={submitPasswordUpdate}
            >
              <Form>
                <TitleRow>
                  <Title>Password</Title>
                  <SaveButton type='submit'>Change Password</SaveButton>
                </TitleRow>
                <Divider />
                <Row>
                  <TextField
                    autoComplete='current-password'
                    name='currentPassword'
                    label='Current password'
                    customBg='#FAFBFD'
                    type='password'
                  />
                  <TextField
                    name='password'
                    label='New password'
                    customBg='#FAFBFD'
                    type='password'
                  />
                  <TextField
                    name='confirmPassword'
                    label='Repeat new password'
                    customBg='#FAFBFD'
                    type='password'
                  />
                </Row>
              </Form>
            </Formik>
          </Card>

          {isTrucker && (
            <Card style={{ marginBottom: 20 }}>
              <Title>Insurance and Truck information</Title>
              <Instructions>
                Scan the QR code below to download the app, complete and see
                your Insurance and Truck information...
              </Instructions>

              <DownloadSection>
                <Store>
                  <img
                    src={TruckCaddyQR}
                    alt='Scan the QR code to download on the App Store'
                    width={260}
                  />
                </Store>

                <Instructions>
                  {'Or click '}
                  <PlainTextButton
                    id='truck-caddy-site'
                    href='https://truckcaddyapp.com/'
                    target='_blank'
                    rel='noreferrer'
                    style={{ fontSize: '16px', fontWeight: 700 }}
                  >
                    here
                  </PlainTextButton>
                </Instructions>
              </DownloadSection>
            </Card>
          )}
        </>
      )}
    </Layout>
  );
};

export default MyAccountPage;
