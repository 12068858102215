type Props = {
    size: number;
};

const ArrowUpIcon = ({ size }: Props): JSX.Element => {
  return (
    <svg width={size} height={size} viewBox="0 0 9 9" fill="none">
      <path
        d="M7.752 1L1 7.752M2.125 1h5.626v5.626"
        stroke="#1C3264"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ArrowUpIcon;
