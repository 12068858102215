import { useState, useCallback, useEffect } from 'react';
import { loadStatus } from '../api/ListsAPI';
import { useNotification } from '../contexts/notification-context';

interface returnType {
  list: { id: string; name: string }[];
  loading: boolean;
}

export default function useLoadStatusList(): returnType {
  // notifications handler
  const { setNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const getStatus = useCallback(() => {
    setLoading(true);
    loadStatus()
      .then(({ data }) => {
        if (data.success) {
          const wildCardId = data.status.length + 1;
          data.status.unshift({ id: wildCardId, name: 'Show all' });
          setList(data.status);
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => setLoading(false));
  }, [setNotification]);

  useEffect(() => {
    getStatus();
  }, [getStatus]);

  return { list, loading };
}