import { useState } from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import DropOffIcon from '../../assets/images/dropoff-location.png';
import PickUpIcon from '../../assets/images/pickup-location.png';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';

type GlobalJobDetailsMapProps = {
  pickupLocation: { lat: number; lng: number };
  dropoffLocation: { lat: number; lng: number };
};

const MapContainer = styled.div`
  height: 245px;

  /*  Small devices */
  @media (max-width: 700px) {
    height: 190px;
  }
`;

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%'
};

const style = {
  display: 'flex'
};

const GlobalJobDetailsMap: React.FC<GlobalJobDetailsMapProps> = (props) => {
  const { pickupLocation, dropoffLocation } = props;
  const [, setLoaded] = useState(false);

  const points = [pickupLocation, dropoffLocation];
  const bounds = new google.maps.LatLngBounds();
  for (let i = 0; i < points.length; i++) {
    bounds.extend(points[i]);
  }

  return (
    <MapContainer>
      <Map
        mapId={'pickup-dropoff-map'}
        style={style}
        google={google}
        containerStyle={containerStyle}
        initialCenter={pickupLocation}
        bounds={bounds}
        onIdle={() => setLoaded(true)}
        disableDefaultUI
      >
        <Marker
          title={'Pickup marker'}
          position={pickupLocation}
          icon={{ url: PickUpIcon }}
        />
        <Marker
          title={'Dropoff marker'}
          position={dropoffLocation}
          icon={{ url: DropOffIcon }}
        />
      </Map>
    </MapContainer>
  );
};

const LoadingContainer = () => (
  <div style={{ display: 'grid', placeItems: 'center' }}>
    <CircularProgress disableShrink />
  </div>
);

export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyBG7dgqYVoOnxtU6doKTM_iWqmfwXQUry4',
  language: 'en',
  LoadingContainer
}))(GlobalJobDetailsMap);
