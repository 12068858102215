import axios from 'axios';

import {
  deleteToken,
  getToken,
  isTokenExpired,
  setToken,
} from '../helpers/auth-helper';
import { refreshToken } from './AuthenticationAPI';
import { endpoints, endpointsUnprotected } from './Constants';
import { User } from '../types/User';


const configuredAxios = axios.create({
  baseURL: endpoints.BASE_URL,
  responseType: 'json',
});

const refreshTokenURL = `${endpoints.auth.base}${endpoints.auth.refreshToken}`;

configuredAxios.interceptors.request.use(
  function(req) {
    const token = getToken();
    if (
      token &&
      !endpointsUnprotected.some((endpoint) => req.url?.includes(endpoint))
    ) {
      req.headers['Authorization'] = `Bearer ${token.token}`;
    }
    return req;
  },
  function(error) {
    return Promise.reject(error);
  },
);

configuredAxios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    const token: User | null = getToken();
    if (!token || originalRequest.url === refreshTokenURL) return Promise.reject(error);
    const isExpired = isTokenExpired(token.token);
    if (error.response.status === 401 && !originalRequest._retry && isExpired) {
      originalRequest._retry = true;
      try {
        const newToken = await refreshToken(token.refreshToken);
        token.token = newToken.data.token;
        setToken(token);
        return configuredAxios(originalRequest);
      } catch (error) {
        console.error(error);
        deleteToken();
        window.location.replace('/');
      }
    }
    return Promise.reject(error);
  },
);


export default configuredAxios;
