import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: ${({ pd }: Props) => pd};
`;

interface Props {
  pd: string;
}

const SpinnerContainer = ({ pd }: Props): JSX.Element => {
  return (
    <Wrapper pd={pd}>
      <CircularProgress disableShrink />
    </Wrapper>
  );
};

export default SpinnerContainer;