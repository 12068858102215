import JobLocationCard from '../JobLocationCard/JobLocationCard';
import GlobalJob from '../../types/GlobalJob';
import currencyFormat from '../../utils/currencyFormat';
import GlobalJobDetailsMap from '../GlobalJobDetailsMap/GlobalJobDetailsMap';
import Button from '../Button/Button';
import { useModal } from '../../contexts/modal-context';
import {
  SvgIconComponent,
  AccessTime,
  AttachMoney,
  Height
} from '@material-ui/icons';
import {
  Container,
  BookJobSection,
  Details,
  Locations,
  Detail,
  DetailCard,
  ListName,
  ListValue,
  LoadsRemaining
} from './GlobalJobDetails.styles';
import DownloadAppModal from '../ConfirmationModal/DownloadAppModal';

const GlobalJobDetails: React.FC<{ job: GlobalJob }> = ({ job }) => {
  const { requireModal } = useModal();

  const pickupLocation = {
    lat: job.pickupLocation.coordinates.lng,
    lng: job.pickupLocation.coordinates.lat
  };

  const dropoffLocation = {
    lat: job.dropoffLocation.coordinates.lng,
    lng: job.dropoffLocation.coordinates.lat
  };

  return (
    <Container>
      <GlobalJobDetailsMap
        pickupLocation={pickupLocation}
        dropoffLocation={dropoffLocation}
      />

      <Locations>
        <JobLocationCard location={job.pickupLocation} isPickUp />
        <JobLocationCard location={job.dropoffLocation} isPickUp={false} />
      </Locations>

      <Details>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            flexWrap: 'wrap'
          }}
        >
          <DetailWithIcon
            icon={AccessTime}
            label='START DATE'
            value={job.startDate}
          />
          <DetailWithIcon
            icon={Height}
            label='DISTANCE'
            iconStyle={{ rotate: '90deg' }}
            value={job.distance + ' miles'}
          />
          <DetailWithIcon
            icon={AttachMoney}
            label='PRICE'
            value={currencyFormat(job.price)}
          />
        </div>

        <Detail>
          <ListName>TRUCKS</ListName>
          <ListValue>{job.truckType.join(', ')}</ListValue>
        </Detail>
        <Detail>
          <ListName>MATERIAL</ListName>
          <ListValue>{job.material}</ListValue>
        </Detail>
        <Detail vertical>
          <ListName>NOTES</ListName>
          <span style={{ color: '#1c3264' }}>{job.remarks}</span>
        </Detail>
      </Details>

      <LoadsRemaining>{job.loadsRemaining} Loads remaining</LoadsRemaining>

      <BookJobSection>
        <div style={{ flex: '1' }}>
          <span style={{ fontWeight: 600, fontSize: '17px' }}>
            ${job.price} Total
          </span>
          <span>{` / $${job.rate} Hourly`}</span>
        </div>

        <Button
          text='Book Job'
          handleClick={() => requireModal(<DownloadAppModal />)}
          background='#1E3363'
          borderColor='transparent'
          color='white'
          style={{
            margin: '0',
            width: 'auto',
            flex: '1',
            textTransform: 'none'
          }}
        />
      </BookJobSection>
    </Container>
  );
};

const DetailWithIcon: React.FC<{
  icon: SvgIconComponent;
  label: string;
  value: string;
  iconStyle?: React.CSSProperties;
}> = ({ icon: Icon, label, value, iconStyle }) => (
  <DetailCard>
    <Icon
      color='primary'
      style={{
        width: '20px',
        height: '20px',
        marginBottom: '5px',
        ...iconStyle
      }}
    />
    <ListName style={{ fontSize: '13px' }}>{label}</ListName>
    <ListValue>{value}</ListValue>
  </DetailCard>
);

export default GlobalJobDetails;
