import styled from 'styled-components';

export const Container = styled.article`
  display: flex;
  flex-direction: column;
  width: 490px;
  overflow-y: auto;
  border: 1px solid #dce3f3;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;

  /*  Small devices */
  @media (max-width: 700px) {
    width: 100%;
  }
`;

export const Details = styled.section`
  display: flex;
  flex-direction: column;
  gap: 6px;
  padding: 15px;
  font-size: 14px;
  overflow-y: auto;
  min-height: 90px;
  border-bottom: 1px solid #dce3f3;

  /*  Small devices */
  @media (max-width: 700px) {
    overflow-y: initial;
    min-height: initial;
  }
`;

export const Detail = styled.li`
  display: flex;
  flex-direction: ${({ vertical }: { vertical?: boolean }) =>
    vertical ? 'column' : 'row'};
  justify-content: space-between;
  gap: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 10px 28px;
`;

export const ListName = styled.span`
  color: #405daa;
`;

export const ListValue = styled.span`
  color: #1c3264;
  font-weight: 700;
  text-align: right;
`;

export const Locations = styled.div`
  display: flex;
`;

export const LoadsRemaining = styled.span`
  display: grid;
  place-items: center;
  background-color: #d6dced;
  color: #405daa;
  padding: 10px;
  border-radius: 3px;
  font-weight: 700;
  margin: 15px;
`;

export const BookJobSection = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
  background-color: #405daa;
  color: white;
  padding: 10px 26px;
`;

export const DetailCard = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 16px;
  flex: 1;
`;
