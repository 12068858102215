import styled from 'styled-components';

type ProfileTypeCardProps = {
  icon: React.FC;
  title: string;
  description: string;
  selected: boolean;
  onClick: () => void;
};

const Container = styled.article`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 35px 25px;
  border-radius: 3px;
  border: ${({ selected }: { selected: boolean }) =>
    selected ? '4px solid #F3C018' : '1px solid #e8ecf9'};
  background-color: #f6f7fc;
  color: #1c3264;
  box-sizing: border-box;
  font-family: Nunito, sans-serif;
  cursor: pointer;

  :hover {
    box-shadow: 2px 4px 4px 0px rgba(0, 0, 0, 0.07);
  }
`;

const Title = styled.span`
  font-size: 15px;
  font-weight: 800;
  text-align: center;
  margin-top: 15px;
`;

const Description = styled.p`
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  line-height: 1.7;
`;

const ProfileTypeCard: React.FC<ProfileTypeCardProps> = (props) => {
  const { icon: Icon, title, description, selected, onClick } = props;

  return (
    <Container selected={selected} onClick={onClick}>
      <Icon />
      <Title>{title}</Title>
      <Description>{description}</Description>
    </Container>
  );
};

export default ProfileTypeCard;
