import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { NewJob } from '../types/GoogleAddress';
import { DetailResponse, GlobalJobResponse, JobResponse } from '../types/responses/job-response';
import API from './API';
import { endpoints } from './Constants';
import { LoadsList } from '../types/responses/load-list-response';
import { UpdatedJob } from '../types/Job';

// url = gc/jobs/
const BASE_URL = endpoints.jobs.base;

export interface MyJobsResponse {
  count: number;
  jobs: JobResponse[];
  success: boolean;
  totalJobs: number;
  totalPages: number;
}

export interface QueryParamsProps {
  page: number;
  limit: number;
  search: string;
  status: string;
}

export interface JobDetailResponse {
  job: DetailResponse;
  success: boolean;
}

export interface LoadsQueryParams {
  limit: number;
  page: number;
  since: string;
  until: string;
  status: string;
}

export interface LoadsListResponse {
  success: boolean;
  totalLoads: number;
  totalPages: number;
  count: number;
  loads: LoadsList[];
}

export type GlobalJobsResponse = Omit<MyJobsResponse, 'jobs'> & {
  jobs: GlobalJobResponse[];
};

//  get the list of the jobs of the current user
export const myJobs = async (
  query: QueryParamsProps
): Promise<AxiosResponse<MyJobsResponse>> => {
  const url = `${BASE_URL}${endpoints.jobs.myJobs}`;
  const { page, limit, search, status } = query;
  let params: Record<string, unknown> = { page, limit, search };
  if (query.status !== '') {
    params = { ...params, status };
  }
  return await API.get(url, { params });
};

export const getGlobalJobs = async (
  config?: AxiosRequestConfig
): Promise<AxiosResponse<GlobalJobsResponse>> => {
  const url = `${endpoints.jobs.jobBoard}`;

  return await API.get(url, config);
};

export interface QueryParamsProps {
  page: number;
  limit: number;
  search: string;
  status: string;
}

export const createJob = async (job: NewJob): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}`, job);
};

export const getDetails = async (id: string): Promise<AxiosResponse> => {
  return await API.get(`${BASE_URL}${id}`);
};

export const update = async (
  id: string,
  job: NewJob
): Promise<AxiosResponse> => {
  return await API.put(`${BASE_URL}${id}`, job);
};

// get the details of the current job for the detail page
export const getJobDetail = async (
  id: string
): Promise<AxiosResponse<JobDetailResponse>> => {
  return await API.get(`${BASE_URL}${endpoints.jobs.detail}${id}`);
};

// update the job detail
export const HttpUpdateJobDetail = async (
  id: string,
  job: UpdatedJob
): Promise<AxiosResponse> => {
  return await API.put(`${BASE_URL}${id}`, job);
};

// get the list of loads of the current job
export const HttpGetLoadList = async (
  query: LoadsQueryParams,
  id: string
): Promise<AxiosResponse<LoadsListResponse>> => {
  const url = `${BASE_URL}${endpoints.jobs.loads}${id}`;
  const { limit, page, since, until, status } = query;
  let params: Record<string, unknown> = { page, limit };
  if (query.status !== '') {
    params = { ...params, status };
  }
  if (query.since !== '') {
    params = { ...params, since };
  }
  if (query.until !== '') {
    params = { ...params, until };
  }
  return await API.get(url, { params });
};

export const HttpUpdateJobStatus = async (
  id: string,
  status: number
): Promise<AxiosResponse> => {
  return await API.put(`${BASE_URL}${endpoints.jobs.jobStatus}${id}`, {
    status
  });
};
