import styled from 'styled-components';

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.background ? `${props.background}` : '#F7D55E;'};
  border-radius: 3px;
  border: ${(props) =>
    props.borderColor
      ? `1px solid ${props.borderColor}`
      : '1px solid #F7D55E;'};
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  color: ${(props) => (props.color ? `${props.color}` : '#16244A;')};
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: 282px;
  height: 40px;
  cursor: pointer;
  margin-top: 20px;
  transition: all 0.3s ease-out;
  :hover {
    opacity: 0.8;
  }

  @media (min-width: 300px) and (max-width: 767.98px) {
    width: 85%;
  }
`;

interface ButtonProps {
  text: string;
  handleClick: () => void;
  background?: string;
  color?: string;
  borderColor?: string;
  style?: React.CSSProperties;
}
const Button = ({
  text,
  handleClick,
  background,
  color,
  borderColor,
  style,
}: ButtonProps): JSX.Element => {
  return (
    <StyledButton
      onClick={handleClick}
      background={background}
      color={color}
      borderColor={borderColor}
      style={style}
    >
      {text}
    </StyledButton>
  );
};

export default Button;
