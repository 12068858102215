import { ProfileTypes } from '../types/ProfileTypes';
import { Roles } from '../types/Roles';

export default function getUserType(roles: number[]): string {
  if (roles.includes(Roles.TRUCKER)) {
    return ProfileTypes.TRUCKER;
  }

  if (roles.includes(Roles.GENERAL_CONTRACTOR)) {
    return ProfileTypes.GENERAL_CONTRACTOR;
  }

  return ProfileTypes.INDIVIDUAL;
}
