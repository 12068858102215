import { TicketDetailResponse, TicketLoadsResponse, TicketTruckerResponse } from './responses/ticket-detail-response';
import {
  decimalToHour, decimalToMinute,
  formatCurrency,
  formatDateOnlyPrettier,
  formatHour,
  getTruckTypesAsString,
  padNumber,
  parseNumberToLocaleString,
} from '../helpers/formats';

export class TicketDetail {
  date: string;
  id: number;
  jobSite: string;
  loads: TicketLoad[];
  status: string;
  totalAmount: string;
  totalDuration: string;
  trucker: TicketDetailTrucker;
  loadsCount: string;
  jobId: number;

  constructor(data: TicketDetailResponse) {
    this.date = formatDateOnlyPrettier(data.date);
    this.id = data.id;
    this.jobSite = data.jobSite;
    this.loads = data.loads.map((load, index) => new TicketLoad(load, index));
    this.status = data.status;
    this.totalAmount = formatCurrency(data.totalAmount);
    this.totalDuration = `${decimalToHour(data.totalDuration)} HRS / ${decimalToMinute(data.totalDuration)} MIN`;
    this.trucker = new TicketDetailTrucker(data.trucker);
    this.loadsCount = padNumber(data.loads.length, 2);
    this.jobId = data.jobId;
  }
}

export class TicketLoad {
  numberOfLoad: string;
  id: number;
  startDate: string;
  endDate: string;
  duration: string;
  distance: string;
  total: string;
  rate: string;

  constructor(data: TicketLoadsResponse, index: number) {
    this.numberOfLoad = `${padNumber((index + 1), 2)}`;
    this.id = data.id;
    this.startDate = formatHour(data.startDate);
    this.endDate = formatHour(data.endDate);
    this.duration = `${decimalToHour(data.duration)} HRS / ${decimalToMinute(data.duration)} MIN`
    this.distance = `${parseNumberToLocaleString(data.distance)} MI`;
    this.total = formatCurrency(data.total);
    this.rate = formatCurrency(data.rate);
  }
}

export class TicketDetailTrucker {
  email: string;
  name: string;
  truckType: string;
  phone: number;
  companyName: string;

  constructor(data: TicketTruckerResponse) {
    this.email = data.email;
    this.name = data.name;
    const trucksArr = data.truckTypes.map(truck => truck.truckType);
    this.truckType = getTruckTypesAsString(trucksArr);
    this.companyName = data.companyName;
    this.phone = data.phone;
  }
}


