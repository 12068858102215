import { createContext, FC, useContext, useEffect, useState } from 'react';
import { deleteToken, getToken, setToken } from '../helpers/auth-helper';
import { User } from '../types/User';
import { MyAccountData } from '../types/Account';

export type AuthContextType = {
  user?: User | null;
  onLogin: (user: User) => void;
  onLogout: () => void;
  updateUser: (user: MyAccountData) => void;
};

const AuthContext = createContext<AuthContextType | Record<string, never>>({});

export const AuthProvider: FC = (props) => {
  const { children } = props;
  const [user, setUser] = useState<User | null | undefined>();

  // handle a successful login
  const onLogin = (user: User) => {
    setUser(user);
    setToken(user);
  };

  // handle a successful logout
  const onLogout = () => {
    deleteToken();
    setUser(null);
  };


  const updateUser = ({ name, lastname: lastName, phone }: MyAccountData) => {
    const email = `${user?.email}`;
    const token = `${user?.token}`;
    const refreshToken = `${user?.refreshToken}`;
    const confirmedEmail = Boolean(user?.confirmedEmail);
    const updatedUser = {
      confirmedEmail,
      refreshToken,
      token,
      email,
      name,
      lastName,
      phone: phone.toString(),
      roles: user?.roles,
    };

    setToken(updatedUser);
    setUser(updatedUser);
  };

  const authContextValue: AuthContextType = {
    user,
    onLogin,
    onLogout,
    updateUser,
  };

  // get token from localstorage and set a current user if a token exists
  useEffect(() => {
    const token = getToken();
    if (!token) {
      setUser(null);
      return;
    } else {
      setUser(token);
    }
  }, []);

  return (
    <AuthContext.Provider value={authContextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextType | Record<string, never> => {
  return useContext(AuthContext);
};
