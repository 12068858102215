import { AvailableStatus } from '../../types/Status';
import { ArrowForwardIos, BorderColor } from '@material-ui/icons';
import styled from 'styled-components';

const ArrowRightIcon = styled(ArrowForwardIos)`
  color: #91a5da;
  font-size: 18px !important;
`;

const DraftIcon = styled(BorderColor)`
  color: #91a5da;
  font-size: 18px !important;
`;

interface JobStatusLinkProps {
  id: number;
  status: AvailableStatus;
}

const JobStatusLink = ({ status }: JobStatusLinkProps): JSX.Element => {
  const JobStatusIcon = (): JSX.Element => {
    switch (status) {
      case 'Draft':
        return <DraftIcon data-tag="allowRowEvents" titleAccess="Edit job" />;
      default:
        return <ArrowRightIcon data-tag="allowRowEvents" titleAccess="View details" />;
    }
  };

  return <JobStatusIcon data-tag="allowRowEvents" />;
};

export default JobStatusLink;
