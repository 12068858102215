import React from 'react';
// Libraries
import { RowRecord, TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';
// Components
import Layout from '../../shared/components/Layout/Layout';
import TimePicker from '../../components/TimePicker/TimePicker';
import Dropdown from '../../components/Dropdown/Dropdown';
import DataPresentation from '../../components/DataPresentation/DataPresentation';
import Table from '../../components/Table/Table';
import RegularStyleCell from '../../components/RegularStyleCell/RegularStyleCell';
import Badge from '../../components/TicketStatusBadge/TicketStatusBadge';
// Others
import useGetTickets from '../../hooks/useGetTickets';
import { padNumber } from '../../helpers/formats';
import { TicketRow } from '../../types/TicketRow';
import { usePayMultipleTicket } from '../../hooks/usePayMultipleTicket';
import MarkTicketAsPaidModal from '../../components/ConfirmationModal/MarkTicketAsPaidModal';
import { useModal } from '../../contexts/modal-context';
import useTicketStatusList from '../../hooks/useTicketStatusList';
// Styles
import {
  MainContainer,
  Card,
  Title,
  FlexRow,
  SelectTicketsRow,
  ButtonsContainer,
  TicketSelectionContainer,
  NumberOfTicketsTag,
  Button,
} from './my-tickets.styles';

const MyTicketsPage = (): JSX.Element => {
  // Get access to the route history
  const history = useHistory();
  // Confirmation context
  const { requireModal } = useModal();
  // Get the list of available ticket status from the server
  const statusList = useTicketStatusList();
  // Ticket list querying
  const ticketList = useGetTickets(statusList.list);
  // Select tickets and update their status
  const { count, handleCheckboxChange, markAsPaidCb, markAsNotPaidCb } =
    usePayMultipleTicket({
      onFinalize: ticketList.getTicketList,
    });

  // Require confirmation to mark tickets as paid
  const handleMarkAsPaid = () => {
    requireModal(<MarkTicketAsPaidModal submit={markAsPaidCb} status="paid" />);
  };
  // Require confirmation to mark tickets as not paid
  const handleMarkAsNotPaid = () => {
    requireModal(
      <MarkTicketAsPaidModal submit={markAsNotPaidCb} status="not paid" />
    );
  };
  // navigate to the load detail
  const handleRowClicked = ({ id }: RowRecord): void => {
    history.push(`/my-tickets/detail/${id}`);
  };

  // data table columns
  const columns: TableColumn<TicketRow>[] = [
    {
      name: '# Ticket',
      selector: (currentTicket: TicketRow) => currentTicket.id,
      sortable: true,
    },
    {
      name: 'Driver',
      selector: (currentTicket: TicketRow) => currentTicket.driverId,
      sortable: true,
      cell: function renderTrucker(currentTicket: TicketRow) {
        return <RegularStyleCell value={currentTicket.driver} />;
      },
    },
    {
      name: '# Loads',
      selector: (currentTicket: TicketRow) => currentTicket.loads,
      sortable: true,
      center: true,
    },
    {
      name: 'Amount',
      selector: (currentTicket: TicketRow) => currentTicket.totalAmount,
      sortable: true,
      center: true,
    },
    {
      name: 'JobSite Name',
      selector: (currentTicket: TicketRow) => currentTicket.jobSiteName,
      sortable: true,
      minWidth: '200px',
    },
    {
      name: 'Date',
      selector: (currentTicket: TicketRow) => currentTicket.date,
      sortable: true,
      cell: function renderDate(currentTicket: TicketRow) {
        return <RegularStyleCell value={currentTicket.date} />;
      },
      minWidth: '200px',
    },
    {
      name: 'Status',
      selector: (currentTicket: TicketRow) => currentTicket.status,
      sortable: true,
      cell: function renderBadge(currentTicket: TicketRow) {
        return <Badge value={currentTicket.status} />;
      },
      center: true,
    },
  ];

  return (
    <Layout>
      <MainContainer>
        <Card>
          <FlexRow>
            <Title>My ticket History</Title>
            <TimePicker
              className="my-tickets"
              startHr={ticketList.since}
              endHr={ticketList.until}
              handleStartDateChange={ticketList.handleStartDateChange}
              handleEndDateChange={ticketList.handleEndDateChange}
              startLabel="Start Date"
              endLabel="End Date"
            />
            <Dropdown
              className="my-tickets"
              value={ticketList.queryParams.status}
              handleChange={ticketList.handleSelectChange}
              options={statusList.list}
              maxWidth="100%"
              customPadding="10px 32px 10px 8px"
            />
          </FlexRow>
          <FlexRow>
            <DataPresentation
              name="Number of tickets"
              value={ticketList.generalInfo.totalTickets}
            />
            <DataPresentation
              name="Total Hours Accumulated"
              value={ticketList.generalInfo.totalHours}
            />
            <DataPresentation
              name={
                Number(ticketList.queryParams.status) === 2
                  ? 'Amount Paid'
                  : 'Total Amount'
              }
              value={
                Number(ticketList.queryParams.status) === 2
                  ? ticketList.generalInfo.totalAmountPaid
                  : ticketList.generalInfo.totalAmountDue
              }
            />
          </FlexRow>
        </Card>
        <SelectTicketsRow>
          <TicketSelectionContainer>
            <NumberOfTicketsTag>({padNumber(count, 3)})</NumberOfTicketsTag>
            Tickets Selected
          </TicketSelectionContainer>
          <ButtonsContainer>
            <Button onClick={handleMarkAsPaid} disabled={count === 0}>
              Mark as paid
            </Button>
            <Button
              onClick={handleMarkAsNotPaid}
              disabled={count === 0}
              className="secondary"
            >
              Mark as not paid
            </Button>
          </ButtonsContainer>
        </SelectTicketsRow>
        <Card className="table">
          <Table
            className="my-tickets"
            columns={columns}
            data={ticketList.tickets}
            totalRows={ticketList.totalTickets}
            paginationLimit={ticketList.queryParams.limit}
            setQueryParams={ticketList.setQueryParams}
            handleRowClicked={handleRowClicked}
            isLoading={ticketList.loading}
            tableName="ticket"
            selectableRows
            handleSelectRow={handleCheckboxChange}
          />
        </Card>
      </MainContainer>
    </Layout>
  );
};

export default MyTicketsPage;
