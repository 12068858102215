import React, { ReactNode } from 'react';
// Context
import { useModal } from '../../contexts/modal-context';
// Material UI related
import { Close } from '@material-ui/icons';
// Components
import BaseModal from '../BaseModal/BaseModal';
// styles
import {
  Container,
  CloseIconContainer,
} from './Modal.styles';


interface ModalProps {
  children: ReactNode;
}

const ConfirmationModalWrapper = ({ children }: ModalProps): JSX.Element => {
  const { close } = useModal();
  return (
      <BaseModal>
        <Container>
          <CloseIconContainer onClick={close}>
            <Close fontSize="large" />
          </CloseIconContainer>
          {children}
        </Container>
      </BaseModal>
  );
};

export default ConfirmationModalWrapper;
