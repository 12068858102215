import { AxiosResponse } from 'axios';
import API from './API';
import { endpoints } from './Constants';
import { loadDetailResponse } from '../types/responses/load-detail-response';

// url = gc/loads/
const BASE_URL = endpoints.loads.base;
const JOB_BASE = endpoints.jobs.base;

interface LoadDetail {
  load: loadDetailResponse;
  success: boolean;
}

export const getLoadDetail = async (
  id: string
): Promise<AxiosResponse<LoadDetail>> => {
  return await API.get(`${BASE_URL}${endpoints.loads.detail}${id}`);
};

export const cancelLoad = async (
  id: string,
  remarks: string
): Promise<AxiosResponse> => {
  const body = {
    remarks,
  };
  return await API.delete(`${JOB_BASE}${endpoints.loads.cancel}${id}`, {
    data: body,
  });
};