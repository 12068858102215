import Chip from '@material-ui/core/Chip';
import styled from 'styled-components';

import {
  AvailableTicketColors, AvailableTicketStatus, AvailableTicketTextColors,
} from '../../types/Status';

interface ContainerProps {
  bgcolor: string;
  textcolor: string;
}

const Container = styled(Chip)<ContainerProps>`
  background-color: ${(props) => `${props.bgcolor} !important`};
  text-transform: capitalize !important;
  font-size: 10px !important;
  font-weight: 700;
  color: ${(props) => `${props.textcolor} !important`};
  cursor: pointer !important;
  &.MuiChip-root{
    height: unset;
    width: 62px;
  }
  .MuiChip-label{
    padding: 5px 0;
  }
`;

function Badge({ value }: { value: AvailableTicketStatus }): JSX.Element {
  const color = AvailableTicketColors[value];
  const textcolor = AvailableTicketTextColors[value];

  return (
    <Container
      data-tag='allowRowEvents'
      label={<div data-tag='allowRowEvents'>{value}</div>}
      bgcolor={color}
      textcolor={textcolor}
    />
  );
}

export default Badge;
