import { Chip } from '@material-ui/core';
import styled from 'styled-components';
import { JobTypes } from '../../types/JobTypes';
import ArrowUpIcon from '../Icons/ArrowUpIcon';
import ArrowBottomIcon from '../Icons/ArrowBottomIcon';

interface WrapperProps {
  useIcon?: boolean;
}

const Wrapper = styled.div<WrapperProps>`
  .MuiChip-root {
    width: fit-content;
    height: auto;
    background-color: ${({ useIcon }) => (!!useIcon ? '#f7d55e' : '#E8ECF9')};
    padding: 0 8px;
  }

  .MuiChip-label {
    font-family: Nunito, sans-serif;
    font-size: 10px;
    font-weight: 400;
    color: #16244a;
    padding: 0;
    margin-left: ${({ useIcon }) => !!useIcon && '3px'};
  }
`;

interface BadgeProps {
  label?: string;
  type?: string;
  useIcon?: boolean;
}

const LoadTypeBadge = ({ label, type, useIcon }: BadgeProps): JSX.Element => {
  const ArrowIcon =
    JobTypes.Import === type ? (
      <ArrowUpIcon size={6} />
    ) : (
      <ArrowBottomIcon size={6} />
    );
  return (
    <Wrapper useIcon={useIcon}>
      {useIcon ? (
        <Chip label={label} icon={ArrowIcon} />
      ) : (
        <Chip label={label} />
      )}
    </Wrapper>
  );
};

export default LoadTypeBadge;
