import styled from 'styled-components';
import Pattern from '../../assets/images/b01.png';
import Banner from '../../assets/images/publicBanner02.jpg';

export const Container = styled.div`
  height: 100vh;
  display: flex;

  &.MuiGrid-container {
    flex-wrap: nowrap;
  }
`;
export const SideBar = styled.div`
  box-sizing: border-box;
  background-image: url(${Banner});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-grow: 0;
  padding: 75px 85px 50px;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const SideBarContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
export const SideBarTitle = styled.h1`
  max-width: 300px;
  margin: 0;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.5);
  line-height: 103.19%;
`;
export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const TitleImage = styled.img`
  height: auto;
  max-width: 100%;
`;
export const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right bottom;
    background-image: url(${Pattern});
    background-color: #f6f7fc;
    background-blend-mode: multiply;
    transform: scaleX(-1);
  }

  @media (max-width: 900px) {
    max-width: 100%;
    flex-basis: 100%;
  }
  @media (max-width: 600px) {
    &:before {
      background-image: none;
    }
  }
  @media (max-width: 400px) {
    padding: 75px 25px;
  }
`;

export const Title = styled.span`
  color: #16244a;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  letter-spacing: -0.015em;
  margin-bottom: 30px;
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  max-width: 375px;
  width: 100%;
  form {
    width: 100%;
  }
`;

export const Button = styled.button`
  background: #405daa;
  border-radius: 3px;
  border: none;
  font-family: Nunito, sans-serif;
  color: white;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: 100%;
  max-width: 285px;
  height: 40px;
  margin-top: 56px;
  cursor: pointer;

  :disabled {
    background: #91a5da;
    cursor: auto;
  }
`;

export const PlainTextButton = styled.a`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  text-decoration-line: underline;
  color: #1c3264;
  margin-top: 30px;
  cursor: pointer;
`;
export const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
