import React, { MouseEvent, useMemo, useState } from 'react';
import styled from 'styled-components';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import MuiMenu from '@mui/material/Menu';
import CheckIcon from '@mui/icons-material/Check';
import { useModal } from '../../contexts/modal-context';
import MarkTicketAsPaidModal from '../ConfirmationModal/MarkTicketAsPaidModal';
import { usePayMultipleTicket } from '../../hooks/usePayMultipleTicket';


const Button = styled(button)`
  &.MuiButton-root {
    width: 130px;
    padding: 6px 13px;
    font-family: Nunito, sans-serif;
    font-size: 11px;
    font-weight: 700;
    background: #FF537C;
    border-radius: 3px;
    color: #FFF;
    letter-spacing: 0.06em;
    justify-content: space-between;

    &:hover {
      background: #F24F76;
    }
  }

  .MuiButton-endIcon {
    position: absolute;
    right: 4px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 31px;
    background: #F24F76;
    border-radius: 3px;
  }

  // Styles when the state is paid
  &.Paid.MuiButton-root {
    background: #6CBD2C !important;
    &:hover{
      background: #62b222 !important;
    }
  }
  &.Paid .MuiButton-endIcon {
    background: #62b222;
  }
`;

const Menu = styled(MuiMenu)`
  .MuiMenu-paper {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06) !important;
    border-radius: 1px;
  }

  .MuiMenu-list {
    padding: 0;
    display: flex;
    flex-direction: column;
    min-width: 130px;
  }
  
  .MuiMenuItem-root {
    padding: 9px;
    font-family: Nunito, sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: 15px;
    color: #16244A;

    &:hover {
      background: #f6f7fc;
    }
  }
  
  .MuiSvgIcon-root {
    color: #82C01D;
    margin-left: auto;
  }
`;



interface Props {
  ticketId: string;
  status?: string;
  onFinalize: () => void;
}

const PayTicketButton = ({ ticketId, status, onFinalize }: Props): JSX.Element => {
// modal handler
  const { requireModal } = useModal();
  // defines if the menu should open or close
  const [isOpen, setIsOpen] = useState(false);
  // reference element for the popover menu
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const oppositeStatus = useMemo(() => {
    if (status === 'Not paid') return 'Paid' 
    return 'Not paid';
  }, [status]);

  // Get the callbacks to update ticket status
  const { markAsPaidCb, markAsNotPaidCb } = usePayMultipleTicket({
    ticketId: Number(ticketId),
    onFinalize
  });

  // Require confirmation to mark tickets as not paid
  const updateTicket = () => {
    closeMenu();
    if (status === 'Not paid') {
      requireModal(<MarkTicketAsPaidModal submit={markAsPaidCb} status="paid" />);
    } else if (status === 'Paid') {
      requireModal(<MarkTicketAsPaidModal submit={markAsNotPaidCb} status="not paid" />);
    }
  };

  const openMenu = (event: MouseEvent) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };
  const closeMenu = () => {
    setAnchorEl(null);
    setIsOpen(false);
  };

  return (
    <>
      <Menu
        id='button-menu'
        anchorEl={anchorEl}
        open={isOpen}
        onClose={closeMenu}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem onClick={updateTicket}>
          Mark as {oppositeStatus === 'Not paid' ? 'unpaid' : 'paid'}
          <CheckIcon />
        </MenuItem>
      </Menu>
      <Button
        className={status}
        variant='contained'
        disableElevation
        disableRipple
        endIcon={<KeyboardArrowDownIcon fontSize='small' />}
        onClick={openMenu}
      >
        {status}
      </Button>
    </>
  );
};

export default PayTicketButton;
