import { FC } from 'react';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { useParams, RouteComponentProps } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';

import { resetPassword } from '../../api/AuthenticationAPI';
import { useNotification } from '../../contexts/notification-context';
import TruckCaddyLogo from '../../assets/images/logo.png';
import Banner from '../../assets/images/publicBanner02.jpg';
import OnBoardingTextField from '../../components/OnboardingTextField/OnBoardingTextField';
import Pattern from '../../assets/images/b01.png';

const Container = styled(Grid)`
  height: 100vh;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  form {
    width: 100%;
  }
`;
const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 375px;
  width: 100%;
`;
const MainContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 0;
  max-width: 66.666667%;
  flex-basis: 66.666667%;
  padding: 75px 25px;

  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-size: auto;
    background-position: right bottom;
    background-image: url(${Pattern});
    background-color: #f6f7fc;
    background-blend-mode: multiply;
    transform: scaleX(-1);
  }

  @media (max-width: 900px) {
    max-width: 100%;
    flex-basis: 100%;
  }
  @media (max-width: 600px) {
    &:before {
      background-image: none;
    }
  }
`;
const SideBar = styled.div`
  box-sizing: border-box;
  background-image: url(${Banner});
  background-size: 100% 100%;
  background-repeat: no-repeat;
  flex-grow: 0;
  padding: 75px 85px 50px;
  max-width: 33.333333%;
  flex-basis: 33.333333%;
  @media (max-width: 900px) {
    display: none;
  }
`;
const SideBarContent = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;
const SideBarTitle = styled.h1`
  max-width: 300px;
  margin: 0;
  text-align: center;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  font-size: 48px;
  letter-spacing: -0.03em;
  color: rgba(255, 255, 255, 0.5);
  line-height: 103.19%;
`;
const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
const TitleImage = styled.img`
  height: auto;
  max-width: 100%;
`;
const Title = styled.span`
  color: #16244a;
  font-family: Cabin, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  letter-spacing: -0.015em;
  margin-bottom: 30px;
`;

const Subtitle = styled.span`
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color: #91a5da;
  margin-bottom: 30px;
`;

const Button = styled.button`
  background: #405daa;
  border-radius: 3px;
  border: none;
  font-family: Nunito, sans-serif;
  color: white;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: 282px;
  height: 40px;
  margin-top: 56px;
  cursor: pointer;

  :disabled {
    background: #91a5da;
    cursor: auto;
  }
`;

interface UrlParams {
  token: string;
}

const ResetPasswordPage: FC<RouteComponentProps> = ({ history }) => {
  // get token from the url params
  const { token }: UrlParams = useParams();
  // get method to be able to show alerts
  const { setNotification } = useNotification();

  // Send a mail to the email address entered to reset the user's password.
  const handleSubmit = (
    values: { password: string; passwordConfirm: string },
    {
      setSubmitting,
    }: FormikHelpers<{ password: string; passwordConfirm: string }>
  ): void => {
    const { password } = values;
    resetPassword(password, token)
      .then(({ data }) => {
        if (data.success) {
          setSubmitting(false);
          setNotification({ type: 'success', message: data.msg, open: true });
          history.push('/login');
        }
      })
      .catch(({ response }) => {
        setSubmitting(false);
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      });
  };

  // validations object for the reset pass form
  const forgotPassValidations = Yup.object({
    password: Yup.string().required('Required'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('password'), null], "Passwords don't match")
      .required('Required'),
  });

  return (
    <Container container>
      <MainContainer>
        <FormContainer>
          <Title>Create new password</Title>
          <Subtitle>
            Create a new password to access your account.
          </Subtitle>
          <Formik
            initialValues={{ password: '', passwordConfirm: '' }}
            validationSchema={forgotPassValidations}
            onSubmit={handleSubmit}
          >
            {({ values, isSubmitting }) => (
              <Form>
                <FieldsContainer>
                  <OnBoardingTextField
                    name="password"
                    type="password"
                    label="New Password"
                  />
                  <OnBoardingTextField
                    name="passwordConfirm"
                    type="password"
                    label="Confirm Password"
                  />
                </FieldsContainer>
                {!isSubmitting ? (
                  <Button
                    disabled={!values.password || !values.passwordConfirm}
                    type="submit"
                  >
                    Save
                  </Button>
                ) : (
                  <>
                    <div style={{ marginTop: '56px' }} />
                    <CircularProgress disableShrink />
                  </>
                )}
              </Form>
            )}
          </Formik>
        </FormContainer>
      </MainContainer>
      <SideBar>
        <SideBarContent>
          <SideBarTitle>The safest way for you</SideBarTitle>
          <LogoContainer>
            <TitleImage src={TruckCaddyLogo} />
          </LogoContainer>
        </SideBarContent>
      </SideBar>
    </Container>
  );
};

export default ResetPasswordPage;
