import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { httpGetTicketList, TicketsQueryParams } from '../api/TicketsAPI';
import { useNotification } from '../contexts/notification-context';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { formatDate } from '../helpers/formats';
import {  TicketRow, TicketGeneralInfo } from '../types/TicketRow';

interface returnType {
  loading: boolean;
  since: MaterialUiPickersDate;
  until: MaterialUiPickersDate;
  queryParams: TicketsQueryParams;
  setQueryParams: Dispatch<SetStateAction<TicketsQueryParams>>;
  handleStartDateChange: (date: MaterialUiPickersDate) => void;
  handleEndDateChange: (date: MaterialUiPickersDate) => void;
  handleSelectChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
  tickets: TicketRow[] | [];
  generalInfo: TicketGeneralInfo;
  totalTickets: number;
  getTicketList: () => void;
}

export default function useGetTickets(ticketStatusList: { id: string; name: string }[]): returnType {
  // notifications handler
  const { setNotification } = useNotification();

  const [loading, setLoading] = useState(false);

  const [totalTickets, setTotalTickets] = useState(0);
  const [tickets, setTickets] = useState<TicketRow[] | []>([]);
  const [generalInfo, setGeneralInfo] = useState({
    totalAmountDue: '',
    totalAmountPaid: '',
    totalHours: '',
    totalTickets: '',
  });

  const [since, setSince] = useState<MaterialUiPickersDate>(null);
  const [until, setUntil] = useState<MaterialUiPickersDate>(null);
  const [queryParams, setQueryParams] = useState<TicketsQueryParams>({
    page: 1,
    limit: 10,
    since: '',
    until: '',
    status: '',
  });

  // Make the request to obtain the tickets list
  const getTicketList = useCallback(() => {
    setLoading(true);
    httpGetTicketList(queryParams)
      .then(({ data }) => {
        if (data.success) {
          // Set the ticket rows
          const ticketsList = data.data.tickets.map((ticket) => new TicketRow(ticket));
          setTickets(ticketsList);
          // Set the tickets general info
          const formattedGeneralInfo = new TicketGeneralInfo(data.data.generalInfo);
          setGeneralInfo(formattedGeneralInfo);
          setTotalTickets(data.data.generalInfo.totalTickets);
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => setLoading(false));
  }, [queryParams, setNotification]);

  useEffect(() => {
    getTicketList();
  }, [getTicketList]);

  const handleEndDateChange = (date: MaterialUiPickersDate): void => {
    if (!!date) {
      setUntil(date);
      const formattedDate = formatDate(date?.toString());
      setQueryParams({ ...queryParams, until: formattedDate });
    }
  };

  const handleStartDateChange = (date: MaterialUiPickersDate): void => {
    if (!!date) {
      setSince(date);
      const formattedDate = formatDate(date?.toString());
      setQueryParams({ ...queryParams, since: formattedDate });
    }
  };

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const statusSelected = event.target.value;
    const lastStatusID = ticketStatusList.length;
    if (statusSelected === lastStatusID) {
      setQueryParams({ ...queryParams, status: '', page: 1 });
    } else setQueryParams({ ...queryParams, status: statusSelected as string, page: 1 });
  };


  return {
    tickets,
    generalInfo,
    loading,
    since,
    until,
    handleEndDateChange,
    handleStartDateChange,
    handleSelectChange,
    queryParams,
    setQueryParams,
    totalTickets,
    getTicketList
  };
}