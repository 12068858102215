import React from 'react';
import { Switch } from 'react-router-dom';
import Route from './Route';

import HomePage from '../pages/Home/home-page.component';
import LoginPage from './../pages/Login/login-page.component';
import AdminsLoginPage from '../pages/Login/admins-login';
import ForgotPasswordPage from '../pages/ForgotPassword/forgot-password-page';
import ResetPasswordPage from '../pages/ResetPassword/reset-password-page';
import OnBoardingPage from '../pages/OnBoarding/onboarding-page';
import VerificationPage from '../pages/Verification/verification-page';
import BillingInfoPage from '../pages/BillingInfo/billing-info-page';
import MyJobsPage from './../pages/MyJobs/my-jobs-page';
import JobFormPage from './../pages/JobForm/job-form-page';
import JobDetailPage from '../pages/JobDetail/job-detail-page';
import MyAccountPage from '../pages/MyAccount/my-account-page';
import LoadDetailPage from '../pages/LoadDetail/load-detail-page';
import MyTicketsPage from '../pages/MyTickets/my-tickets-page';
import TicketDetailPage from '../pages/TicketDetail/ticket-detail-page';
import JobBoard from '../pages/JobBoard/job-board';
import Users from '../pages/Users/users';

export default function Routes(): JSX.Element {
  return (
    <Switch>
      <Route path='/' exact component={HomePage} />
      <Route path='/login' exact component={LoginPage} />
      <Route path='/administrator/login' exact component={AdminsLoginPage} />
      <Route path='/register' exact component={OnBoardingPage} />
      <Route path='/forgot-password' exact component={ForgotPasswordPage} />
      <Route path='/confirm-email/:token' exact component={VerificationPage} />
      <Route
        path='/reset-password/:token'
        exact
        component={ResetPasswordPage}
      />
      <Route
        path={['/administrator/job-board', '/job-board']}
        exact
        component={JobBoard}
        isPrivate
      />
      <Route path='/my-jobs' exact component={MyJobsPage} isPrivate />
      <Route
        path='/load-details/:id'
        exact
        component={LoadDetailPage}
        isPrivate
      />
      <Route
        path={['/administrator/my-account', '/my-account']}
        exact
        component={MyAccountPage}
        isPrivate
      />
      <Route
        path='/job-details/:id'
        exact
        component={JobDetailPage}
        isPrivate
      />
      <Route path='/my-tickets' exact component={MyTicketsPage} isPrivate />
      <Route
        path='/my-tickets/detail/:id'
        exact
        component={TicketDetailPage}
        isPrivate
      />
      <Route path='/create-job' exact component={JobFormPage} isPrivate />
      <Route path='/edit-job/:id' exact component={JobFormPage} isPrivate />
      <Route path='/billing-info' exact component={BillingInfoPage} isPrivate />
      <Route path='/administrator/users' component={Users} exact isPrivate />
      {/* redirect user to Login page if route does not exist and user is not authenticated */}
      <Route component={HomePage} />
    </Switch>
  );
}
