import styled from 'styled-components';
import { Formik, Form, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Dispatch, SetStateAction } from 'react';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import PlainTextButton from '../PlainTextButton/PlainTextButton';
import { UserProfile } from '../../types/User';
import OnBoardingTextField from '../OnboardingTextField/OnBoardingTextField';

export const Button = styled.button`
  background: #f7d55e;
  border-radius: 3px;
  border: none;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  color: #16244a;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: 100%;
  max-width: 176px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  :disabled {
    background: #fdf2ca;
    cursor: auto;
  }
`;

const Options = styled.div`
display: flex; 
  justify-content: space-between;
  align-items: flex-end;
  margin: 56px 0 15px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 25px;
  column-gap: 10px;
  @media(max-width: 600px){
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
`;

interface PersonalInfoProps {
  user: UserProfile;
  updateUser: Dispatch<SetStateAction<UserProfile>>;
  nextStep: () => void;
}

const PersonalInfo = ({
  user,
  updateUser,
  nextStep,
}: PersonalInfoProps): JSX.Element => {
  const history = useHistory();

  /**
   * update the user state and change to the next step
   * @param {FormikValues} values form fields values.
   */
  const handleSubmit = (values: FormikValues): void => {
    updateUser({ ...user, ...values });
    nextStep();
  };

  const digitsOnly = (value) => /^\d+$/.test(value)

  // validations object for the login form
  const validations = Yup.object({
    name: Yup.string().trim().matches(/^[a-zA-Z ]*$/,'Only characters allowed').required('Required'),
    lastname: Yup.string().trim().matches(/^[a-zA-Z ]*$/,'Only characters allowed').required('Required'),
    phone: Yup.string().required('Required').test('Digits only', 'Invalid phone number', digitsOnly).max(10, 'Ten digits maximum'),
    email: Yup.string().email('Invalid email address').required('Required'),
  });

  return (
    <>
      <Formik
        initialValues={user}
        validationSchema={validations}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isValid }) => (
          <Form>
            <Grid>
                <OnBoardingTextField label='Name' name='name' type='text'/>
                <OnBoardingTextField label='Last Name' name='lastname' type='text'/>
                <OnBoardingTextField label='Email Address' name='email' type='email'/>
                <OnBoardingTextField label='Mobile phone number' name='phone' type='text'   />
            </Grid>
            <Options>
              <PlainTextButton
                text='CANCEL'
                handleClick={() => history.push('/')}
              />
              <Button disabled={!isValid} type='submit'>
                Next
                <ChevronRightIcon />
              </Button>
            </Options>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PersonalInfo;
