import React from 'react';
import styled from 'styled-components';
import Backdrop from './Backdrop';
import { useModal } from '../../contexts/modal-context';
import { Modal } from '@material-ui/core';

export const Wrapper = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  outline: none;
  padding: 0 5px;
  display: flex;
  justify-content: center;
`;

interface BaseModalProps {
  children: React.ReactNode;
  style?: React.CSSProperties;
  useBackdrop?: boolean;
}

const BaseModal = ({ children, style, useBackdrop = true }: BaseModalProps): JSX.Element => {
  const { close, isOpen } = useModal();
  
  return (
    <Modal
      open={isOpen}
      BackdropComponent={useBackdrop ? Backdrop : undefined}
      onClose={close}
      style={style}
    >
      <Wrapper>{children}</Wrapper>
    </Modal>
  );
};

export default BaseModal;
