import React, { createContext, useContext, useState } from 'react';

type ModalContextType = {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  requireModal: (modal: JSX.Element) => void;
};

const ModalContext = createContext<ModalContextType | Record<string, never>>({});

interface ProviderProps {
  children: JSX.Element;
}

export const ModalProvider = ({
                                children,
                              }: ProviderProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const [modal, setModal] = useState<JSX.Element>();

  const open = () => {
    setIsOpen(true);
  };

  const close = () => {
    setIsOpen(false);
    setModal(undefined);
  };

  const requireModal = (modal: JSX.Element) => {
    setModal(modal);
    setIsOpen(true);
  };

  return (
    <ModalContext.Provider
      value={{
        open,
        close,
        isOpen,
        requireModal: requireModal,
      }}
    >
      {children}
      {isOpen ? <>{ modal }</> : null}
    </ModalContext.Provider>
  );
};

export const useModal = ():
  | ModalContextType
  | Record<string, never> => {
  return useContext(ModalContext);
};
