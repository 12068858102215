import styled from 'styled-components';
import SearchIcon from '@material-ui/icons/Search';

interface WrapperProps {
  maxWidth?: string;
  backgroundColor?: string;
  borderColor?: string;
  noMargin?: boolean;
}

const SearchWrapper = styled.div`
  box-sizing: border-box;
  margin-top: ${({ noMargin }: WrapperProps) => (noMargin ? 0 : '10px')};
  background: ${({ backgroundColor }: WrapperProps) =>
    !backgroundColor ? '#e8ecf9' : backgroundColor};
  border: ${({ borderColor }: WrapperProps) =>
    `1px solid ${borderColor ?? '#e8ecf9'}`};
  padding: 9px 8px;
  width: 100%;
  max-width: ${({ maxWidth }: WrapperProps) => maxWidth || '356px'};
  border-radius: 3px;
  display: flex;
  flex-direction: row;

  /*  Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    max-width: 100%;
  }
`;

interface SearchProps {
  borderColor?: string;
}

const Search = styled.input`
  background: inherit;
  border: 1px solid #e8ecf9;
  border: ${({ borderColor }: SearchProps) =>
    !borderColor ? '1px solid #e8ecf9' : '1px solid ' + borderColor};
  box-sizing: border-box;
  border-radius: 3px;
  color: #1c3264;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 13px;
  width: 100%;

  ::placeholder {
    color: #1c3264;
  }

  :focus {
    outline: none;
  }

  /*  Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const Icon = styled(SearchIcon)`
  color: #91a5da;
`;

interface SearchInputProps {
  placeholder: string;
  value: string | undefined;
  disabled?: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyPress?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  maxWidth?: string;
  backgroundColor?: string;
  borderColor?: string;
  noMargin?: boolean;
}

function SearchInput({
  placeholder,
  value,
  disabled,
  onChange,
  onKeyPress,
  maxWidth,
  backgroundColor,
  borderColor,
  noMargin
}: SearchInputProps): JSX.Element {
  return (
    <SearchWrapper
      maxWidth={maxWidth}
      backgroundColor={backgroundColor}
      className='search-input-wrapper'
      borderColor={borderColor}
      noMargin={noMargin}
    >
      <Icon fontSize='small' />
      <Search
        disabled={disabled}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        borderColor={backgroundColor}
      />
    </SearchWrapper>
  );
}

export default SearchInput;
