import React from 'react';

// Context
import { useModal } from '../../contexts/modal-context';
// styles
import {
  Title,
  ButtonContainer,
  ConfirmButton,
  DenyButton,
  Separator,
} from './Modal.styles';
import ConfirmationModalWrapper from './ConfirmationModalWrapper';

interface ModalProps {
  submit: () => void;
}

function UpdateMyAccountModal({ submit }: ModalProps): JSX.Element {
  const { close } = useModal();
  return (
    <ConfirmationModalWrapper>
      <Title>Are you sure you want update your personal information?</Title>
      <Separator />
      <ButtonContainer>
        <ConfirmButton type="button" onClick={submit}>
          Yes
        </ConfirmButton>
        <DenyButton type="button" onClick={close}>
          No
        </DenyButton>
      </ButtonContainer>
    </ConfirmationModalWrapper>
  );
}

export default UpdateMyAccountModal;