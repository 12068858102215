import { AuthProvider } from './contexts/auth-context';
import { NotificationProvider } from './contexts/notification-context';
import { ModalProvider } from './contexts/modal-context';
import Routes from './routes';

function App(): JSX.Element {
  return (
    <AuthProvider>
      <NotificationProvider>
        <ModalProvider>
          <Routes />
        </ModalProvider>
      </NotificationProvider>
    </AuthProvider>
  );
}

export default App;
