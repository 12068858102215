import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { CircularProgress } from '@material-ui/core';

import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import MenuItem from '@material-ui/core/MenuItem';


import PlainTextButton from '../PlainTextButton/PlainTextButton';
import { UserProfile } from '../../types/User';
import { register } from '../../api/AuthenticationAPI';
import { useNotification } from '../../contexts/notification-context';
import { statesByCountryId } from '../../api/ListsAPI';
import { State } from '../../types/Lists';
import { FormikHelpers } from 'formik/dist/types';
import OnBoardingTextField from '../OnboardingTextField/OnBoardingTextField';
import { ProfileTypes } from '../../types/ProfileTypes';

import { getRoles } from '../../api/ListsAPI';
import { Role } from '../../types/Lists';


const Button = styled.button`
  background: #f7d55e;
  border-radius: 3px;
  border: none;
  font-family: Nunito, sans-serif;
  font-weight: bold;
  color: #16244a;
  font-size: 16px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.06em;
  text-transform: uppercase;
  width: 100%;
  max-width: 176px;
  height: 40px;
  cursor: pointer;
  display: flex;
  justify-content: center;

  :disabled {
    background: #fdf2ca;
    cursor: auto;
  }
`;

const PrevButton = styled(Button)`
  background: #f6f7fc;
  cursor: pointer;
  margin-right: 7px;

  display: flex;
  justify-content: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, auto);
  row-gap: 25px;
  column-gap: 10px;
  @media (max-width: 600px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, auto);
  }
`;

const Options = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 56px 0 15px;
`;

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
`;


interface PersonalInfoProps {
  user: UserProfile;
  selectedType: ProfileTypes;
  updateUser: Dispatch<SetStateAction<UserProfile>>;
  nextStep: () => void;
  prevStep: () => void;
}

const AditionalInfo = ({
 user,
 selectedType,
 updateUser,
 nextStep,
 prevStep,
}: PersonalInfoProps): JSX.Element => {
  const history = useHistory();
  const { setNotification } = useNotification();
  const [states, setStates] = useState<State[]>([]);
  const [stateSelected, setStateSelected] = useState<State>();

  const [roles, setRoles] = useState<Role[]>([]);

  // get the list of states based on the country id, by default 1 (usa)
  const getStates = useCallback(
    (countryId = 1) => {
      statesByCountryId(countryId)
        .then(({ data }) => {
          if (data.success) {
            setStates([...data.states]);
          }
        })
        .catch(({ response }) => {
          setNotification({
            type: 'error',
            message: response.data.msg,
            open: true,
          });
        });
    },
    [setNotification],
  );

  const getRolesList = useCallback(
    () => {
      getRoles()
        .then(({ data }) => {
          if (data.success) {
            setRoles([...data.roles]);
          }
        })
        .catch(({ response }) => {
          setNotification({
            type: 'error',
            message: response.data.msg,
            open: true,
          });
        });
    },
    [setNotification],
  );

  useEffect(() => {
    getStates();
  }, [getStates]);

  useEffect(() => {
    getRolesList();
  }, [getRolesList]);        

  /**
   * update the user state and complete the registration process
   * @param {FormikValues} values form fields values.
   */
  const handleSubmit = (
    values: UserProfile,
    { setSubmitting }: FormikHelpers<UserProfile>,
  ): void => {
    const { companyName, password, confirmPassword } = values;
    let location;

    if (stateSelected) {
      location = {
        state: stateSelected.name,
        country: 'USA',
        stateAbbr: stateSelected.abbreviation,
      };
    }
    const newUser = {
      ...user,
      companyName,
      password,
      confirmPassword,
      location,
    };

    let selectedRole = null;
    switch (selectedType) {
      case ProfileTypes.GENERAL_CONTRACTOR:
        selectedRole = roles.filter(role => role.name === 'GeneralContractor');
        break;
      case ProfileTypes.TRUCKER:
        selectedRole = roles.filter(role => role.name === 'Trucker');
        break;
      case ProfileTypes.INDIVIDUAL:
        selectedRole = roles.filter(role => role.name === 'Individual');
        break;
    } 

    newUser.userType = selectedRole[0].id;

    updateUser(newUser);
    register(newUser)
      .then(({ data }) => {
        if (data.success) {
          setSubmitting(false);
          const message = 'Registration successful';
          setNotification({ type: 'success', message, open: true });
          nextStep();
        }
      })
      .catch(({ response }) => {
        setSubmitting(false);
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      });
  };

  // get back to the previous step
  const handlePrev = () => {
    prevStep();
  };

  // validations object for the login form
  const validations = Yup.object({
    companyName: Yup.string().trim().required('Required'),
    companyCountry: Yup.string().trim().required('Required'),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords don\'t match')
      .required('Required'),
  });


  return (
    <>
      <Formik
        initialValues={user}
        validationSchema={validations}
        enableReinitialize
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form>
            <Grid>
              <OnBoardingTextField label='Company Name' name='companyName' type='text' />
              <OnBoardingTextField
                label="Company headquarters state"
                name="companyCountry"
                number="text"
                select
                hideIcon
                type="select"
              >
                {states.map((state) => (
                  <MenuItem
                    key={state.id}
                    value={state.id}
                    onClick={() => setStateSelected(state)}
                  >
                    {state.name}
                  </MenuItem>
                ))}
              </OnBoardingTextField>
              <OnBoardingTextField label='Password' name='password' type='password' />
              <OnBoardingTextField label='Repeat password' name='confirmPassword' type='password' />

            </Grid>
            <Options>
              <PlainTextButton
                text='CANCEL'
                handleClick={() => history.push('/')}
              />
              <ButtonsContainer>
                <PrevButton type='button' onClick={handlePrev}>
                  <ChevronLeftIcon />
                  Prev
                </PrevButton>
                {!isSubmitting ? (
                  <Button disabled={!isValid} type='submit'>
                    Next
                    <ChevronRightIcon />
                  </Button>
                ) : (
                  <>
                    <div style={{ marginTop: '56px' }} />
                    <CircularProgress disableShrink />
                  </>
                )}
              </ButtonsContainer>
            </Options>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AditionalInfo;
