import { useEffect, useState } from 'react';

import {
  Map,
  Marker,
  GoogleApiWrapper,
  InfoWindow,
  GoogleAPI,
} from 'google-maps-react';

import CustomMarker from '../../assets/images/circle-solid.svg';
import { formatHourWithSeconds } from '../../helpers/formats';
import styled from 'styled-components';
import { Tracks } from '../../types/LoadDetail';
import jobSideMarker from '../../assets/images/jobSideMarker.svg';
import locationMarker from '../../assets/images/locationMarker.svg';
import useMarkers from '../../hooks/useMarkers';

const Wrapper = styled.div`
  .gm-style-iw-c {
    padding: 14px 18px !important;
  }

  .gm-style-iw-d {
    overflow: auto !important;
  }

  .gm-style-iw-d p {
    margin: 0;
    font-family: Nunito, sans-serif;
    font-size: 16px;
    font-weight: 700;
  }
`;

export interface LatLng {
  lat?: number;
  lng?: number;
}

interface MapContainerProps {
  tracking: Tracks[];
  google: GoogleAPI;
  jobSite: LatLng;
  location: LatLng;
}

const MapContainer = ({
  tracking,
  google,
  jobSite,
  location,
}: MapContainerProps) => {
  const { showInfo, activeMarker, onMarkerClick, handleClose } = useMarkers();
  const [bounds, setBounds] = useState<google.maps.LatLngBounds>();

  // Generate te bounds to set the initial zoom and position
  useEffect(() => {
    const InitialBounds = new google.maps.LatLngBounds();
    InitialBounds.extend({
      lat: jobSite.lat || 0,
      lng: jobSite.lng || 0,
    });
    InitialBounds.extend({
      lat: location.lat || 0,
      lng: location.lng || 0,
    });
    setBounds(InitialBounds);
  }, [
    google.maps.LatLngBounds,
    jobSite.lat,
    jobSite.lng,
    location.lat,
    location.lng,
  ]);


  // generate the center to position the map based on the jobSite
  const initialCenter = {
    lat: jobSite.lat,
    lng: jobSite.lng,
  };

  // Generate markers for each tracking point
  const middleMarkers = tracking.map((track, index) => {
    const date = formatHourWithSeconds(`${new Date()}`).toLocaleLowerCase();
    if (index !== tracking.length - 1) {
      const position = {
        lat: track.position[0],
        lng: track.position[1],
      };
      return (
        <Marker
          onClick={onMarkerClick}
          key={index}
          value={date}
          position={position}
          icon={{
            url: CustomMarker,
            anchor: new google.maps.Point(12, 12),
            scaledSize: new google.maps.Size(24, 24),
          }}
        />
      );
    }
  });


  return (
    <Wrapper>
      <Map
        google={google}
        bounds={bounds}
        initialCenter={initialCenter}
      >
        <InfoWindow
          visible={showInfo}
          marker={activeMarker}
          onClose={handleClose}
        >
          <p>{activeMarker?.value}</p>
        </InfoWindow>

        <Marker
          onClick={onMarkerClick}
          value="JobSite"
          position={{
            lat: jobSite.lat as number,
            lng: jobSite.lng as number,
          }}
          icon={{
            url: jobSideMarker,
            anchor: new google.maps.Point(18, 36),
            scaledSize: new google.maps.Size(36, 36),
          }}
        />
        <Marker
          onClick={onMarkerClick}
          value="Pickup/Dropoff"
          position={{
            lat: location.lat as number,
            lng: location.lng as number,
          }}
          icon={{
            url: locationMarker,
            anchor: new google.maps.Point(18, 36),
            scaledSize: new google.maps.Size(36, 36),
          }}
        />
        {middleMarkers}
      </Map>
    </Wrapper>
  );
};

export default GoogleApiWrapper(() => ({
  apiKey: 'AIzaSyBG7dgqYVoOnxtU6doKTM_iWqmfwXQUry4',
  language: 'en',
}))(MapContainer);