import styled from 'styled-components';
import { ArrowBackIos } from '@material-ui/icons';

interface MainContainerProps {
  isCancelButtonShowing: boolean;
}

export const MainContainer = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  border: 0.5px solid #c2cdea;
  margin-top: ${({ isCancelButtonShowing }: MainContainerProps) =>
    !!isCancelButtonShowing ? '0' : '20px'};
  margin-bottom: 20px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

interface RowProps {
  showBorder?: boolean;
  borderBottom?: boolean;
}

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  white-space: normal;

  & > div:nth-child(2) {
    border-left: ${({ showBorder }: RowProps) =>
      showBorder ? '0.5px solid #C2CDEA;' : 'none'};
    border-right: ${({ showBorder }: RowProps) =>
      showBorder ? '0.5px solid #C2CDEA;' : 'none'};
    /*  Small devices (landscape phones, 690px and up) */
    @media (max-width: 690px) {
      flex-direction: column;
      border-left: none;
      border-right: none;
    }
  }

  & > div {
    border-bottom: ${({ borderBottom }: RowProps) =>
      borderBottom && '0.5px solid #C2CDEA'};
    flex: 1;
  }

  /*  Small devices (landscape phones, 690px and up) */
  @media (max-width: 690px) {
    flex-direction: column;
  }
`;
export const MapRow = styled(Row)`
  height: 339px;
`;

interface CellProps {
  smaller?: boolean;
  backgroundColor?: string;
}

export const Cell = styled.div`
  display: flex;
  align-items: center;
  padding: ${({ smaller }: CellProps) => (smaller ? '8px 35px' : '13px 35px')};
  background: ${({ backgroundColor }: CellProps) =>
    !!backgroundColor ? backgroundColor : '#E8ECF97A'};

  &.remove-pt {
    padding: ${({ smaller }: CellProps) =>
      smaller ? '8px 35px' : '0 35px 13px 35px'};
  }

  /*  Small devices (landscape phones, 690px and up) */
  @media (max-width: 690px) {
    justify-content: center;
  }
`;

export const DataContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  color: #16244a;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: 12px;
  /*  Small devices (landscape phones, 690px and up) */
  @media (max-width: 690px) {
    justify-content: space-between;
  }
`;

export const DetailData = styled(DataContainer)`
  justify-content: space-between;
`;

export const HeaderData = styled(DataContainer)`
  color: #405daa;
  white-space: nowrap !important;

  & > span:last-child,
  div {
    margin-left: 5px;
  }

  & > span:last-child {
    font-family: Cabin, sans-serif;
    font-size: 16px;
    color: #16244a;
  }

  .padTop {
    padding-top: 3px;
  }

  & .MuiChip-root {
    height: auto !important;
    padding-top: 2px;
    padding-bottom: 2px;

    .MuiChip-label {
      font-family: Nunito, sans-serif;
      font-weight: 700;
      font-size: 11px;
    }
  }
`;

export const RegularText = styled.span``;

export const HighlightText = styled.span`
  font-weight: 700;
  font-size: 13px;
  text-transform: capitalize;
`;

export const ArrowLeftIcon = styled(ArrowBackIos)`
  color: #16244a;
  font-size: 12px !important;
  cursor: pointer;
  /*  Small devices (landscape phones, 690px and up) */
  @media (max-width: 690px) {
    display: none !important;
  }
`;

export const MapPlaceHolder = styled.div`
  width: 100%;
  height: 100%;
  background: #a9d8fd;
  position: relative;
`;

export const NotesContainer = styled.div`
  width: 100%;
  font-family: Nunito, sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;

  &.cancellation {
    color: #ff537c;
  }
`;

export const Comments = styled.div`
  height: 90px;
  margin-top: 12px;
  background: #fdfefe;
  border: 0.5px solid #c2cdea;
  padding: 10px;
  font-size: 13px;
  font-weight: 400;
  font-family: Nunito, sans-serif;
  color: #91a5da;

  &.mb {
    margin-bottom: 32px;
  }
`;

export const CancellationMotive = styled(Comments)`
  margin-bottom: 32px;
  color: #16244a;
`;

export const CancelLoad = styled.button`
  width: 82px;
  padding: 3px 12px;
  background: rgba(255, 83, 124, 0.15);
  font-family: Nunito, sans-serif;
  font-weight: 700;
  font-size: 10px;
  text-align: center;
  color: #db2e58;
  outline: none;
  border: none;
  border-radius: 1px;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: rgba(196, 44, 81, 0.25);
  }
`;

export const CancelButtonContainer = styled.div`
  display: ${({ isCancelButtonShowing }: MainContainerProps) =>
    isCancelButtonShowing ? 'flex' : 'none'};
  width: 100%;
  justify-content: flex-end;
  margin-top: 20px;
  margin-bottom: 7px;
`;
