import { AxiosResponse } from 'axios';
import { UserProfile } from '../types/User';
import API from './API';
import { endpoints } from './Constants';

const BASE_URL = endpoints.auth.base;

/**
 * validate credentials and return the user info and auth token
 * @param {string} email user email.
 * @param {string} password user password.
 */
export const login = async (
  email: string,
  password: string
): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.login}`, {
    email,
    password,
  });
};

export const adminsLogin = async (
  email: string,
  password: string
): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.adminsLogin}`, {
    email,
    password,
  });
};

/**
 * Refresh auth token to keep session alive.
 * @param {string} token session refresh token.
 */
export const refreshToken = async (token: string): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.refreshToken}`, {
    refresh_token: token,
  });
};

// logout from the web app /  destroy auth token
export const logout = async (): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.logout}`);
};

/**
 * confirm the account via en email link.
 * @param {string} token auth token.
 */
export const confirmEmail = async (token: string): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.confirmEmail}${token}`);
};

/**
 * Register a new user.
 * @param {UserProfile} user new user data.
 */
export const register = async (user: UserProfile): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.register}`, user);
};

/**
 * send a mail to reset the password to the email address entered.
 * @param {string} email user email.
 */
export const forgotPassword = async (email: string): Promise<AxiosResponse> => {
  return await API.post(`${BASE_URL}${endpoints.auth.forgotPass}`, { email });
};

/**
 * enter and update the user's password.
 * @param {string} password user's new password.
 * @param {string} token auth token.
 */
export const resetPassword = async (
  password: string,
  token: string
): Promise<AxiosResponse> => {
  return await API.put(`${BASE_URL}${endpoints.auth.resetPass}${token}`, {
    password,
  });
};
