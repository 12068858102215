import React, { FC, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { RowRecord, TableColumn } from 'react-data-table-component';
import { useHistory } from 'react-router-dom';

import Layout from '../../shared/components/Layout/Layout';
import { Job } from '../../types/Job';
import { myJobs, QueryParamsProps } from '../../api/JobsAPI';
import SearchInput from '../../components/SearchInput/SearchInput';
import { useNotification } from '../../contexts/notification-context';
import { jobStatus } from '../../api/ListsAPI';
import Dropdown from '../../components/Dropdown/Dropdown';
import Badge from '../../components/Badge/Badge';
import JobLoadsBadge from '../../components/JobLoadsBadge/JobLoadsBadge';
import JobTypeBadge from '../../components/JobTypeBadge/JobTypeBadge';
import JobLocation from '../../components/JobLocation/JobLocation';
import JobStatusLink from '../../components/JobStatusLink/JobStatusLink';

import Table from '../../components/Table/Table';
import { getTruckTypesAsString } from '../../helpers/formats';

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 1px;
  padding: 13px 37px 13px 22px;
  margin: 20px 0px;
`;

export const Row = styled.div`
  display: flex;
  gap: 15px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  /*  Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    flex-direction: column;
    justify-content: left !important;
    align-items: flex-start;
  }
`;

export const Title = styled.p`
  font-family: Cabin, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #16244a;
  margin: 0;
`;

export const Column = styled.div`
  flex-direction: column;
  align-items: center;
  justify-content: left;
`;

export const Button = styled.button`
  background: #f7d55e;
  border-radius: 3px;
  padding: 6px 21px !important;
  border: none;
  text-transform: uppercase;
  color: #16244a;
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.06em;
  transition: 0.25s;
  width: 250px;

  &:hover {
    cursor: pointer;
    background: #e2b824cc;
  }

  /*  Small devices (landscape phones, 576px and up) */
  @media (max-width: 576px) {
    width: 100%;
  }
`;

const MyJobsPage: FC = () => {
  // notifications handler
  const { setNotification } = useNotification();

  // user current jobs
  const [jobs, setJobs] = useState<Job[] | []>([]);

  // available job status to filter by
  const [jobStatusList, setJobStatusList] = useState([]);

  // let the user know if there is a request in progress
  const [isLoading, setIsLoading] = useState(false);

  // search input value
  const [searchInput, setSearchInput] = useState('');

  // data table params
  const [queryParams, setQueryParams] = useState<QueryParamsProps>({
    page: 1,
    limit: 10,
    search: '',
    status: '',
  });

  const [totalJobs, setTotalJobs] = useState(0);

  // get the list of jobs and updated it everytime the queryParams change
  const getJobs = useCallback(() => {
    setIsLoading(true);
    myJobs(queryParams)
      .then(({ data }) => {
        if (data.success) {
          const orders = data.jobs.map((order) => new Job(order));
          setJobs(orders);
          setTotalJobs(data.totalJobs);
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => setIsLoading(false));
  }, [queryParams, setNotification]);

  // get and clear list of jobs
  useEffect(() => {
    getJobs();
  }, [getJobs]);

  // get the list of status at rendering the component
  useEffect(() => {
    // get the list of job status
    const getStatus = () => {
      jobStatus()
        .then(({ data }) => {
          const wildCardId = data.status.length + 1;
          data.status.unshift({ id: wildCardId, name: 'Show all' });
          setJobStatusList(data.status);
        })
        .catch(({ response }) => {
          setNotification({
            type: 'error',
            message: response.data.msg,
            open: true,
          });
        });
    };
    getStatus();
  }, [setNotification]);

  // data table columns
  const columns: TableColumn<Job>[] = [
    {
      name: 'Job number',
      selector: (row: Job) => row.id,
      sortable: true,
      width: '140px',
      center: true,
    },
    {
      name: 'Job Site Name',
      selector: ({ jobSiteLocation }: Job) =>
        `${jobSiteLocation.address}, ${jobSiteLocation.city}`,
      sortable: true,
      minWidth: '200px',
      cell: function GetJobLocation({ jobSiteLocation }: Job) {
        return <JobLocation location={jobSiteLocation} />;
      },
    },
    {
      name: 'Type',
      center: true,
      selector: ({ type }: Job) => type,
      cell: function GetJobTypeBadge({ type }: Job) {
        return <JobTypeBadge type={type} />;
      },
      sortable: true,
      width: '120px',
    },
    {
      name: 'Pickup / Dropoff',
      selector: ({ jobLocation }: Job) =>
        `${jobLocation.address}, ${jobLocation.city}`,
      cell: function GetJobLocation({ jobLocation, type }: Job) {
        return <JobLocation location={jobLocation} type={type} />;
      },
      minWidth: '200px',
      sortable: true,
    },
    {
      name: 'Truck Type',
      selector: (row: Job) => getTruckTypesAsString(row.truckType),
      sortable: true,
      minWidth: '125px',
    },
    {
      name: '#Loads',
      selector: ({ loadsRemaining }: Job) => loadsRemaining,
      cell: function GetLoadBadge({ loads, loadsRemaining }: Job) {
        return <JobLoadsBadge loads={loads} loadsRemaining={loadsRemaining} />;
      },
      sortable: true,
      width: '130px',
      center: true,
    },
    {
      name: 'Status',
      selector: ({ status }: Job) => status,
      sortable: true,
      width: '110px',
      center: true,
      cell: function GetBadge({ status }: Job) {
        return <Badge value={status} />;
      },
    },
    {
      name: '',
      sortable: false,
      cell: function GetJobStatusLink({ status, id }: Job) {
        return <JobStatusLink status={status} id={id} />;
      },
      width: '70px',
    },
  ];
  // handle enter key press to search
  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Enter') {
      setQueryParams({ ...queryParams, search: searchInput });
    }
  };

  // handle search input change event
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => setSearchInput(event.target.value);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const statusSelected = event.target.value;
    const lastStatusID = jobStatusList.length;
    if (statusSelected === lastStatusID) {
      setQueryParams({ ...queryParams, status: '', page: 1 });
    } else setQueryParams({ ...queryParams, status: statusSelected as string, page: 1 });
  };

  // get access to the route history
  const history = useHistory();

  // navigate to create a new job
  const handleNewJob = (): void => history.push('/create-job');

  // navigate to update a job
  const handleRowClicked = ({ id, status }: RowRecord): void => {
    if (status === 'Draft') history.push(`/edit-job/${id}`);
    else history.push(`/job-details/${id}`);
  };

  return (
    <Layout>
      <>
        <Card>
          <Row>
            <Column>
              <Title>Jobs site list</Title>
            </Column>
            <Button onClick={handleNewJob}>Create Job</Button>
          </Row>
          <div style={{ marginTop: '12px' }} />
          <Column>
            <Row>
              <SearchInput
                placeholder="Search..."
                value={searchInput}
                onChange={handleInputChange}
                onKeyPress={handleSearch}
              />
              <Dropdown
                value={queryParams.status}
                handleChange={handleSelectChange}
                options={jobStatusList}
              />
            </Row>
            <Table
              columns={columns}
              data={jobs}
              totalRows={totalJobs}
              paginationLimit={queryParams.limit}
              setQueryParams={setQueryParams}
              handleRowClicked={handleRowClicked}
              isLoading={isLoading}
              tableName="job"
              askToCreateFirst
            />
          </Column>
        </Card>
      </>
    </Layout>
  );
};

export default MyJobsPage;
