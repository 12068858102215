import { AvailableLoadStatus } from './Status';
import { LoadsList } from './responses/load-list-response';
import { formatHour } from '../helpers/formats';

export class Load {
  createdAt: string;
  endDate: string;
  id: number;
  rate: number;
  startDate: string;
  status: AvailableLoadStatus;
  totalHours: number;
  totalEstimated: number;
  trucker: {
    email: string;
    name: string;
    phone: string;
    truckTypes: string[];
  };

  constructor(data: LoadsList) {
    this.createdAt = data.createdAt;
    this.endDate = Boolean(data.endDate) ? formatHour(data.endDate) : '-';
    this.id = data.id;
    this.rate = data.rate;
    this.startDate = Boolean(data.startDate) ? formatHour(data.startDate) : '-';
    this.status = data.status;
    this.totalHours = data.totalHours;
    this.totalEstimated = data.totalEstimated;
    this.trucker = data.trucker;
  }

  // return truck types list as a string and separate it by a coma
  public getTruckTypesAsString(): string {
    return this.trucker.truckTypes.join(', ');
  }
}
