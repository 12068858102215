import { Dispatch, useCallback, useEffect, useState } from 'react';
import { UpdatedJob } from '../types/Job';
import { getJobDetail, HttpUpdateJobDetail } from '../api/JobsAPI';
import { useNotification } from '../contexts/notification-context';
import { AvailableStatus } from '../types/Status';
import { formatCurrency, parseNumberToLocale } from '../helpers/formats';

interface nonEditableDataInterface {
  estimatedCost: string;
  totalHours: number;
  loadsProgress: string;
  distance: number;
  name: string;
}

interface returnType {
  loading: boolean;
  jobStatus?: AvailableStatus;
  isEditionActive: boolean;
  staticData: nonEditableDataInterface;
  initialValues: UpdatedJob;
  updateJobDetail: (values: UpdatedJob) => void;
  handleEditButton: () => void;
  setJobStatus: Dispatch<AvailableStatus | undefined>;
}

export default function useJobDetail(id: string): returnType {
  // Notifications handler
  const { setNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState<AvailableStatus>();
  const [isEditionActive, setIsEditionActive] = useState(false);
  const [staticData, setStaticData] = useState({
    estimatedCost: '',
    totalHours: 0,
    loadsProgress: '',
    distance: 0,
    name: ''
  });
  const [initialValues, setInitialValues] = useState({
    rate: 0,
    loads: 0,
    loadsNotification: 0,
    material: '',
    remarks: '',
  });

  // get the details of the current job
  const getDetails = useCallback(async () => {
    getJobDetail(id)
      .then(({ data }) => {
        if (data.success) {
          const {
            rate,
            loads,
            loadsNotification,
            material,
            remarks,
            totals,
            distance,
            status,
          } = data.job;
          setJobStatus(status);
          setInitialValues({
            rate,
            loads,
            loadsNotification,
            material,
            remarks,
          });
          setStaticData({
            estimatedCost: formatCurrency(totals.estimatedCost),
            totalHours: parseNumberToLocale(totals.totalHours),
            loadsProgress: totals.loadProgress,
            distance,
            name: data.job.name,
          });
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      });
  }, [id, setNotification]);

  useEffect(() => {
    getDetails();
  }, [getDetails]);

  // Send a request to update the job detail info
  const updateJobDetail = (values: UpdatedJob) => {
    setLoading(true);
    HttpUpdateJobDetail(id, values)
      .then(({ data }) => {
        if (data.success) {
          setNotification({
            type: 'success',
            message: data.msg,
            open: true,
          });
        }
      })
      .catch(({ response }) => {
        setNotification({
          type: 'error',
          message: response.data.msg,
          open: true,
        });
      })
      .finally(() => {
        setIsEditionActive(false);
        setLoading(false);
      });
  };

  // Enable the fields
  const handleEditButton = () => {
    setIsEditionActive(true);
  };
  return {
    loading,
    jobStatus,
    isEditionActive,
    staticData,
    initialValues,
    updateJobDetail,
    handleEditButton,
    setJobStatus,
  };
}
