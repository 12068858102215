import styled from "styled-components";

export const Wrapper = styled.div`
  margin-top: 18px;

  &.my-tickets {
    margin-top: 0;
  }
  &.ticket-detail{
    margin-top: 0;
    .rdt_TableRow {
      .rdt_TableCell {
        padding-top: 18px;
        padding-bottom: 18px;
      }
    }
  }

  .rdt_TableHeadRow > .rdt_TableCol {
    font-family: Open Sans, sans-serif;
    font-weight: 300;
    font-size: 12px;
    color: #1c3264;
    text-transform: uppercase;
  }

  .rdt_TableRow,
  .rdt_TableHeadRow {
    border-bottom-color: #91a5da;
  }

  .rdt_TableRow {
    min-height: unset;
  }

  .rdt_TableHeadRow {
    min-height: 40px;
  }

  .rdt_TableCol_Sortable > div {
    overflow: visible;
    white-space: break-spaces;
  }

  .rdt_TableRow {
    .rdt_TableCell {
      padding-top: 10px;
      padding-bottom: 10px;
      font-family: 'Nunito', sans-serif;
      font-weight: 700 !important;
      font-size: 13px;

      line-height: 14px;
      text-transform: uppercase;
    }

    .rdt_TableCell > div {
      white-space: break-spaces;
    }
  }

  .rdt_TableBody {
    max-height: 55vh;
    overflow-y: auto;
  }

  .rdt_TableBody::-webkit-scrollbar {
    width: 5px;
  }

  .rdt_TableBody::-webkit-scrollbar-track {
    border-radius: 10px;
  }

  .rdt_TableBody::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.2);
  }
`;