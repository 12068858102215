import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import React from 'react';

const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: calc(100vh - 110px);
  align-items: center;
  justify-content: center;
`;

const LoadingScreen = (): JSX.Element => {
  return (
    <Wrapper>
      <CircularProgress disableShrink />
    </Wrapper>
  );
};

export default LoadingScreen;