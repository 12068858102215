import * as Yup from 'yup';

export const myAccountValidations = {
  password: Yup.object({
    currentPassword: Yup.string().required('Required'),
    password: Yup.string().required('Required'),
    confirmPassword: Yup.string()
      .required('Required')
      .oneOf([Yup.ref('password'), null], 'Required'),
  }),
  user: Yup.object({
    companyName: Yup.string().required('Required'),
    name: Yup.string().required('Required'),
    lastname: Yup.string().required('Required'),
    phone: Yup.number()
      .typeError('This field require a number value')
      .required('Required'),
  }),
};

export const jobDetailValidations = {
  detail: Yup.object({
    remarks: Yup.string().required('Required'),
    material: Yup.string().required('Required'),
    loads: Yup.number()
      .typeError('This field require a number value')
      .required('Required'),
    loadsNotification: Yup.number()
      .typeError('This field require a number value')
      .required('Required'),
    rate: Yup.number()
      .typeError('This field require a number value')
      .required('Required'),
  }),
};
