import { MenuItem } from '@material-ui/core';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import styled from 'styled-components';

import { formatAddress } from '../../helpers/formats';
import { GoogleAddress } from '../../types/GoogleAddress';
import SearchInput from '../SearchInput/SearchInput';

const OptionList = styled.div`
  background-color: white;
  width: 100% !important;
  border-radius: 0px 0px 3px 3px;
  position: absolute;
  z-index: 2;
`;

const Option = styled(MenuItem)`
  width: 100%;
  padding: 10px 10px !important;
`;

interface GoogleSearchProps {
  handleSearch: (address: {
    address: string;
    components: GoogleAddress;
  }) => void;
  value: {
    address: string;
    components: GoogleAddress;
  };
  handleMap: (coordinates: { lat: number; lng: number }) => void;
}

const GoogleSearch = ({
  handleSearch,
  value,
  handleMap,
}: GoogleSearchProps): JSX.Element => {
  /**
   * handle when the search value changes
   * @param {string} address address entered.
   */
  const handleChange = (address: string) => {
    handleSearch({ address, components: {} });
  };

  /**
   * handle when the user clicks on an address option
   * @param {string} address address entered.
   */
  const handleSelect = (address: string): void => {
    geocodeByAddress(address)
      .then((results: google.maps.GeocoderResult[]) => {
        const components = formatAddress(results[0].address_components);
        handleSearch({ address, components });
        return getLatLng(results[0]);
      })
      .then((latLng: { lat: number; lng: number }) => {
        handleMap(latLng);
      })
      .catch((error: Record<string, unknown>) => console.error('Error', error));
  };

  return (
    <PlacesAutocomplete
      value={value.address}
      onSelect={handleSelect}
      onChange={handleChange}
      searchOptions={{ componentRestrictions: { country: ['us', 'mx'] } }}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps }) => (
        <div>
          <SearchInput
            maxWidth="100%"
            placeholder="Search location..."
            {...getInputProps({
              className: 'location-search-input',
            })}
          />
          <OptionList>
            {suggestions.map((suggestion) => {
              return (
                <Option
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {})}
                >
                  {suggestion.description}
                </Option>
              );
            })}
          </OptionList>
        </div>
      )}
    </PlacesAutocomplete>
  );
};

export default GoogleSearch;
