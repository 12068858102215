import { TableColumn } from 'react-data-table-component';
import Table from '../../components/Table/Table';
import Layout from '../../shared/components/Layout/Layout';
import { Card, Row, Title } from '../MyJobs/my-jobs-page';
import { ListUser } from '../../types/responses/users-list';
import { formatDate } from '../../utils/dateFormats';
import { useEffect, useState } from 'react';
import { getUsers } from '../../api/UsersAPI';
import { useNotification } from '../../contexts/notification-context';
import { formatPhone } from '../../utils/phoneFormat';
import getUserType from '../../utils/getUserType';

const Users: React.FC = () => {
  const [users, setUsers] = useState<ListUser[]>([]);
  const [totalUsers, setTotalUsers] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { setNotification } = useNotification();

  const [queryParams, setQueryParams] = useState({
    page: 1,
    limit: 10
  });

  const tableCols: TableColumn<ListUser>[] = [
    {
      name: 'ID',
      selector: (user) => user.id,
      sortable: true,
      width: '65px'
    },
    {
      name: 'User type',
      selector: (user) => getUserType(user.userRoles.map((r) => r.id)),
      sortable: true,
      minWidth: '130px',
      grow: 1.5
    },
    {
      name: 'Subscription',
      selector: () => 'Premium',
      sortable: true,
      maxWidth: '100px'
    },
    {
      name: 'Status',
      selector: (user) => (user.status ? 'Enabled' : 'Disabled'),
      sortable: true,
      maxWidth: '100px'
    },
    {
      name: 'Name',
      selector: (user) => user.fullName,
      sortable: true,
      minWidth: '250px',
      grow: 2
    },
    {
      name: 'Email',
      selector: (user) => user.email,
      sortable: true,
      minWidth: '250px',
      grow: 2
    },
    {
      name: 'Phone',
      selector: (user) => formatPhone(user.phone),
      sortable: false,
      minWidth: '125px'
    },
    {
      name: 'Registration date',
      selector: (user) => user.registrationDate,
      cell: ({ registrationDate }) => formatDate(new Date(registrationDate)),
      sortable: true,
      width: '100px'
    }
  ];

  useEffect(() => {
    const getJobs = async () => {
      setIsLoading(true);
      try {
        const { data } = await getUsers({ params: queryParams });

        if (data.success) {
          setUsers(data.users);
          setTotalUsers(data.totalItems);
        }
      } catch (error) {
        if (error instanceof Error) {
          console.log(error);
          setNotification({
            type: 'error',
            message: 'Something went wrong. Please try again later.',
            open: true
          });
        }
      } finally {
        setIsLoading(false);
      }
    };

    getJobs();
  }, [setNotification, queryParams]);

  return (
    <Layout>
      <Card>
        <Row style={{ marginBottom: '20px' }}>
          <Title>Users</Title>
        </Row>

        <Table
          columns={tableCols}
          data={users}
          totalRows={totalUsers}
          paginationLimit={queryParams.limit}
          setQueryParams={setQueryParams}
          handleRowClicked={() => null}
          isLoading={isLoading}
          tableName='Users List'
        />
      </Card>
    </Layout>
  );
};

export default Users;
