import styled from 'styled-components';

export const Card = styled.div`
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.06);
  border-radius: 1px;
  padding: 35px;
  margin: 20px 0 0;

  &.mb {
    margin-bottom: 20px !important;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 15px;

  & > div {
    width: 100%;
    flex: 1;
  }

  /*  Small devices (landscape phones, 665px and up) */
  @media (max-width: 665px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TitleRow = styled(Row)`
  justify-content: space-between;

  @media (max-width: 665px) {
    flex-direction: Row;
  }
  @media (max-width: 425px) {
    flex-direction: column;
  }
`;

export const Title = styled.p`
  font-family: Cabin, sans-serif;
  font-weight: 700;
  font-size: 19px;
  color: #16244a;
  margin: 0;
`;

export const SaveButton = styled.button`
  background: #f7d55e;
  border-radius: 3px;
  padding: 6px 21px;
  border: none;
  text-transform: uppercase;
  color: #16244a;
  font-family: Nunito, sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 700;
  letter-spacing: 0.06em;
  transition: 0.25s;
  width: 170px;

  &:hover {
    cursor: pointer;
    background: #e2b824cc;
  }

  @media (max-width: 425px) {
    width: 100%;
  }
`;

export const EditButton = styled(SaveButton)`
  background: #fdf2ca;
  color: #16244a;

  &:hover {
    cursor: pointer;
    background: #e6d285;
  }
`;

export const EmptyColumn = styled.div`
  /*  Small devices (landscape phones, 665px and up) */
  @media (max-width: 665px) {
    display: none;
  }
`;
export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background: #c2cdea;
  margin-top: 17px;
`;

export const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 102px);
`;

export const Instructions = styled.p`
  font-size: 16px;
  font-family: Nunito, sans-serif;
  font-weight: 600;
  text-align: center;
  color: #16244a;
  margin-bottom: 20px;
`;

export const DownloadSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const Store = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 15px;

  @media (max-width: 600px) {
    gap: 0;
  }
`;
