import { JobTypes } from '../../types/JobTypes';
import { Location } from '../../types/Location';
import ArrowBottomIcon from '../Icons/ArrowBottomIcon';
import ArrowUpIcon from '../Icons/ArrowUpIcon';
import styled from 'styled-components';

const SiteName = styled.div`
  font-weight: 700;
  text-transform: capitalize;
  width: 100%;
`;

const SiteWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 6px;
`;

const AddressWrapper = styled.div``;

const Address = styled.div`
  font-weight: 300;
  text-transform: none;
  font-size: 14px;
`;

const ArrowWrapper = styled.div`
  background-color: ${({ type }: { type: string }) =>
    `${JobTypes.Export === type ? '#B0BEE4' : '#F7D55E'}`};
  padding: 0 3px;
  margin-right: 6px;
`;

interface JobLocation {
  location: Location;
  type?: string;
}

const JobLocation = ({ location, type }: JobLocation): JSX.Element => {
  const ArrowIcon =
    JobTypes.Import === type ? (
      <ArrowUpIcon size={8} />
    ) : (
      <ArrowBottomIcon size={8} />
    );

  return (
    <div>
      <SiteWrapper>
        {type && <ArrowWrapper type={type}>{ArrowIcon}</ArrowWrapper>}
        <SiteName data-tag="allowRowEvents">{location.name}</SiteName>
      </SiteWrapper>
      <AddressWrapper>
        <Address data-tag="allowRowEvents">{location.address}</Address>
      </AddressWrapper>
    </div>
  );
};

export default JobLocation;
