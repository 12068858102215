import { useCallback, useEffect, useState } from 'react';
import { useNotification } from '../contexts/notification-context';
import { httpGetTicketDetail } from '../api/TicketsAPI';
import { TicketDetail } from '../types/TicketDetail';

interface returnType {
  detail?: TicketDetail;
  loading: boolean;
  fetchDetail: () => void;
}

export default function useGetTicketDetail(id: string): returnType {
  // Notifications handler
  const { setNotification } = useNotification();

  const [loading, setLoading] = useState(false);
  const [detail, setDetail] = useState<TicketDetail>();

  const fetchDetail = useCallback(() => {
      setLoading(true);
      httpGetTicketDetail(id)
        .then(({ data }) => {
          const ticketData = new TicketDetail(data.ticket);
          setDetail(ticketData);
        })
        .catch(({ response }) => {
          setNotification({
            type: 'error',
            message: response.data.msg,
            open: true,
          });
        })
        .finally(() => {
          setLoading(false);
        });
    }, [id, setNotification],
  );

  useEffect(() => {
    fetchDetail();
  }, [fetchDetail]);

  return { detail, loading, fetchDetail };
}