import {
  createContext,
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useState,
} from 'react';
import {
  Notification,
  NotificationProps,
} from '../components/Notification/Notification';

export type NotificationContextType = {
  setNotification: Dispatch<SetStateAction<NotificationProps>>;
};

const NotificationContext = createContext<
  NotificationContextType | Record<string, never>
>({});

export const NotificationProvider: FC = (props) => {
  // handle the close event of the notification
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setNotification({ open: false, message: '', type: undefined, handleClose });
  };

  // Snackbar State
  const [notification, setNotification] = useState<NotificationProps>({
    open: false,
    message: '',
    type: undefined,
    handleClose,
  });

  return (
    <NotificationContext.Provider
      value={{
        setNotification,
      }}
    >
      {props.children}
      {notification?.open ? (
        <Notification
          open={notification?.open}
          message={notification.message}
          type={notification.type}
          handleClose={handleClose}
        />
      ) : null}
    </NotificationContext.Provider>
  );
};

export const useNotification = ():
  | NotificationContextType
  | Record<string, never> => {
  return useContext(NotificationContext);
};
