import { FC } from 'react';
import Grid from '@material-ui/core/Grid';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router-dom';

import Banner from '../../assets/images/publicBanner01.jpg';
import TruckCaddyLogo from '../../assets/images/logo.png';
import PlainTextButton from './../../components/PlainTextButton/PlainTextButton';
import Button from './../../components/Button/Button';


const Container = styled(Grid)`
  height: 100vh;
  &.MuiGrid-container{
    flex-wrap: nowrap;
  }
`;

const InfoContainer = styled(Grid)`
  background: #16244a;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 45px;

  @media (max-width: 900px) {
    flex-grow: 0;
    max-width: 100% !important;
    flex-basis: 100% !important;
  }
  @media (max-width: 900px) {
    padding: 10px;
  }
`;
const Logo = styled.div`
  display: flex;
  align-items: center;
`;
const TitleImage = styled.img`
  height: auto;
  max-width: 100%;
`;


const Subtitle = styled.p`
  font-family: Nunito, sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  color: #f6f7fc;
  width: 650px;
  text-align: left;

  @media (max-width: 900px) {
    text-align: center;
  }

  @media (max-width: 1999.98px) {
    width: fit-content;
  }

  @media (min-width: 1200px) {
    width: 650px;
  }
`;

const ImageContainer = styled(Grid)`
  background-image: url(${Banner});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center 50%;
  @media (max-width: 900px) {
    display: none;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  flex: 3;
  justify-content: center;
  @media (max-width: 900px) {
    align-items: center;
  }
`;

const  ButtonsContainer = styled.div`
  display: flex;
  padding-bottom: 50px;
  @media (max-width: 900px) {
    text-align: center;
    flex-direction: column;
  }
`;

const HomePage: FC<RouteComponentProps> = ({ history }) => {
  // navigate to login
  const handleClickLogin = () => history.push('/login');
  // navigate to register page
  const handleClickCreate = () => history.push('/register');

  return (
    <Container container>
      <ImageContainer item xs={4} sm={4} md={4} />
      <InfoContainer item xs={12} sm={8} md={8}>
        <Content>
          <Logo>
            <TitleImage src={TruckCaddyLogo}/>
          </Logo>
          <Subtitle>
            Welcome to Truck Caddy, the best way to find and book trucks for your next project.
            Please sign in or create an account to get started.
          </Subtitle>
          <Button text="Log in" handleClick={handleClickLogin} />
          <Button
            text="Create an account"
            handleClick={handleClickCreate}
            background="#16244a"
            color="white"
          />
        </Content>
        <ButtonsContainer>
          <PlainTextButton
            text="Terms and Conditions"
            handleClick={() => {
              console.log('');
            }}
          />
          <PlainTextButton
            text="Privacy Policy"
            handleClick={() => {
              console.log('');
            }}
          />
        </ButtonsContainer>
      </InfoContainer>
    </Container>
  );
};

export default HomePage;
