export enum StatusId {
  Active = 1,
  Draft = 2,
  Canceled = 3,
  Completed = 4,
  Inactive = 5,
}

export enum JobStatusDisplayColor {
  Active = '#82C01D',
  Completed = '#4caf50',
  Inactive = '#FF537C',
}

export type AvailableStatus =
  | 'Active'
  | 'Draft'
  | 'Canceled'
  | 'Completed'
  | 'Inactive';

export enum StatusColors {
  Active = '#82C01D',
  Draft = '#E8ECF9',
  Canceled = '#dc004e',
  Completed = '#4caf50',
  Inactive = '#E8ECF9',
}

export enum StatusTextColors {
  Active = '#FFF',
  Draft = '#16244A',
  Canceled = '#FFF',
  Completed = '#FFF',
  Inactive = '#FF537C',
}

export type AvailableLoadStatus =
  | 'Scheduled'
  | 'Canceled'
  | 'Completed'
  | 'Started';

export enum LoadStatusColors {
  Scheduled = '#405DAA',
  Canceled = '#FF537C',
  Completed = '#6CBD2C',
  Started = '#05B8C3',
}

export enum LoadStatusTextColors {
  Scheduled = '#FFF',
  Canceled = '#FFF',
  Completed = '#FFF',
  Started = '#FFF',
}

export type AvailableTicketStatus =
  | 'Not paid'
  | 'Paid';

export enum AvailableTicketColors {
  'Paid' = 'rgba(130, 192, 29, 0.15)',
  'Not paid' = 'rgba(255, 83, 124, 0.15)',
}

export enum AvailableTicketTextColors {
  'Paid' = '#6CBD2C',
  'Not paid' = '#FF537C',
}
