import { useState, useCallback, useEffect } from 'react';
import { TicketRow } from '../types/TicketRow';
import { httpSetTicketAsNotPaid, httpSetTicketAsPaid } from '../api/TicketsAPI';
import { useModal } from '../contexts/modal-context';
import { AxiosError, AxiosResponse } from 'axios';
import { useNotification } from '../contexts/notification-context';

interface hookParams {
  onFinalize?: () => void;
  ticketId?: number;
}

interface returnType {
  count: number;
  handleCheckboxChange: ({ selectedRows, selectedCount }: {
    selectedRows: TicketRow[];
    selectedCount: number;
  }) => void;
  markAsPaidCb: () => void;
  markAsNotPaidCb: () => void;
}

export function usePayMultipleTicket(config: hookParams): returnType {
  // Confirmation context
  const { close } = useModal();
  // Notification handler
  const { setNotification } = useNotification();

  const [selectedTickets, setSelectedTickets] = useState<number[] | []>([]);
  const [count, setCount] = useState(0);

  // Set the selected ticket if passed by params
  useEffect(() => {
    if (typeof config?.ticketId !== 'undefined') {
      setSelectedTickets([config?.ticketId]);
    }
  }, [config.ticketId]);

  const handleCheckboxChange = ({ selectedRows, selectedCount }: {
    selectedRows: TicketRow[];
    selectedCount: number;
  }) => {
    const ids = selectedRows.map(row => row.id);
    setSelectedTickets(ids);
    setCount(selectedCount);
  };

  const markAsPaidCb = useCallback(() => {
      httpSetTicketAsPaid(selectedTickets)
        .then(({ data }: AxiosResponse) => {
          if (data.success) {
            setNotification({
              type: 'success',
              message: data.msg,
              open: true,
            });
          }
        })
        .catch(({ response }: AxiosError) => {
          setNotification({
            type: 'error',
            message: response?.data.msg,
            open: true,
          });
        })
        .finally(() => {
          close();
          if (typeof config.onFinalize === 'function') {
            config.onFinalize();
          }
          setCount(0);
          setSelectedTickets([]);
        });
    }, [close, config, selectedTickets, setNotification],
  );

  const markAsNotPaidCb = useCallback(() => {
      httpSetTicketAsNotPaid(selectedTickets)
        .then(({ data }: AxiosResponse) => {
          if (data.success) {
            setNotification({
              type: 'success',
              message: data.msg,
              open: true,
            });
          }
        })
        .catch(({ response }: AxiosError) => {
          setNotification({
            type: 'error',
            message: response?.data.msg,
            open: true,
          });
        })
        .finally(() => {
          close();
          if (typeof config.onFinalize === 'function') {
            config.onFinalize();
          }
          setCount(0);
          setSelectedTickets([]);
        });
    }, [close, config, selectedTickets, setNotification],
  );


  return { count, handleCheckboxChange, markAsPaidCb, markAsNotPaidCb };
}