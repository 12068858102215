import styled from 'styled-components';

const Button = styled.a`
  font-family: Nunito;
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  text-decoration-line: underline;
  margin-right: 12px;
  color: #91a5da;
  cursor: pointer;
  letter-spacing: 0.06em;
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

interface PlainTextButtonProps {
  text: string;
  handleClick: () => void;
}

const PlainTextButton = ({
  text,
  handleClick,
}: PlainTextButtonProps): JSX.Element => {
  return <Button onClick={handleClick}>{text}</Button>;
};

export default PlainTextButton;
